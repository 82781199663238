import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";

// component
import Button from "../../components/Button";

// pdf icon
import PdfIcon from "../../assets/pdf-icon.png";
import { BsDownload, BsX } from "react-icons/bs";

const CnLabelPage = () => {
  const userType = useSelector((state) => state.auth.type);

  let datas = [
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "cello" },
    { fileName: "rello" },
    { fileName: "hello" },
    { fileName: "jello" },
    { fileName: "kello" },
    { fileName: "cello" },
    { fileName: "rello" },
  ];

  return (
    <SubDashboardLayout
      title={userType}
      operationName={"Cn-Label"}
      to={"../../"}
      id={"CnLabelPage"}
    >
      <div className="cnlabelContainer">
        <div className="cnlabelHeader">
          <div className="button-wrap">
            <label className="button" htmlFor="upload">
              Upload PDF
            </label>
            <input id="upload" type="file" />
            <div />
          </div>
        </div>
        <div className="cnlabelContent">
          {datas.map((data, index) => (
            <div className="fileContainer" key={index}>
              <BsX className="delete" size={25} />
              <BsDownload className="download" />
              <img src={PdfIcon} alt={data.fileName} />
              <p>{`${data.fileName}.pdf`}</p>
            </div>
          ))}
        </div>
      </div>
    </SubDashboardLayout>
  );
};

export default CnLabelPage;
