import axios from "../helper/axios";
import { menuConstants } from "./constants";

export const getMenu = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: menuConstants.GET_MENU_REQUEST,
      });
      let res = await axios.get("/menu/get");
      if (res.status === 201 || res.status === 200) {
        return dispatch({
          type: menuConstants.GET_MENU_SUCCESS,
          payload: res.data.menu,
        });
      }
      dispatch({
        type: menuConstants.GET_MENU_FAILURE,
      });
    } catch (err) {
      dispatch({
        type: menuConstants.GET_MENU_FAILURE,
      });
    }
  };
};

export const updateMenu = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: menuConstants.UPDATE_MENU_REQUEST,
      });
      let res = await axios.put(`/menu/update/${id}`, value);
      if (res.status === 200) {
        dispatch({
          type: menuConstants.UPDATE_MENU_SUCCESS,
        });
        return dispatch(getMenu());
      }
      dispatch({
        type: menuConstants.UPDATE_MENU_FAILURE,
      });
    } catch (err) {
      dispatch({
        type: menuConstants.UPDATE_MENU_FAILURE,
      });
    }
  };
};
