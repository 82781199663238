import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// component
import StudentForm from "../../components/StudentForm";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";

const StudentFormPage = (props) => {
  //props
  let { action } = props;
  const Student = useSelector((state) => state.student.student);
  const loading = useSelector((state) => state.student.loading);
  const user = useSelector((state) => state.auth.user);

  //params
  let { studentId } = useParams();

  return (
    <SubDashboardLayout
      title={user.siteName}
      operationName={`${action} Student`}
      id={"siteStudentForm"}
      to={"/sites/master-list"}
      loading={loading}
    >
      <StudentForm
        student={Student}
        studentId={studentId}
        site={user.siteName}
        action={action}
        to={"/sites/master-list"}
      />
    </SubDashboardLayout>
  );
};

export default StudentFormPage;
