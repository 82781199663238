import "./style.scss";
import React from "react";

const TextArea = (props) => {
  let { placeholder, label, name } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: ".5rem",
      }}
    >
      {label && (
        <label
          style={{
            fontSize: ".9rem",
            padding: ".3rem .2rem",
          }}
        >
          {label}
        </label>
      )}
      <textarea
        className="txtarea"
        placeholder={placeholder}
        name={name}
        {...props}
      />
    </div>
  );
};

export default TextArea;
