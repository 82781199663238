import { menuConstants } from "../actions/constants";

const initialState = {
  menu: [],
  loading: false,
  error: null,
  message: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case menuConstants.GET_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case menuConstants.GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: payload,
      };
    case menuConstants.GET_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong !",
      };
    case menuConstants.UPDATE_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case menuConstants.UPDATE_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Updated successfully",
      };
    case menuConstants.UPDATE_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong !",
      };
    default:
      return { ...state };
  }
};
