import React, { useState } from "react";
import "./style.scss";
import Button from "../../components/Button";
import Input from "../../components/FormComponents/Input";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  authSignin,
  authSignup,
  driverSignin,
  siteSignin,
  vendorSignin,
} from "../../actions";
import { useEffect } from "react";
import Loader from "../../components/Loader";

const AutheticationPage = (props) => {
  let { title, pin, action, type } = props;

  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.auth.loading);
  const [credentials, setCredentials] = useState({
    userName: "",
    password: "",
    pinCode: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (auth.authenticate) {
      if (type === "vendor") {
        return navigate("/vendors");
      }
      if (type === "site") {
        return navigate("/sites");
      }
      if (type === "driver") {
        return navigate("/drivers");
      }
      return navigate("/admin/dashboard");
    }
  }, [auth.authenticate]);

  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    if (type === "vendor") {
      return dispatch(vendorSignin(credentials));
    }
    if (type === "site") {
      return dispatch(siteSignin(credentials));
    }

    if (type === "driver") {
      return dispatch(driverSignin(credentials));
    }
    action === "login"
      ? dispatch(authSignin(credentials))
      : dispatch(authSignup(credentials));
  };
  // when formValues are changes
  const onChange = (e) => {
    setCredentials((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <section id="authentication" className="container">
      <Link to="/" className="backwardLink">
        <FaArrowLeft size={25} color="#636363" />
      </Link>
      <div className="loginForm">
        <h3 className="loginTitle">{title}</h3>
        {loading && <Loader />}
        {!loading && (
          <form>
            <Input placeholder="username" name="userName" onChange={onChange} />
            <Input placeholder="password" name="password" onChange={onChange} />
            {pin && (
              <Input
                placeholder="PIN CODE"
                type="password"
                name="pinCode"
                onChange={onChange}
              />
            )}
            <Button
              text={action === "login" ? "Login" : "Signup"}
              type="primary"
              onClick={onSubmit}
            />
          </form>
        )}
      </div>
    </section>
  );
};

export default AutheticationPage;
