import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";

// icon
import { FaRegCircle, FaTimes } from "react-icons/fa";
import { AddMeal } from "../../actions";

const TableData = (props) => {
  let { student_id, dates, mealTypeId, defValue, day, site, clicked } = props;
  const [mark, setMark] = useState(0);
  useEffect(() => {
    setMark(defValue);
  }, [defValue]);

  const Icon = (value) => {
    switch (value) {
      case 1:
        return <FaTimes />;
      case 2:
        return <FaRegCircle />;
      default:
        return;
    }
  };

  const changeValue = async (v) => {
    if (v === 0) {
      setMark(1);
    } else if (v === 1) {
      setMark(2);
    } else {
      setMark(0);
    }
  };
  // mealtypes
  let mealTypes = [
    "breakfast",
    "amSnack",
    "lunch",
    "pmSnack",
    "supper",
    "eveningSnack",
  ];
  // defines meal type
  let mealType = mealTypes[mealTypeId - 1];
  let dispatch = useDispatch();
  const onClick = async (e) => {
    changeValue(mark);
    let meal = {
      student_id,
      date: dates[day - 1],
      mealType,
      value: mark,
      mealId: day,
      weeks: dates,
      site: site,
    };
    dispatch(AddMeal(meal));
    // clicked((prev) => !prev);
  };

  return (
    <td className="tableData" onClick={onClick}>
      {Icon(mark)}
    </td>
  );
};

export default TableData;
