import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";

// actions
import { getOrdersByVendor } from "../../../actions";
import Loader from "../../../components/Loader";

const OrderRecievePage = (props) => {
  const dispatch = useDispatch();

  const Orders = useSelector((state) => state.order.orders);
  const loading = useSelector((state) => state.order.loading);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user._id) {
      dispatch(getOrdersByVendor(user._id));
    }
  }, [user._id]);

  return (
    <SubDashboardLayout
      title={"Vendor Dashboard"}
      operationName={`Orders Recieved`}
      id={"vendorDashboard"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>Meal Type</th>
              <th>Meals Required</th>
              <th>Site</th>
              <th>Sent to</th>
              <th>Comments</th>
              <th>Created At</th>
              <th>Status</th>
            </tr>
            {Orders.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Orders yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Orders.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order.mealType}</td>
                  <td>{order.mealRequired}</td>
                  <td>{order.site.siteName}</td>
                  <td>{order.vendor.vendorName}</td>
                  <td>{order.comment}</td>
                  <td>{format(new Date(order.createdAt), "dd-MM-yyyy")}</td>
                  <td>{order.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default OrderRecievePage;
