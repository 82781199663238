import { driverConstants } from "../actions/constants";

const initialState = {
  driver: {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    signature: "",
  },
  loading: false,
  error: null,
  message: "",
  status: 0,
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case driverConstants.ADD_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case driverConstants.ADD_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Driver created",
        status: payload,
      };
    case driverConstants.ADD_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case driverConstants.GET_DRIVER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case driverConstants.GET_DRIVER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        driver: payload,
      };
    case driverConstants.GET_DRIVER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case driverConstants.UPDATE_DRIVER_REQUEST:
      return {
        ...state,
        status: 0,
        loading: true,
      };
    case driverConstants.UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        message: "Updated successfully!",
      };
    case driverConstants.UPDATE_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case driverConstants.DELETE_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case driverConstants.DELETE_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Deleted successfully!",
      };
    case driverConstants.DELETE_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};
