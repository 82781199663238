import { authConstants } from ".";
import axios from "../helper/axios";

export const authSignin = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.AUTH_SIGNIN_REQUEST });
      let res = await axios.post(`/users/signin`, credentials);

      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("type", "admin");
        return dispatch({
          type: authConstants.AUTH_SIGNIN_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: authConstants.AUTH_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    } catch (err) {
      dispatch({
        type: authConstants.AUTH_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    }
  };
};

export const authSignup = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.AUTH_SUPER_SIGNUP_REQUEST });
      let res = await axios.post(`/users/signup`, credentials);

      if (res.status === 201) {
        return dispatch({
          type: authConstants.AUTH_SUPER_SIGNUP_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: authConstants.AUTH_SUPER_SIGNUP_FAILURE,
        payload: "Invalid credentials",
      });
    } catch (err) {
      dispatch({
        type: authConstants.AUTH_SUPER_SIGNUP_FAILURE,
        payload: "Invalid credentials",
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.GET_AUTHENTICATE_REQUEST });
    const token = localStorage.getItem("token");
    const type = localStorage.getItem("type");
    if (token && type) {
      const user = JSON.parse(localStorage.getItem("user"));
      return dispatch({
        type: authConstants.GET_AUTHENTICATE_SUCCESS,
        payload: { token, user, type },
      });
    }
    dispatch({
      type: authConstants.GET_AUTHENTICATE_FAILURE,
      payload: "Failed to login",
    });
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("type");

    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    let type = localStorage.getItem("type");

    if (!token && !user && !type) {
      dispatch({
        type: authConstants.LOGOUT_SUCCESS,
      });
    }
    dispatch({
      type: authConstants.LOGOUT_FAILURE,
    });
  };
};
