import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import axios from "../../../helper/axios";
import { format, parse } from "date-fns";

// actions
import {
  getEnrollmentForm,
  updateEnrollmentForm,
} from "../../../actions/student.action";

// style
import "./style.scss";

//sub layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";
// component
import Input from "../../../components/FormComponents/Input";
import Button from "../../../components/Button";

// img
import Tick from "../../../assets/tick.png";
import UnTick from "../../../assets/untick.png";

const EnrollmentFormPage = () => {
  let { studentId } = useParams();

  // state
  let auth = useSelector((state) => state.auth);
  let enrollmentForm = useSelector((state) => state.student.enrollmentForm);
  let loading = useSelector((state) => state.student.loading);

  const [edit, setEdit] = useState(false);

  const initialState = {
    student: {
      fullName: "",
      age: 0,
      birthDate: "",
      site: {
        siteName: "",
      },
    },
    days: [
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Monday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Tuesday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Wednesday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Thursday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Friday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Saturday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Sunday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
    ],
    parent: {
      signature: "",
      streetAptNo: "",
      zipcode: "",
      dayPhone: "",
    },
    vary: false,
  };

  const [formValues, setFormValues] = useState(initialState);

  const onTickChange = (e, index) => {
    let newArr = [...formValues.days];
    newArr[index][e.target.name] = !newArr[index][e.target.name];
    setFormValues({ ...formValues, days: newArr });
  };
  const onTimeChange = (e, index) => {
    let newArr = [...formValues.days];
    newArr[index][e.target.name] = e.target.value;

    setFormValues({ ...formValues, days: newArr });
  };

  let { student, parent, days, vary } = formValues;

  const createAndDownloadPdf = () => {
    axios
      .post("/create-pdf/enrollment-form", formValues)
      .then(() =>
        axios.get("/create-pdf/enrollment-form", {
          responseType: "blob",
        })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, `${formValues?.student?.fullName}-enrollment-form.pdf`);
      });
  };
  const dispatch = useDispatch();

  const updateForm = (enrollmentId, value) => {
    dispatch(updateEnrollmentForm(enrollmentId, value));
    setTimeout(() => {
      setEdit(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(getEnrollmentForm(studentId));
  }, []);

  useEffect(() => {
    if (enrollmentForm._id) {
      setFormValues(enrollmentForm);
    }
  }, [enrollmentForm]);

  let date = new Date(enrollmentForm?.parent?.createdAt);
  return (
    <SubDashboardLayout
      to="/sites/master-list"
      title={auth.user.siteName}
      operationName={"Enrollment Form"}
      id={"enrollmentForm"}
      loading={loading}
    >
      <div className="enrollmentFormContainer">
        <div className="enrollmentForm">
          <div className="enrollmentFormHeader">
            <h2>CHILD AND ADULT CARE FOOD PROGRAM</h2>
            <h1>ENROLLMENT FORM</h1>
            <p>
              <b>
                Required Form for use by Child Care Centers and Head Start
                Programs
              </b>
            </p>
            <p className="description">
              CACFP programs exempt from having an enrollment form on file are:
              Emergency Shelters, Outside School Hours, Youth Development &
              After School at Risk
            </p>
          </div>
          <div className="headerBox">
            <div className="instructions">
              <h2>
                <b>Instructions to Complete</b>
              </h2>
              <ul>
                <li>
                  All parents/guardians are to complete a separate form for each
                  child enrolled at the child care or Head Start center.
                </li>
                <li>
                  List the child’s name, age, birth date, the days and hours
                  normally in care and the meals normally received while in
                  care.
                </li>
                <li>
                  List the child’s name, age, birth date, the days and hours
                  normally in care and the meals normally received while in
                  care.
                </li>
                <li>
                  If the child comes before and after school, list the hours in
                  care for both the morning and afternoon
                </li>
                <li>
                  CACFP Federal regulations 226.15(e) (2) require that an
                  enrollment form be completed annually and signed by the
                  child’s parent or guardian.
                </li>
              </ul>
            </div>
            <div className="centerName">
              <h4>
                <b>CENTER NAME:</b>
                <span> {student?.site?.siteName}</span>
              </h4>
            </div>
            <div className="studentDetails">
              <div className="studentName">
                <h4>
                  <b>CHILD'S NAME:</b>
                </h4>
                <span>{student?.fullName}</span>
              </div>
              <div className="age">
                <h4>
                  <b>AGE:</b>
                </h4>
                <span>{student?.age}</span>
              </div>
              <div className="birthDate">
                <h4>
                  <b>BIRTHDATE:</b>
                </h4>
                <span>{student?.birthDate}</span>
              </div>
            </div>
          </div>
          <div className="mealCheckBox">
            <table>
              <thead>
                <tr>
                  <th className="text-center" colSpan={12}>
                    CHECK THE NORMAL DAYS AND HOURS YOUR CHILD IS IN CARE
                    <br />
                    AND THE MEALS RECEIVED WHILE IN CARE
                  </th>
                </tr>
                <tr>
                  <th rowSpan={2} className="text-center" colSpan={2}>
                    Check (<img src={Tick} alt="tick" className="tickImg" />)
                    Days Child Normally in Care
                  </th>
                  <th colSpan={4} className="text-center">
                    List hours child normally in care
                  </th>
                  <th colSpan={6} className="text-center">
                    Check (<img src={Tick} alt="tick" className="tickImg" />)
                    meals child normally receives while in care
                  </th>
                </tr>
                <tr className="row-center">
                  <th>Arrive</th>
                  <th>Depart</th>
                  <th>Arrive</th>
                  <th>Depart</th>
                  <th>Breakfast</th>
                  <th>Am Snack</th>
                  <th>Lunch</th>
                  <th>Pm Snack</th>
                  <th>Supper</th>
                  <th>Evening Snack</th>
                </tr>
              </thead>
              <tbody>
                {days?.map((day, index) => (
                  <tr className="daysRow" key={index}>
                    <th>{day.name}</th>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.tick}
                          name="tick"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.tick ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          value={day.arrive1}
                          name="arrive1"
                          onChange={(e) => onTimeChange(e, index)}
                          type="time"
                          placeholder="arrival time"
                        />
                      ) : day?.arrive1 ? (
                        format(
                          parse(`${day.arrive1}`, "HH:mm", new Date()),
                          "hh:mm a"
                        )
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          value={day.depart1}
                          name="depart1"
                          onChange={(e) => onTimeChange(e, index)}
                          type="time"
                          placeholder="depart time"
                        />
                      ) : day?.depart1 ? (
                        format(
                          parse(`${day.depart1}`, "HH:mm", new Date()),
                          "hh:mm a"
                        )
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          value={day.arrive2}
                          name="arrive2"
                          onChange={(e) => onTimeChange(e, index)}
                          type="time"
                          placeholder="arrival time"
                        />
                      ) : day?.arrive2 ? (
                        format(
                          parse(`${day.arrive2}`, "HH:mm", new Date()),
                          "hh:mm a"
                        )
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          value={day.depart2}
                          name="depart2"
                          onChange={(e) => onTimeChange(e, index)}
                          type="time"
                          placeholder="depart time"
                        />
                      ) : day.depart2 ? (
                        format(
                          parse(`${day.depart2}`, "HH:mm", new Date()),
                          "hh:mm a"
                        )
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.breakfast}
                          name="breakfast"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.breakfast ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.amSnack}
                          name="amSnack"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.amSnack ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.lunch}
                          name="lunch"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.lunch ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.pmSnack}
                          name="pmSnack"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.pmSnack ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.supper}
                          name="supper"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.supper ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                    <td>
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={day.eveningSnack}
                          name="eveningSnack"
                          onChange={(e) => onTickChange(e, index)}
                        />
                      ) : day.eveningSnack ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={12}>
                    <div className="changedtick">
                      {edit ? (
                        <Input
                          type="checkbox"
                          checked={vary}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              vary: !formValues.vary,
                            })
                          }
                        />
                      ) : vary ? (
                        <img src={Tick} alt="tick" className="checked" />
                      ) : (
                        <img src={UnTick} alt="untick" className="unchecked" />
                      )}
                      Yes, the schedule listed above may frequently vary due to
                      changes in parents/guardians schedule.
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="parentInfoBox">
            <div className="firstRow">
              <div className="signature">
                <b>SIGNATURE OF PARENT/GUARDIAN:</b>
                <img src={parent?.signature} alt="signature" />
              </div>
              <div className="date">
                <b>DATE: </b>
                {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
              </div>
              <div className="phoneNumber">
                <b>DAY PHONE NUMBER:</b>
                {parent?.dayPhone}
              </div>
            </div>
            <div className="secondRow">
              <div className="address">
                <b>MAILING ADDRESS: </b>
                {parent?.streetAptNo},{parent?.city}, {parent?.zipcode}
              </div>
            </div>
            <div className="thirRow">
              <div className="street">
                <b>STREET /APT.: </b>
                {parent?.streetAptNo}
              </div>
              <div className="city">
                <b>CITY: </b>
                {parent?.city}
              </div>
              <div className="zipCode">
                <b>ZIP CODE: </b>
                {parent?.zipcode}
              </div>
            </div>
            <div className="rights">
              In accordance with Federal civil rights law and U.S. Department of
              Agriculture (USDA) civil rights regulations and policies, the
              USDA, its Agencies, offices, and employees, and institutions
              participating in or administering USDA programs are prohibited
              from discriminating based on race, color, national origin, sex,
              disability, age, or reprisal or retaliation for prior civil rights
              activity in any program or activity conducted or funded by USDA.
              Persons with disabilities who require alternative means of
              communication for program information (e.g. Braille, large print,
              audiotape, American Sign Language, etc.), should contact the
              Agency (State or local) where they applied for benefits.
              Individuals who are deaf, hard of hearing or have speech
              disabilities may contact USDA through the Federal Relay Service at
              (800) 877-8339. Additionally, program information may be made
              available in languages other than English. To file a program
              complaint of discrimination, complete the USDA Program
              Discrimination Complaint Form, (AD-3027) found online at:
              http://www.ascr.usda.gov/complaint_filing_cust.html, and at any
              USDA office, or write a letter addressed to USDA and provide in
              the letter all of the information requested in the form. To
              request a copy of the complaint form, call (866) 632-9992. Submit
              your completed form or letter to USDA by: (1) mail: U.S.
              Department of Agriculture, Office of the Assistant Secretary for
              Civil Rights, 1400 Independence Avenue, SW, Washington, D.C.
              20250-9410; (2) fax: (202) 690-7442; or (3)
              email:program.intake@usda.gov. This institution is an equal
              opportunity provider
            </div>
          </div>
        </div>
        <div className="actions">
          {edit ? (
            <Button
              text="Update Form"
              type="primary"
              onClick={() =>
                updateForm(formValues._id, {
                  days: [...formValues.days],
                  vary: formValues.vary,
                })
              }
            />
          ) : (
            <Button
              text="Edit Form"
              type="secondary"
              onClick={() => setEdit((prev) => !prev)}
            />
          )}
          <Button
            text="Download Form"
            type="secondary"
            onClick={createAndDownloadPdf}
          />
        </div>
      </div>
    </SubDashboardLayout>
  );
};

export default EnrollmentFormPage;
