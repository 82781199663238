import React, { useEffect } from "react";
import SubDashboardLayout from "../../../components/SubDashboardLayout";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
// actions
import { getOrdersByDriver, updateOrder } from "../../../actions";
// component
import Select from "../../../components/FormComponents/Select";
import Loader from "../../../components/Loader";

const RouteAssignedPage = () => {
  const dispatch = useDispatch();

  const Orders = useSelector((state) => state.order.orders);
  const loading = useSelector((state) => state.order.loading);
  const user = useSelector((state) => state.auth.user);
  const orderUpdated = useSelector((state) => state.order.orderUpdated);

  useEffect(() => {
    if (user._id) {
      dispatch(getOrdersByDriver(user._id));
    }
  }, [user._id, orderUpdated]);
  return (
    <SubDashboardLayout
      title={"Driver"}
      operationName={`Route Assigned`}
      id={"routeAssign"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>Meal Type</th>
              <th>Meals Required</th>
              <th>Site</th>
              <th>Comments</th>
              <th>Order Created (d/m/y)</th>
              <th>Status</th>
            </tr>
            {Orders.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Route yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Orders.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order.mealType}</td>
                  <td>{order.mealRequired}</td>
                  <td>{order.vendor.vendorName}</td>
                  <td>{order.comment}</td>
                  <td>{format(new Date(order.createdAt), "dd-MM-yyyy")}</td>
                  <td>
                    <Select
                      defaultValue={order.status}
                      onChange={(e) =>
                        dispatch(
                          updateOrder(order._id, { status: e.target.value })
                        )
                      }
                    >
                      <option value="pending">Pending</option>
                      <option value="complete">Complete</option>
                    </Select>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default RouteAssignedPage;
