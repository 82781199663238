import {
  invoiceConstants,
  driverConstants,
  vendorConstants,
} from "../actions/constants";

const initialState = {
  invoices: [],
  drivers: [],
  loading: false,
  error: null,
  message: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case invoiceConstants.GET_INVOICE_BY_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invoiceConstants.GET_INVOICE_BY_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: payload,
      };
    case invoiceConstants.GET_INVOICE_BY_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case driverConstants.GET_DRIVERS_BY_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case driverConstants.GET_DRIVERS_BY_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: payload,
      };
    case driverConstants.GET_DRIVERS_BY_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case vendorConstants.UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vendorConstants.UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Updated Successfully",
      };
    case vendorConstants.UPDATE_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Update was not successful",
      };
    default:
      return { ...state };
  }
};
