import axios from "../helper/axios";
import { studentConstants } from "./constants";

export const searchStudents = (text, siteId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: studentConstants.GET_STUDENTS_BY_SITE_REQUEST });
      const res = await axios.get(
        `search/students?fullName=${text}&site=${siteId}`
      );
      if (res.status === 200) {
        return dispatch({
          type: studentConstants.GET_STUDENTS_BY_SITE_SUCCESS,
          payload: res.data.students,
        });
      }
      dispatch({ type: studentConstants.GET_STUDENTS_BY_SITE_FAILURE });
    } catch (err) {
      console.log(err);
      dispatch({
        type: studentConstants.GET_STUDENTS_BY_SITE_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getAllStudentsBySite = (site) => {
  return async (dispatch) => {
    try {
      dispatch({ type: studentConstants.GET_STUDENTS_BY_SITE_REQUEST });
      const res = await axios.get(`students/${site}`);
      if (res.status === 200) {
        return dispatch({
          type: studentConstants.GET_STUDENTS_BY_SITE_SUCCESS,
          payload: res.data.students,
        });
      }
      dispatch({ type: studentConstants.GET_STUDENTS_BY_SITE_FAILURE });
    } catch (err) {
      dispatch({
        type: studentConstants.GET_STUDENTS_BY_SITE_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getStudentsById = (studentId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: studentConstants.GET_STUDENTS_BY_ID_REQUEST });
      let res = await axios.get(`/students/get/${studentId}`);
      if (res.status === 200) {
        dispatch({
          type: studentConstants.GET_STUDENTS_BY_ID_SUCCESS,
          payload: res.data.student,
        });
      } else {
        dispatch({ type: studentConstants.GET_STUDENTS_BY_ID_FAILURE });
      }
    } catch (err) {
      dispatch({
        type: studentConstants.GET_STUDENTS_BY_ID_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const AddStudents = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: studentConstants.ADD_STUDENTS_REQUEST });
      let res = await axios.post(`/students/add`, value);
      if (res.status === 201) {
        return dispatch({
          type: studentConstants.ADD_STUDENTS_SUCCESS,
          payload: res.status,
        });
      }
      dispatch({ type: studentConstants.ADD_STUDENTS_FAILURE });
    } catch (err) {
      dispatch({
        type: studentConstants.ADD_STUDENTS_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const UpdateStudent = (updatedValue, studentId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: studentConstants.UPDATE_STUDENT_REQUEST });
      let res = await axios.put(`/students/update/${studentId}`, updatedValue);
      if (res.status === 200) {
        return dispatch({
          type: studentConstants.UPDATE_STUDENT_SUCCESS,
          payload: updatedValue,
        });
      }
      dispatch({ type: studentConstants.UPDATE_STUDENT_FAILURE });
    } catch (err) {
      dispatch({
        type: studentConstants.UPDATE_STUDENT_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getAllStudentsWithMealBySite = (site, weeks) => {
  return async (dispatch) => {
    dispatch({ type: studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_REQUEST });
    let res = await axios.post(`meal/students/${site}`, { weeks });
    if (res.status === 200) {
      return dispatch({
        type: studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_SUCCESS,
        payload: res.data,
      });
    }
    dispatch({ type: studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_FAILURE });

    try {
    } catch (err) {
      dispatch({
        type: studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

// get enrollmentform
export const getEnrollmentForm = (studentId) => {
  return async (dispatch) => {
    dispatch({ type: studentConstants.GET_STUDENT_ENROLLMENT_FORM_REQUEST });
    let res = await axios.get(`enrollment/get/${studentId}`);
    if (res.status === 200 || res.status === 201) {
      return dispatch({
        type: studentConstants.GET_STUDENT_ENROLLMENT_FORM_SUCCESS,
        payload: res.data.enrollmentForm,
      });
    }
    dispatch({ type: studentConstants.GET_STUDENT_ENROLLMENT_FORM_FAILURE });

    try {
    } catch (err) {
      dispatch({
        type: studentConstants.GET_STUDENT_ENROLLMENT_FORM_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const updateEnrollmentForm = (enrollmentId, value) => {
  return async (dispatch) => {
    dispatch({ type: studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_REQUEST });
    let res = await axios.put(`enrollment/update/${enrollmentId}`, value);
    if (res.status === 200) {
      return dispatch({
        type: studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_SUCCESS,
        payload: res.data.enrollmentForm,
      });
    }
    dispatch({ type: studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_FAILURE });

    try {
    } catch (err) {
      dispatch({
        type: studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};
