import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Button = (props) => {
  let { text, type, to } = props;

  const generateBgColor = (type) => {
    switch (type) {
      case "primary":
        return {
          color: "#fff",
          backgroundColor: "#5cb85c",
        };
      case "secondary":
        return { color: "#fff", backgroundColor: "#337ab7" };
      case "danger":
        return {
          color: "#fff",
          backgroundColor: "#d9534f",
        };
      default:
        return { color: "#000", backgroundColor: "#fff" };
    }
  };

  return to ? (
    <Link to={to}>
      <button className="generalBtn" style={generateBgColor(type)} {...props}>
        {text}
      </button>
    </Link>
  ) : (
    <button className="generalBtn" style={generateBgColor(type)} {...props}>
      {text}
    </button>
  );
};

export default Button;
