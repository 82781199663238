import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";
// material table
import Table from "../../components/Table";
import { deleteAdmin, getAllUser } from "../../actions";

const AdminManagementPanel = (props) => {
  // state
  const users = useSelector((state) => state.admin.users);
  const userDeleted = useSelector((state) => state.admin.userDeleted);
  const loading = useSelector((state) => state.admin.loading);
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);

  let dispatch = useDispatch();

  useEffect(() => {
    if (user._id && userType === "admin") {
      dispatch(getAllUser());
    }
  }, [user._id, userType, userDeleted]);

  return (
    <SubDashboardLayout title={"Admin"} id={"adminMangementPanel"}>
      <Table
        actions={[
          (rowData) => ({
            icon: "clear",
            tooltip: rowData._id === user._id ? "Me" : "Delete Admin",
            onClick: (event, rowData) => dispatch(deleteAdmin(rowData._id)),
            disabled: rowData._id === user._id,
          }),
        ]}
        options={{
          paging: false,
          search: false,
          headerStyle: {
            backgroundColor: "rgb(233 233 233)",
            color: "#000",
          },
          draggable: false,
        }}
        columns={[
          { title: "Username", field: "userName" },
          { title: "Role", field: "role" },
        ]}
        data={users}
        title="Admin Management Table"
      />
    </SubDashboardLayout>
  );
};

export default AdminManagementPanel;
