import React, { useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";

// icons
import {
  // FaRegEnvelope,
  // FaRegBell,
  FaChevronDown,
  FaUser,
} from "react-icons/fa";
import { HiLogin, HiLogout } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { signout } from "../../../actions";

const Header = () => {
  const auth = useSelector((state) => state.auth);
  const [drop, setDrop] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signOut = () => {
    dispatch(signout());
    navigate("/");
  };

  return (
    <header>
      <div className="container">
        <div className="headerLeft">
          <Link to="/" className="logo">
            ChildCare
          </Link>
        </div>
        <div className="headerRight">
          {/* <div className="mailBox">
            <FaRegEnvelope size={25} />
          </div>
          <div className="notificationBell">
            <FaRegBell size={25} />
          </div> */}
          <div className="user">
            <FaUser size={25} />
            <div className="userName" onClick={() => setDrop((prev) => !prev)}>
              {auth.authenticate ? (
                <p
                  style={{
                    color: "#383838",
                    margin: 0,
                  }}
                >
                  {auth.user.userName}
                </p>
              ) : (
                "Login"
              )}
              <FaChevronDown size={15} className="dropdownicon" />
            </div>
            <div className={`userDropDown ${drop ? "drop" : ""}`}>
              {auth.authenticate ? (
                <ul>
                  <li onClick={signOut}>
                    <HiLogout />
                    Signout
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to="/admin/signin">Admin</Link>
                  </li>
                  <li>
                    <Link to="/sites/signin">Site</Link>
                  </li>
                  <li>
                    <Link to="/vendors/signin">Vendor</Link>
                  </li>
                  <li>
                    <Link to="/drivers/signin">Driver</Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
