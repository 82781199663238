import React from "react";
import {
  FaCheckCircle,
  FaFileInvoiceDollar,
  // FaHeadset,
  FaHome,
  FaList,
  FaRegEdit,
  FaRegEye,
  FaScroll,
  FaShoppingCart,
  FaUserNurse,
  // FaWrench,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";

const AdminDashboard = () => {
  return (
    <DashboardLayout optionsLength={5} DashboardTitle="Admin">
      <Link to="sites">
        <FaHome />
        <span>Sites</span>
      </Link>
      <Link to="vendors">
        <FaShoppingCart />
        <span>Vendors</span>
      </Link>
      <Link to="invoices">
        <FaFileInvoiceDollar />
        <span>Invoices</span>
      </Link>
      <Link to="orders">
        <FaRegEdit />
        <span>Orders</span>
      </Link>
      <Link
        to="menu"
        style={{
          borderRight: "0",
        }}
      >
        <FaList />
        <span>Menu</span>
      </Link>
      <Link to="delivery-slip">
        <FaScroll />
        <span>Delivery Slip</span>
      </Link>
      <Link
        to="cn-labels"
        style={{
          pointerEvents: "none",
          color: "#d2d2d2",
        }}
      >
        <FaCheckCircle />
        <span>CN Labels</span>
      </Link>
      <Link to="monitor-site">
        <FaRegEye />
        <span>Montor Site</span>
      </Link>
      <Link to="users">
        <FaUserNurse />
        <span>Admins</span>
      </Link>
      {/* <Link to="communications">
        <FaHeadset />
        <span>Communications</span>
      </Link>
      <Link to="settings">
        <FaWrench />
        <span>Settings</span>
      </Link> */}
    </DashboardLayout>
  );
};

export default AdminDashboard;
