import React from "react";
import TextArea from "../../FormComponents/TextArea";

const Supper = (props) => {
  let { edit, setSupper, supper } = props;

  const setMeal = (e, index, name) => {
    let newArr = [...supper];
    newArr[index][name] = e.target.value;
    setSupper(newArr);
  };

  return (
    <>
      {supper.map((meal, index) =>
        index === 0 ? (
          <tr className="supper" key={index}>
            <th rowSpan={supper.length}>
              <p className="rotateText">Supper</p>
            </th>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].items}
                  placeholder="items"
                  onChange={(e) => setMeal(e, index, "items")}
                />
              ) : (
                <pre>{supper[index].items}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity1}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity1")}
                />
              ) : (
                <pre>{supper[index].quantity1}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity2}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity2")}
                />
              ) : (
                <pre>{supper[index].quantity2}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity3}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity3")}
                />
              ) : (
                <pre>{supper[index].quantity3}</pre>
              )}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.mon}
                  onChange={(e) => setMeal(e, index, "mon")}
                />
              )}
              {meal.mon}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.tues}
                  onChange={(e) => setMeal(e, index, "tues")}
                />
              )}
              {meal.tues}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.wed}
                  onChange={(e) => setMeal(e, index, "wed")}
                />
              )}
              {meal.wed}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.thurs}
                  onChange={(e) => setMeal(e, index, "thurs")}
                />
              )}
              {meal.thurs}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.fri}
                  onChange={(e) => setMeal(e, index, "fri")}
                />
              )}
              {meal.fri}
            </td>
          </tr>
        ) : (
          <tr className="supper" key={index}>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].items}
                  placeholder="items"
                  onChange={(e) => setMeal(e, index, "items")}
                />
              ) : (
                <pre>{supper[index].items}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity1}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity1")}
                />
              ) : (
                <pre>{supper[index].quantity1}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity2}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity2")}
                />
              ) : (
                <pre>{supper[index].quantity2}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={supper[index].quantity3}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity3")}
                />
              ) : (
                <pre>{supper[index].quantity3}</pre>
              )}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.mon}
                  onChange={(e) => setMeal(e, index, "mon")}
                />
              )}
              {meal.mon}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.tues}
                  onChange={(e) => setMeal(e, index, "tues")}
                />
              )}
              {meal.tues}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.wed}
                  onChange={(e) => setMeal(e, index, "wed")}
                />
              )}
              {meal.wed}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.thurs}
                  onChange={(e) => setMeal(e, index, "thurs")}
                />
              )}
              {meal.thurs}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.fri}
                  onChange={(e) => setMeal(e, index, "fri")}
                />
              )}
              {meal.fri}
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default Supper;
