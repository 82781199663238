import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const downloadPdfDocument = (tableId) => {
  const input = document.getElementById(tableId);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [1600, 1000],
    });
    pdf.addImage(imgData, "JPEG", 0, 0);
    pdf.save("students.pdf");
  });
};

export default downloadPdfDocument;
