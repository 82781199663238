import { parentConstants } from "../actions/constants";

const initialState = {
  parents: [],
  parent: {
    firstName: "",
    lastName: "",
    userName: "",
    streetAptNo: "",
    city: "",
    zipcode: "",
    dayPhone: "",
    workPhone: "",
    ssn: "",
    password: "",
    racialCategory: "",
    ethnicCategory: "",
    signature: "",
    siteName: "",
    students: [],
  },
  loading: false,
  error: null,
  message: "",
  status: 0,
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case parentConstants.GET_PARENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case parentConstants.GET_PARENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        parent: payload,
      };
    case parentConstants.GET_PARENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case parentConstants.ADD_PARENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstants.ADD_PARENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        parent: payload,
      };
    case parentConstants.ADD_PARENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case parentConstants.UPDATE_PARENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case parentConstants.UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        loading: false,
        parent: payload,
        status: 200,
        message: "Parent Updated",
      };
    case parentConstants.UPDATE_PARENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    default:
      return { ...state };
  }
};
