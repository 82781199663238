import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

// actions
import { AddDriver, getDriverById, updateDriver } from "../../actions";

// components
import Button from "../Button";
import Input from "../FormComponents/Input";
import Signature from "../FormComponents/Signature";
import Notification from "../Notification";

// layout
import SubDashboardLayout from "../SubDashboardLayout";
import { useParams } from "react-router-dom";

const DriverForm = (props) => {
  // props
  let { action } = props;
  let { driverId } = useParams();

  // state
  const Driver = useSelector((state) => state.driver);
  const user = useSelector((state) => state.auth.user);
  const driverStatus = useSelector((state) => state.driver.status);
  const driverMsg = useSelector((state) => state.driver.message);

  const initialState = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    signature: "",
  };

  const [formValue, setFormValues] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (action === "Update") {
      return dispatch(getDriverById(driverId));
    }
  }, [action]);

  useEffect(() => {
    if (action === "Update") {
      setFormValues(Driver.driver);
    }
  }, [Driver.driver]);

  useEffect(() => {
    setLoading(Driver.loading);
  }, [Driver.loading]);

  useEffect(() => {
    if (
      Driver.error !== null ||
      Driver.status === 201 ||
      Driver.status === 200
    ) {
      setShow(true);
    }
  }, [Driver.status, Driver.error]);

  // destructed props
  let { firstName, lastName, userName, password, signature } = formValue;
  const formRef = useRef();
  const signCanvas = useRef({});

  //   onchange
  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setShow(false);
    // canvas to dataurl
    let sign = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    formValue.signature = sign;

    action === "Create"
      ? dispatch(AddDriver({ ...formValue, vendor: user._id }))
      : dispatch(updateDriver(driverId, formValue));

    if (!loading && action === "Create") {
      setFormValues({
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        signature: "",
      });
      signCanvas.current.clear();
    }
  };

  const clear = (e) => {
    e.preventDefault();
    signCanvas.current.clear();
  };

  useEffect(() => {
    setShow(false);
    if (driverStatus === 200 || driverStatus === 201) {
      setShow(true);
    }
  }, [driverStatus]);
  // reset the fields if the action is create
  useEffect(() => {
    if (action === "Create") {
      setFormValues(initialState);
      setShow(false);
    }
  }, [action]);

  return (
    <SubDashboardLayout
      title={"Vendor"}
      operationName={`${action} Driver`}
      id={"driverFormPage"}
      to={"../"}
      loading={loading}
    >
      <Notification show={show} type={"success"} message={driverMsg} />
      <div className="formContainer">
        <form className="driverForm" ref={formRef}>
          <div className="twoFields">
            <Input
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={onChange}
            />
            <Input
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={onChange}
            />
          </div>
          <div className="twoFields">
            <Input
              label="Username"
              name="userName"
              value={userName}
              onChange={onChange}
            />
            <Input
              label="Password"
              name="password"
              value={password}
              onChange={onChange}
            />
          </div>
          {signature && (
            <div className="signature">
              <label>Parent's signature:</label>
              <div className="signatureContainer">
                <img src={formValue.signature} alt="signautre" />
              </div>
              <Button
                text="Remove Signature"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormValues((prevState) => ({
                    ...prevState,
                    signature: "",
                  }));
                }}
              />
            </div>
          )}
          <Signature clear={clear} canvas={signCanvas} />

          <Button
            text={
              loading
                ? `${action === "Update" ? "Updating" : "Adding"} information`
                : `${action} driver`
            }
            type="primary"
            onClick={onSubmit}
          />
        </form>
      </div>
    </SubDashboardLayout>
  );
};

export default DriverForm;
