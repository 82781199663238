import "./style.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";
import MasterList from "../../../components/MasterList";
// import axios from "../../../helper/axios";
import { getAllStudentsBySite } from "../../../actions/student.action";

// icon

const SiteMasterListPage = () => {
  const Student = useSelector((state) => state.student);
  const user = useSelector((state) => state.auth.user);
  const parentAdded = useSelector((state) => state.parent.parent);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user._id) {
      dispatch(getAllStudentsBySite(user._id));
    }
  }, [user._id, parentAdded]);

  return (
    <SubDashboardLayout
      title={user.siteName}
      operationName="MasterList"
      id="siteMasterList"
    >
      <MasterList students={Student.students} />
    </SubDashboardLayout>
  );
};

export default SiteMasterListPage;
