import React, { useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useParams } from "react-router-dom";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";

// helper

import Notification from "../../components/Notification";
import ParentForm from "../../components/ParentForm";
import { useSelector } from "react-redux";

const ParentFormPage = (props) => {
  const user = useSelector((state) => state.auth.user);
  // props
  let { action, id } = props;
  // queries
  const studentId = new URLSearchParams(useLocation().search).get("studentId");
  // params
  const { parentId } = useParams();

  const [show, setShow] = useState(false);

  return (
    <SubDashboardLayout
      title={user.siteName}
      operationName={`${action} Parent information`}
      id={"siteParentForm"}
      to={"/sites/master-list"}
    >
      <Notification
        message={"Updated Successfully!"}
        type={"success"}
        show={show}
      />
      <ParentForm
        action={action}
        site={user.siteName}
        to={"/sites/master-list"}
        studentId={studentId}
        parentId={parentId}
        setShow={setShow}
      />
    </SubDashboardLayout>
  );
};

export default ParentFormPage;
