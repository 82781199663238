import axios from "../helper/axios";
import { vendorConstants } from "./constants";

export const vendorSignin = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.VENDOR_SIGNIN_REQUEST });
      let res = await axios.post(`/vendors/signin`, credentials);

      if (res.status === 200) {
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("type", "vendor");
        return dispatch({
          type: vendorConstants.VENDOR_SIGNIN_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: vendorConstants.VENDOR_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.VENDOR_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    }
  };
};

export const getAllVendors = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.GET_ALL_VENDORS_REQUEST });
      let res = await axios.get(`/vendors/get/all`);

      if (res.status === 200) {
        return dispatch({
          type: vendorConstants.GET_ALL_VENDORS_SUCCESS,
          payload: res.data.vendors,
        });
      }
      dispatch({
        type: vendorConstants.GET_ALL_VENDORS_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.GET_ALL_VENDORS_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};

export const addVendor = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.ADD_VENDOR_REQUEST });
      let res = await axios.post(`/vendors/add`, value);
      if (res.status === 201) {
        console.log(res);
        return dispatch({
          type: vendorConstants.ADD_VENDOR_SUCCESS,
          payload: res.data.vendor,
        });
      }
      dispatch({
        type: vendorConstants.ADD_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.ADD_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};

export const deleteVendor = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.DELETE_VENDOR_REQUEST });
      let res = await axios.delete(`/vendors/delete/${id}`);
      if (res.status === 200) {
        dispatch({
          type: vendorConstants.DELETE_VENDOR_SUCCESS,
        });
        return dispatch(getAllVendors());
      }
      dispatch({
        type: vendorConstants.DELETE_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.DELETE_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
export const updateVendor = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.UPDATE_VENDOR_REQUEST });
      let res = await axios.put(`/vendors/update/${id}`, value);
      if (res.status === 200) {
        return dispatch({
          type: vendorConstants.UPDATE_VENDOR_SUCCESS,
        });
      }
      dispatch({
        type: vendorConstants.UPDATE_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.UPDATE_VENDOR_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
export const getVendorById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: vendorConstants.GET_VENDOR_BY_ID_REQUEST });
      let res = await axios.get(`/vendors/get/${id}`);
      if (res.status === 200) {
        console.log(res);
        return dispatch({
          type: vendorConstants.GET_VENDOR_BY_ID_SUCCESS,
          payload: res.data.vendor,
        });
      }
      dispatch({
        type: vendorConstants.GET_VENDOR_BY_ID_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: vendorConstants.GET_VENDOR_BY_ID_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
