export const studentConstants = {
  GET_STUDENTS_BY_SITE_REQUEST: "GET_STUDENTS_BY_SITE_REQUEST",
  GET_STUDENTS_BY_SITE_SUCCESS: "GET_STUDENTS_BY_SITE_SUCCESS",
  GET_STUDENTS_BY_SITE_FAILURE: "GET_STUDENTS_BY_SITE_FAILURE",
  GET_STUDENTS_BY_ID_REQUEST: "GET_STUDENTS_BY_ID_REQUEST",
  GET_STUDENTS_BY_ID_SUCCESS: "GET_STUDENTS_BY_ID_SUCCESS",
  GET_STUDENTS_BY_ID_FAILURE: "GET_STUDENTS_BY_ID_FAILURE",
  ADD_STUDENTS_REQUEST: "ADD_STUDENTS_REQUEST",
  ADD_STUDENTS_SUCCESS: "ADD_STUDENTS_SUCCESS",
  ADD_STUDENTS_FAILURE: "ADD_STUDENTS_FAILURE",
  UPDATE_STUDENT_REQUEST: "UPDATE_STUDENT_REQUEST",
  UPDATE_STUDENT_SUCCESS: "UPDATE_STUDENT_SUCCESS",
  UPDATE_STUDENT_FAILURE: "UPDATE_STUDENT_FAILURE",
  GET_STUDENTS_WITH_MEAL_BY_SITE_REQUEST:
    "GET_STUDENTS_WITH_MEAL_BY_SITE_REQUEST",
  GET_STUDENTS_WITH_MEAL_BY_SITE_SUCCESS:
    "GET_STUDENTS_WITH_MEAL_BY_SITE_SUCCESS",
  GET_STUDENTS_WITH_MEAL_BY_SITE_FAILURE:
    "GET_STUDENTS_WITH_MEAL_BY_SITE_FAILURE",
  GET_STUDENT_ENROLLMENT_FORM_REQUEST: "GET_STUDENT_ENROLLMENT_FORM_REQUEST",
  GET_STUDENT_ENROLLMENT_FORM_SUCCESS: "GET_STUDENT_ENROLLMENT_FORM_SUCCESS",
  GET_STUDENT_ENROLLMENT_FORM_FAILURE: "GET_STUDENT_ENROLLMENT_FORM_REQUEST",
  UPDATE_STUDENT_ENROLLMENT_FORM_REQUEST:
    "UPDATE_STUDENT_ENROLLMENT_FORM_REQUEST",
  UPDATE_STUDENT_ENROLLMENT_FORM_SUCCESS:
    "UPDATE_STUDENT_ENROLLMENT_FORM_SUCCESS",
  UPDATE_STUDENT_ENROLLMENT_FORM_FAILURE:
    "UPDATE_STUDENT_ENROLLMENT_FORM_REQUEST",
};

export const mealConstants = {
  ADD_MEAL_REQUEST: "ADD_MEAL_REQUEST",
  ADD_MEAL_SUCCESS: "ADD_MEAL_SUCCESS",
  ADD_MEAL_FAILURE: "ADD_MEAL_FAILURE",
  GET_MEAL_COUNT_REQUEST: "GET_MEAL_COUNT_REQUEST",
  GET_MEAL_COUNT_SUCCESS: "GET_MEAL_COUNT_SUCCESS",
  GET_MEAL_COUNT_FAILURE: "GET_MEAL_COUNT_FAILURE",
};

export const parentConstants = {
  GET_PARENT_BY_ID_REQUEST: "GET_PARENT_BY_ID_REQUEST",
  GET_PARENT_BY_ID_SUCCESS: "GET_PARENT_BY_ID_SUCCESS",
  GET_PARENT_BY_ID_FAILURE: "GET_PARENT_BY_ID_FAILURE",
  ADD_PARENTS_REQUEST: "ADD_PARENTS_REQUEST",
  ADD_PARENTS_SUCCESS: "ADD_PARENTS_SUCCESS",
  ADD_PARENTS_FAILURE: "ADD_PARENTS_FAILURE",
  UPDATE_PARENT_REQUEST: "UPDATE_PARENT_REQUEST",
  UPDATE_PARENT_SUCCESS: "UPDATE_PARENT_SUCCESS",
  UPDATE_PARENT_FAILURE: "UPDATE_PARENT_FAILURE",
};

export const orderConstants = {
  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
  GET_ORDERS_BY_VENDOR_REQUEST: "GET_ORDERS_BY_VENDOR_REQUEST",
  GET_ORDERS_BY_VENDOR_SUCCESS: "GET_ORDERS_BY_VENDOR_SUCCESS",
  GET_ORDERS_BY_VENDOR_FAILURE: "GET_ORDERS_BY_VENDOR_FAILURE",
  GET_ORDERS_BY_DRIVER_REQUEST: "GET_ORDERS_BY_DRIVER_REQUEST",
  GET_ORDERS_BY_DRIVER_SUCCESS: "GET_ORDERS_BY_DRIVER_SUCCESS",
  GET_ORDERS_BY_DRIVER_FAILURE: "GET_ORDERS_BY_DRIVER_FAILURE",
  GET_ORDERS_BY_SITE_REQUEST: "GET_ORDERS_BY_SITE_REQUEST",
  GET_ORDERS_BY_SITE_SUCCESS: "GET_ORDERS_BY_SITE_SUCCESS",
  GET_ORDERS_BY_SITE_FAILURE: "GET_ORDERS_BY_SITE_FAILURE",
  GET_ALL_ORDERS_REQUEST: "GET_ALL_ORDERS_REQUEST",
  GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
  GET_ALL_ORDERS_FAILURE: "GET_ALL_ORDERS_FAILURE",
  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
};

export const invoiceConstants = {
  GET_INVOICE_BY_VENDOR_REQUEST: "GET_INVOICE_BY_VENDOR_REQUEST",
  GET_INVOICE_BY_VENDOR_SUCCESS: "GET_INVOICE_BY_VENDOR_SUCCESS",
  GET_INVOICE_BY_VENDOR_FAILURE: "GET_INVOICE_BY_VENDOR_FAILURE",
  ADD_INVOICE_REQUEST: "ADD_INVOICE_REQUEST",
  ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
  ADD_INVOICE_FAILURE: "ADD_INVOICE_FAILURE",
  GET_INVOICE_BY_ID_REQUEST: "GET_INVOICE_BY_ID_REQUEST",
  GET_INVOICE_BY_ID_SUCCESS: "GET_INVOICE_BY_ID_SUCCESS",
  GET_INVOICE_BY_ID_FAILURE: "GET_INVOICE_BY_ID_FAILURE",
  UPDATE_INVOICE_REQUEST: "UPDATE_INVOICE_REQUEST",
  UPDATE_INVOICE_SUCCESS: "UPDATE_INVOICE_SUCCESS",
  UPDATE_INVOICE_FAILURE: "UPDATE_INVOICE_FAILURE",
  DELETE_INVOICE_REQUEST: "DELETE_INVOICE_REQUEST",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAILURE: "DELETE_INVOICE_FAILURE",
};

export const driverConstants = {
  GET_DRIVERS_BY_VENDOR_REQUEST: "GET_DRIVERS_BY_VENDOR_REQUEST",
  GET_DRIVERS_BY_VENDOR_SUCCESS: "GET_DRIVERS_BY_VENDOR_SUCCESS",
  GET_DRIVERS_BY_VENDOR_FAILURE: "GET_DRIVERS_BY_VENDOR_FAILURE",
  ADD_DRIVER_REQUEST: "ADD_DRIVER_REQUEST",
  ADD_DRIVER_SUCCESS: "ADD_DRIVER_SUCCESS",
  ADD_DRIVER_FAILURE: "ADD_DRIVER_FAILURE",
  GET_DRIVER_BY_ID_REQUEST: "GET_DRIVER_BY_ID_REQUEST",
  GET_DRIVER_BY_ID_SUCCESS: "GET_DRIVER_BY_ID_SUCCESS",
  GET_DRIVER_BY_ID_FAILURE: "GET_DRIVER_BY_ID_FAILURE",
  UPDATE_DRIVER_REQUEST: "UPDATE_DRIVER_REQUEST",
  UPDATE_DRIVER_SUCCESS: "UPDATE_DRIVER_SUCCESS",
  UPDATE_DRIVER_FAILURE: "UPDATE_DRIVER_FAILURE",
  DELETE_DRIVER_REQUEST: "DELETE_DRIVER_REQUEST",
  DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
  DELETE_DRIVER_FAILURE: "DELETE_DRIVER_FAILURE",
  DRIVER_SIGNIN_REQUEST: "DRIVER_SIGNIN_REQUEST",
  DRIVER_SIGNIN_SUCCESS: "DRIVER_SIGNIN_SUCCESS",
  DRIVER_SIGNIN_FAILURE: "DRIVER_SIGNIN_FAILURE",
};

export const authConstants = {
  AUTH_SIGNIN_REQUEST: "AUTH_SIGNIN_REQUEST",
  AUTH_SIGNIN_SUCCESS: "AUTH_SIGNIN_SUCCESS",
  AUTH_SIGNIN_FAILURE: "AUTH_SIGNIN_FAILURE",
  AUTH_SUPER_SIGNUP_REQUEST: "AUTH_SUPER_SIGNUP_REQUEST",
  AUTH_SUPER_SIGNUP_SUCCESS: "AUTH_SUPER_SIGNUP_SUCCESS",
  AUTH_SUPER_SIGNUP_FAILURE: "AUTH_SUPER_SIGNUP_FAILURE",
  GET_AUTHENTICATE_REQUEST: "GET_AUTHENTICATE_REQUEST",
  GET_AUTHENTICATE_SUCCESS: "GET_AUTHENTICATE_SUCCESS",
  GET_AUTHENTICATE_FAILURE: "GET_AUTHENTICATE_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const userConstants = {
  GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
  GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
  GET_ALL_USER_FAILURE: "GET_ALL_USER_FAILURE",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
};

export const siteConstants = {
  GET_ALL_SITES_REQUEST: "GET_ALL_SITES_REQUEST",
  GET_ALL_SITES_SUCCESS: "GET_ALL_SITES_SUCCESS",
  GET_ALL_SITES_FAILURE: "GET_ALL_SITES_FAILURE",
  GET_ALL_SITES_WITH_REPORT_REQUEST: "GET_ALL_SITES_WITH_REPORT_REQUEST",
  GET_ALL_SITES_WITH_REPORT_SUCCESS: "GET_ALL_SITES_WITH_REPORT_SUCCESS",
  GET_ALL_SITES_WITH_REPORT_FAILURE: "GET_ALL_SITES_WITH_REPORT_FAILURE",
  ADD_SITE_REQUEST: "ADD_SITE_REQUEST",
  ADD_SITE_SUCCESS: "ADD_SITE_SUCCESS",
  ADD_SITE_FAILURE: "ADD_SITE_FAILURE",
  DELETE_SITE_REQUEST: "DELETE_SITE_REQUEST",
  DELETE_SITE_SUCCESS: "DELETE_SITE_SUCCESS",
  DELETE_SITE_FAILURE: "DELETE_SITE_FAILURE",
  UPDATE_SITE_REQUEST: "UPDATE_SITE_REQUEST",
  UPDATE_SITE_SUCCESS: "UPDATE_SITE_SUCCESS",
  UPDATE_SITE_FAILURE: "UPDATE_SITE_FAILURE",
  GET_SITE_BY_ID_REQUEST: "GET_SITE_BY_ID_REQUEST",
  GET_SITE_BY_ID_SUCCESS: "GET_SITE_BY_ID_SUCCESS",
  GET_SITE_BY_ID_FAILURE: "GET_SITE_BY_ID_FAILURE",
  SITE_SIGNIN_REQUEST: "SITE_SIGNIN_REQUEST",
  SITE_SIGNIN_SUCCESS: "SITE_SIGNIN_SUCCESS",
  SITE_SIGNIN_FAILURE: "SITE_SIGNIN_FAILURE",
};

export const vendorConstants = {
  GET_ALL_VENDORS_REQUEST: "GET_ALL_VENDORS_REQUEST",
  GET_ALL_VENDORS_SUCCESS: "GET_ALL_VENDORS_SUCCESS",
  GET_ALL_VENDORS_FAILURE: "GET_ALL_VENDORS_FAILURE",
  ADD_VENDOR_REQUEST: "ADD_VENDOR_REQUEST",
  ADD_VENDOR_SUCCESS: "ADD_VENDOR_SUCCESS",
  ADD_VENDOR_FAILURE: "ADD_VENDOR_FAILURE",
  DELETE_VENDOR_REQUEST: "DELETE_VENDOR_REQUEST",
  DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
  DELETE_VENDOR_FAILURE: "DELETE_VENDOR_FAILURE",
  UPDATE_VENDOR_REQUEST: "UPDATE_VENDOR_REQUEST",
  UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
  UPDATE_VENDOR_FAILURE: "UPDATE_VENDOR_FAILURE",
  GET_VENDOR_BY_ID_REQUEST: "GET_VENDOR_BY_ID_REQUEST",
  GET_VENDOR_BY_ID_SUCCESS: "GET_VENDOR_BY_ID_SUCCESS",
  GET_VENDOR_BY_ID_FAILURE: "GET_VENDOR_BY_ID_FAILURE",
  VENDOR_SIGNIN_REQUEST: "VENDOR_SIGNIN_REQUEST",
  VENDOR_SIGNIN_SUCCESS: "VENDOR_SIGNIN_SUCCESS",
  VENDOR_SIGNIN_FAILURE: "VENDOR_SIGNIN_FAILURE",
};

export const menuConstants = {
  GET_MENU_REQUEST: "GET_MENU_REQUEST",
  GET_MENU_SUCCESS: "GET_MENU_SUCCESS",
  GET_MENU_FAILURE: "GET_MENU_FAILURE",
  UPDATE_MENU_REQUEST: "UPDATE_MENU_REQUEST",
  UPDATE_MENU_SUCCESS: "UPDATE_MENU_SUCCESS",
  UPDATE_MENU_FAILURE: "UPDATE_MENU_FAILURE",
};

export const deliverySlipConstants = {
  GET_DELIVERY_SLIP_REQUEST: "GET_DELIVERY_SLIP_REQUEST",
  GET_DELIVERY_SLIP_SUCCESS: "GET_DELIVERY_SLIP_SUCCESS",
  GET_DELIVERY_SLIP_FAILURE: "GET_DELIVERY_SLIP_FAILURE",
  GET_DELIVERY_SLIP_BY_ID_REQUEST: "GET_DELIVERY_SLIP_BY_ID_REQUEST",
  GET_DELIVERY_SLIP_BY_ID_SUCCESS: "GET_DELIVERY_SLIP_BY_ID_SUCCESS",
  GET_DELIVERY_SLIP_BY_ID_FAILURE: "GET_DELIVERY_SLIP_BY_ID_FAILURE",
  CREATE_DELIVERY_SLIP_REQUEST: "CREATE_DELIVERY_SLIP_REQUEST",
  CREATE_DELIVERY_SLIP_SUCCESS: "CREATE_DELIVERY_SLIP_SUCCESS",
  CREATE_DELIVERY_SLIP_FAILURE: "CREATE_DELIVERY_SLIP_FAILURE",
  UPDATE_DELIVERY_SLIP_REQUEST: "UPDATE_DELIVERY_SLIP_REQUEST",
  UPDATE_DELIVERY_SLIP_SUCCESS: "UPDATE_DELIVERY_SLIP_SUCCESS",
  UPDATE_DELIVERY_SLIP_FAILURE: "UPDATE_DELIVERY_SLIP_FAILURE",
  DELETE_DELIVERY_SLIP_REQUEST: "DELETE_DELIVERY_SLIP_REQUEST",
  DELETE_DELIVERY_SLIP_SUCCESS: "DELETE_DELIVERY_SLIP_SUCCESS",
  DELETE_DELIVERY_SLIP_FAILURE: "DELETE_DELIVERY_SLIP_FAILURE",
};
