import React, { useEffect, useState } from "react";

// helper
import getWeek from "../../../helper/getWeek";

const TableHeader = () => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    let today = new Date();
    setWeeks(getWeek(today));
  }, []);
  return (
    <>
      <tr className="labels">
        <th>Type</th>
        <th>Component</th>
        <th colSpan={3}>Minimum Serving</th>
      </tr>
      <tr className="labels2">
        <th></th>
        <th></th>
        <th>
          1 - 2 <br />
          years
        </th>
        <th>
          3 - 5 <br />
          years
        </th>
        <th>
          6 - 18 <br />
          years
        </th>
        <th>
          Mon.
          <div>{weeks[0]}</div>
        </th>
        <th>
          tues.
          <div>{weeks[1]}</div>
        </th>
        <th>
          wed.
          <div>{weeks[2]}</div>
        </th>
        <th>
          thurs.
          <div>{weeks[3]}</div>
        </th>
        <th>
          fri.
          <div>{weeks[4]}</div>
        </th>
      </tr>
    </>
  );
};

export default TableHeader;
