import React from "react";

// style
import "./style.scss";

import Header from "./Header";

// svg
import { ReactComponent as Wave } from "../../assets/wave.svg";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  const { children } = props;
  return (
    <>
      <Header />
      <Wave className="wave" />
      {children}
      <Outlet />
    </>
  );
};

export default Layout;
