import React from "react";
import "./style.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
// actions
import { getDrivers, getOrdersByVendor, updateOrder } from "../../../actions";
// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";
// components
import Select from "../../../components/FormComponents/Select";
import Loader from "../../../components/Loader";

const AssignRoutePage = (props) => {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const loading = useSelector((state) => state.order.loading);
  const drivers = useSelector((state) => state.vendor.drivers);
  const user = useSelector((state) => state.auth.user);
  const orderUpdated = useSelector((state) => state.order.orderUpdated);

  useEffect(() => {
    if (user._id) {
      dispatch(getOrdersByVendor(user._id));
      dispatch(getDrivers(user._id));
    }
  }, [user._id, orderUpdated]);

  const onSelectChange = (e, id) => {
    dispatch(updateOrder(id, { driver: e.target.value }, user._id));
  };
  return (
    <SubDashboardLayout
      title={"Vendor Dashboard"}
      operationName={`Assign Route`}
      id={"assignRoute"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>Meal Type</th>
              <th>Meals Required</th>
              <th>Site</th>
              <th>Comments</th>
              <th>Created At</th>
              <th>Assign to</th>
              <th>Status</th>
            </tr>
            {orders.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Orders yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              orders.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order.mealType}</td>
                  <td>{order.mealRequired}</td>
                  <td>{order.site.siteName}</td>
                  <td>{order.comment}</td>
                  <td>{format(new Date(order.createdAt), "dd-MM-yyyy")}</td>
                  <td>
                    <div>
                      <Select
                        onChange={(e) => onSelectChange(e, order._id)}
                        defaultValue={
                          order.driver === null ? "" : order.driver._id
                        }
                      >
                        <option value="">Select Driver</option>
                        {drivers.map((driver, index) => (
                          <option value={driver._id} key={index}>
                            {driver.firstName}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </td>
                  <td>{order.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default AssignRoutePage;
