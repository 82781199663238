import { driverConstants } from ".";
import axios from "../helper/axios";

export const driverSignin = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.DRIVER_SIGNIN_REQUEST });
      let res = await axios.post(`/drivers/signin`, credentials);

      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("type", "driver");
        return dispatch({
          type: driverConstants.DRIVER_SIGNIN_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: driverConstants.DRIVER_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.DRIVER_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    }
  };
};

export const getDrivers = (vendor) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.GET_DRIVERS_BY_VENDOR_REQUEST });
      let res = await axios.get(`/drivers/all?vendor=${vendor}`);

      if (res.status === 200) {
        return dispatch({
          type: driverConstants.GET_DRIVERS_BY_VENDOR_SUCCESS,
          payload: res.data.drivers,
        });
      }
      dispatch({
        type: driverConstants.GET_DRIVERS_BY_VENDOR_FAILURE,
        payload: "Something went wrong fetching drivers",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.GET_DRIVERS_BY_VENDOR_FAILURE,
        payload: "Something went wrong fetching drivers",
      });
    }
  };
};

export const AddDriver = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.ADD_DRIVER_REQUEST });
      let res = await axios.post(`/drivers/create`, value);
      if (res.status === 201) {
        return dispatch({
          type: driverConstants.ADD_DRIVER_SUCCESS,
          payload: res.status,
        });
      }
      dispatch({
        type: driverConstants.ADD_DRIVER_FAILURE,
        payload: "Something went wrong creating driver",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.ADD_DRIVER_FAILURE,
        payload: "Something went wrong creating driver",
      });
    }
  };
};

export const getDriverById = (driverId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.GET_DRIVER_BY_ID_REQUEST });
      let res = await axios.get(`/drivers/get/${driverId}`);
      if (res.status === 200) {
        return dispatch({
          type: driverConstants.GET_DRIVER_BY_ID_SUCCESS,
          payload: res.data.driver,
        });
      }
      dispatch({
        type: driverConstants.GET_DRIVER_BY_ID_FAILURE,
        payload: "Something went wrong fetching driver by Id",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.GET_DRIVER_BY_ID_FAILURE,
        payload: "Something went wrong fetching driver by Id ",
      });
    }
  };
};

export const updateDriver = (driverId, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.UPDATE_DRIVER_REQUEST });
      let res = await axios.put(`/drivers/update/${driverId}`, value);
      console.log(res);
      if (res.status === 200) {
        return dispatch({
          type: driverConstants.UPDATE_DRIVER_SUCCESS,
        });
      }
      dispatch({
        type: driverConstants.UPDATE_DRIVER_FAILURE,
        payload: "Something went wrong updating driver",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.UPDATE_DRIVER_FAILURE,
        payload: "Something went wrong updating driver",
      });
    }
  };
};

export const deleteDriver = (driverId, vendorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: driverConstants.DELETE_DRIVER_REQUEST });
      let res = await axios.delete(`/drivers/delete/${driverId}`);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: driverConstants.DELETE_DRIVER_SUCCESS,
        });
        return dispatch(getDrivers(vendorId));
      }
      dispatch({
        type: driverConstants.DELETE_DRIVER_FAILURE,
        payload: "Something went wrong updating driver",
      });
    } catch (err) {
      dispatch({
        type: driverConstants.DELETE_DRIVER_FAILURE,
        payload: "Something went wrong updating driver",
      });
    }
  };
};
