import "./style.scss";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

// loader
import Loader from "../../assets/loader.gif";

const SubDashboardLayout = (props) => {
  let {
    id,
    title = "Not set yet",
    operationName,
    children,
    to = "../",
    loading,
    table,
  } = props;

  return (
    <section className="dashboard" id={id}>
      <div
        className="loading"
        style={{
          display: `${loading ? "flex" : "none"}`,
        }}
      >
        <img src={Loader} alt="loader" />
      </div>
      <div
        className="dashboardHeader container"
        style={{
          margin: `${table ? "1rem auto" : "0 auto"}`,
        }}
      >
        <Link to={to} className="backwardLink">
          <FaArrowLeft size={25} color="#636363" />
        </Link>
        <h1 className="title">{title}</h1>
        <h3 className="operationName">{operationName}</h3>
      </div>
      <div className="children container">{children}</div>
      <Outlet />
    </section>
  );
};

export default SubDashboardLayout;
