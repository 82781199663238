import React from "react";
import "./style.scss";

// component
import SignaturePad from "react-signature-canvas";
import Button from "../../Button";
// icons
import { FaRedoAlt } from "react-icons/fa";
const Signature = (props) => {
  let { canvas, clear } = props;
  return (
    <div className="signatureFieldContainer">
      <div className="sigantureFieldContainerhead">
        <label>Signature</label>
        <Button
          type="secondary"
          text={<FaRedoAlt size={10} />}
          onClick={clear}
        />
      </div>
      <div className="signatureField">
        <SignaturePad
          ref={canvas}
          canvasProps={{
            width: 500,
            height: 300,
            className: "signCanvas",
          }}
        />
      </div>
    </div>
  );
};

export default Signature;
