import React from "react";
import { FaListUl, FaRoad, FaScroll } from "react-icons/fa";
import { Link } from "react-router-dom";
// layout
import DashboardLayout from "../../components/DashboardLayout";

const DriversPage = () => {
  return (
    <DashboardLayout
      DashboardTitle={"Driver"}
      id="driversDashboard"
      optionsLength={3}
    >
      <Link to="delivery-slip">
        <FaScroll size={25} />
        <span>Delivery Slip</span>
      </Link>
      <Link to="route-assigned">
        <FaRoad size={25} />
        <span>Route Assigned</span>
      </Link>
      <Link to="menu">
        <FaListUl size={25} />
        <span>Menu</span>
      </Link>
    </DashboardLayout>
  );
};

export default DriversPage;
