import { combineReducers } from "redux";
import adminReducer from "./admin.reducer";
import authReducer from "./auth.reducer";
import deliverySlipReducer from "./deliverySlip.reducer";
import driverReducer from "./driver.reducer";
import invoiceReducer from "./invoice.reducer";
import mealReducer from "./meal.reducer";
import menuReducer from "./menu.reducer";
import orderReducer from "./order.reducer";
import parentReducer from "./parent.reducer";
import siteReducer from "./site.reducer";
import studentReducer from "./student.reducer";
import vendorReducer from "./vendor.reducer";

const rootReducer = combineReducers({
  student: studentReducer,
  parent: parentReducer,
  meal: mealReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  driver: driverReducer,
  vendor: vendorReducer,
  site: siteReducer,
  auth: authReducer,
  admin: adminReducer,
  menu: menuReducer,
  deliveryslip: deliverySlipReducer,
});

export default rootReducer;
