import { userConstants } from ".";
import axios from "../helper/axios";

export const getAllUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: userConstants.GET_ALL_USER_REQUEST });
      let res = await axios.get(`/users/all`);

      if (res.status === 200) {
        return dispatch({
          type: userConstants.GET_ALL_USER_SUCCESS,
          payload: res.data.users,
        });
      }
      dispatch({
        type: userConstants.GET_ALL_USER_FAILURE,
        payload: "Error fetching the users",
      });
    } catch (err) {
      dispatch({
        type: userConstants.GET_ALL_USER_FAILURE,
        payload: "Error fetching the users",
      });
    }
  };
};

export const deleteAdmin = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userConstants.GET_ALL_USER_REQUEST });
      let res = await axios.delete(`/users/delete/${id}`);

      if (res.status === 200) {
        return dispatch({
          type: userConstants.DELETE_USER_SUCCESS,
          payload: res.data.userDeleted,
        });
      }
      dispatch({
        type: userConstants.DELETE_USER_FAILURE,
        payload: "Error fetching the users",
      });
    } catch (err) {
      dispatch({
        type: userConstants.DELETE_USER_FAILURE,
        payload: "Error fetching the users",
      });
    }
  };
};
