import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";

// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";

// actions
import { deleteInvoice, getInvoices, getAllSites } from "../../../actions";

// component
import Select from "../../../components/FormComponents/Select";
import Input from "../../../components/FormComponents/Input";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

const InvoicePage = (props) => {
  const dispatch = useDispatch();

  const Vendor = useSelector((state) => state.vendor);
  const loading = useSelector((state) => state.vendor.loading);
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);
  const sites = useSelector((state) => state.admin.sites);
  const invoiceDeleted = useSelector((state) => state.invoice.deletedId);

  let initialQuery = {
    date: "",
    site: "",
  };

  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    dispatch(getAllSites());
  }, []);

  useEffect(() => {
    if (user._id && query.date !== undefined && query.site !== undefined) {
      if (userType === "vendor") {
        dispatch(getInvoices(user._id, query.date, query.site));
      } else {
        dispatch(getInvoices("", query.date, query.site));
      }
    }
  }, [user._id, invoiceDeleted, query]);

  const onDelete = (id) => {
    dispatch(deleteInvoice(id));
  };

  const onChange = (e) => {
    setQuery((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <SubDashboardLayout
      title={`${userType} Dashboard`}
      operationName={`Invoices`}
      id={"InvoicePage"}
      to={"../../"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <td colSpan={8} className="filterField">
                <div className="filterContainer">
                  <div className="Date">
                    <b>Date: </b>
                    <Input
                      type="date"
                      value={query.date}
                      name="date"
                      onChange={onChange}
                    />
                  </div>
                  <div className="site">
                    <b>Site:</b>
                    <Select value={query.site} name="site" onChange={onChange}>
                      <option value="">Select</option>
                      {sites.map((site, index) => (
                        <option value={site._id} key={index}>
                          {site.siteName}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="actionContainer">
                    <Button text="Create Invoice" to="create" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>#</th>
              <th>Order No.</th>
              <th>Order Date</th>
              <th>Site</th>
              <th>Amount</th>
              <th>Paid</th>
              <th>Remaining</th>
              <th>Action</th>
            </tr>
            {Vendor.invoices?.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Invoice yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Vendor.invoices?.map((invoice, index) => (
                <tr key={invoice._id}>
                  <td>{index + 1}</td>
                  <td>{invoice.orderNumber}</td>
                  <td>{invoice.invoiceDate}</td>
                  <td>{invoice.site?.siteName}</td>
                  <td>{invoice.amount} $</td>
                  <td>{invoice.paid} $</td>
                  <td>{invoice.amount - invoice.paid}</td>
                  <td>
                    <Button
                      text="update"
                      type="primary"
                      to={`update/${invoice._id}`}
                    />
                    <Button
                      text="Delete"
                      type="danger"
                      onClick={() => onDelete(invoice._id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default InvoicePage;
