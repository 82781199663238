const getWeek = (d) => {
  // // input date
  let current = new Date(d);

  // return days;
  var week = [];
  // Starting Monday not Sunday
  current.setDate(current.getDate() - current.getDay() + 1);

  for (var i = 0; i < 5; i++) {
    let dateObj = current;
    let date = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear();
    week.push(
      `${month < 10 ? "0" + month : month}/${
        date < 10 ? "0" + date : date
      }/${year}`
    );
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export default getWeek;
