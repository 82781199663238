import "./App.scss";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// actions
import { isUserLoggedIn } from "./actions";

//Layout
import Layout from "./components/layout";

// page
import HomePage from "./pages/HomePage";
import DriversPage from "./pages/DriversPage";
import DeliverySlip from "./components/DeliverySlip";

// Admin page
import AdminDashboard from "./pages/AdminDashboard";
import AllSitePage from "./pages/AdminDashboard/AllSitePage";
import AllVendorPage from "./pages/AdminDashboard/AllVendorPage";
import SiteForm from "./components/SiteForm";

// Site page
import SitePage from "./pages/SitePage";
import SiteMasterListPage from "./pages/SitePage/SiteMasterListPage";
import SiteMeanlCountPage from "./pages/SitePage/SiteMealCountPage";

// Vendor page
import VendorsPage from "./pages/VendorsPage";
import VendorDriverPage from "./pages/VendorsPage/VendorDriverPage";
import OrderRecievePage from "./pages/VendorsPage/OrderRecievePage";
import InvoicePage from "./pages/VendorsPage/InvoicePage";

// components
import StudentFormPage from "./pages/StudentFormPage";
import ParentFormPage from "./pages/ParentFormPage";
import OrderForm from "./components/OrderForm";
import InvoiceForm from "./components/InvoiceForm";
import DriverForm from "./components/DriverForm";
import VendorForm from "./components/VendorForm";
import AutheticationPage from "./pages/AuthenticationPage";
import AssignRoutePage from "./pages/VendorsPage/AssignRoutePage";
import RouteAssignedPage from "./pages/DriversPage/RouteAssignedPage";
import DeliverySlipPage from "./pages/DeliverySlipPage";
import MenuPage from "./pages/MenuPage";
import OrderPage from "./pages/OrderPage";
import CnLabelPage from "./pages/CnLabelPage";
import MonitorReviewForm from "./components/MonitorReviewForm";
import AdminManagementPanel from "./pages/AdminManagmentPanel";

// private routes
import AdminPrivate from "./components/PrivateRoutes/AdminPrivate";
import SitePrivate from "./components/PrivateRoutes/SitePrivate";
import VendorPrivate from "./components/PrivateRoutes/VendorPrivate";
import DriverPrivate from "./components/PrivateRoutes/DriverPrivate";
import EnrollmentFormPage from "./pages/SitePage/EnrollmentFormPage";

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        {/* admin routes */}
        <Route
          path="admin/signin"
          element={<AutheticationPage title="Admin Login" action="login" />}
        />
        <Route
          path="admin/signup"
          element={
            <AutheticationPage title="Admin Singup" action="signup" pin />
          }
        />
        <Route path="admin" element={<AdminPrivate />}>
          <Route path="dashboard">
            <Route index element={<AdminDashboard />} />
            <Route path="sites">
              <Route index element={<AllSitePage />} />
              <Route path="create" element={<SiteForm action="Add" />} />
              <Route path=":siteId" element={<SiteForm action="Update" />} />
            </Route>
            <Route path="vendors">
              <Route index element={<AllVendorPage />} />
              <Route path="create" element={<VendorForm action="Add" />} />
              <Route
                path=":vendorId"
                element={<VendorForm action="Update" />}
              />
            </Route>
            <Route path="invoices">
              <Route index element={<InvoicePage />} />
              <Route path="create" element={<InvoiceForm action="Create" />} />
              <Route
                path="update/:invoiceId"
                element={<InvoiceForm action="Update" />}
              />
            </Route>
            <Route path="orders">
              <Route index element={<OrderPage />} />
              <Route path="create" element={<OrderForm />} />
            </Route>
            <Route path="menu" element={<MenuPage />} />
            <Route path="delivery-slip">
              <Route index element={<DeliverySlipPage />} />
              <Route path="create" element={<DeliverySlip action="Create" />} />
              <Route path=":id" element={<DeliverySlip action="Update" />} />
            </Route>
            {/* <Route path="cn-labels">
              <Route index element={<CnLabelPage />} />
            </Route> */}
            <Route path="monitor-site">
              <Route index element={<MonitorReviewForm />} />
            </Route>
            <Route path="users" element={<AdminManagementPanel />} />
          </Route>
        </Route>
        {/* sites routes */}
        <Route
          path="/sites/signin"
          element={
            <AutheticationPage title="Site Login" action="login" type="site" />
          }
        />
        <Route path="sites" element={<SitePrivate />}>
          <Route index element={<SitePage />} />

          <Route path="create-order" element={<OrderForm />} />
          <Route path="delivery-slip">
            <Route index element={<DeliverySlipPage />} />
            <Route path=":id" element={<DeliverySlip action="Update" />} />
          </Route>
          <Route path="master-list" element={<SiteMasterListPage />} />
          <Route path="meal-count" element={<SiteMeanlCountPage />} />
          <Route path="menu" element={<MenuPage />} />
          <Route path="students">
            <Route
              path="create"
              element={<StudentFormPage action="Create" />}
            />
            <Route
              path="update/:studentId"
              element={<StudentFormPage action="Update" />}
            />
          </Route>
          <Route path="parents">
            <Route path="create" element={<ParentFormPage action="Add" />} />
            <Route
              path="update/:parentId"
              element={<ParentFormPage action="Update" />}
            />
          </Route>
          <Route path="enrollment-form">
            <Route path=":studentId" element={<EnrollmentFormPage />} />
          </Route>
        </Route>

        {/* vendor routes */}
        <Route
          path="/vendors/signin"
          element={
            <AutheticationPage
              title="Vendor Login"
              type="vendor"
              action="login"
            />
          }
        />
        <Route path="vendors" element={<VendorPrivate />}>
          <Route index element={<VendorsPage />} />
          <Route path="orders-received" element={<OrderRecievePage />} />
          <Route path="delivery-slip">
            <Route index element={<DeliverySlipPage />} />
            <Route path="create" element={<DeliverySlip action="Create" />} />
            <Route path=":id" element={<DeliverySlip action="Update" />} />
          </Route>
          <Route path="invoices">
            <Route index element={<InvoicePage />} />
            <Route path="create" element={<InvoiceForm action="Create" />} />
            <Route
              path="update/:invoiceId"
              element={<InvoiceForm action="Update" />}
            />
          </Route>
          <Route path="assign-route" element={<AssignRoutePage />} />
          <Route path="drivers">
            <Route index element={<VendorDriverPage />} />

            <Route path="create" element={<DriverForm action="Create" />} />
            <Route
              path="update/:driverId"
              element={<DriverForm action="Update" />}
            />
          </Route>
          <Route path="cn-labels">
            <Route index element={<CnLabelPage />} />
          </Route>
          <Route path="menu" element={<MenuPage />} />
        </Route>

        {/* driver routes */}
        <Route
          path="/drivers/signin"
          element={
            <AutheticationPage
              title="Driver Signin"
              type="driver"
              action="login"
            />
          }
        />
        <Route path="drivers/*" element={<DriverPrivate />}>
          <Route index element={<DriversPage />} />
          <Route path="delivery-slip">
            <Route index element={<DeliverySlipPage />} />
            <Route path=":id" element={<DeliverySlip action="Update" />} />
          </Route>
          <Route path="route-assigned" element={<RouteAssignedPage />} />
          <Route path="menu" element={<MenuPage />} />
        </Route>
        <Route path="*" component={<div>No match</div>} />
      </Route>
    </Routes>
  );
}

export default App;
