import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

// image
import playGround from "../../assets/playground.png";
//icons
import {
  FaHome,
  FaShoppingCart,
  FaRegAddressCard,
  FaUserNurse,
} from "react-icons/fa";
import { useSelector } from "react-redux";

const HomePage = () => {
  let Navigations = [
    {
      title: "Admin",
      icon: <FaUserNurse size={30} />,
      to: "/admin/signin",
    },
    {
      title: "Sites",
      icon: <FaHome size={30} />,
      to: "/sites/signin",
    },
    {
      title: "Vendors",
      icon: <FaShoppingCart size={30} />,
      to: "/vendors/signin",
    },
    {
      title: "Drivers",
      icon: <FaRegAddressCard size={30} />,
      to: "/drivers/signin",
    },
  ];

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.authenticate && auth.type === "vendor") {
      navigate("/vendors");
    }
    if (auth.authenticate && auth.type === "site") {
      navigate("/sites");
    }
    if (auth.authenticate && auth.type === "admin") {
      navigate("/admin/dashboard");
    }
    if (auth.authenticate && auth.type === "driver") {
      navigate("/drivers");
    }
  }, [auth.authenticate, auth.type]);

  return (
    <section id="home" className="container">
      <div className="graphicsSection">
        <img src={playGround} alt="earth" className="playGroundImage" />
      </div>
      <div className="navLinks">
        {Navigations.map((navigation, index) => (
          <Link to={navigation.to} key={index} className="link">
            {navigation.icon}
            <p className="linkTitle">{navigation.title}</p>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default HomePage;
