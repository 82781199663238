import React from "react";
import "./style.scss";
// table
import MaterialTable from "material-table";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
});

const Table = (props) => {
  let { columns, data, options, actions, title, detailpanel, editable } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        actions={actions}
        options={{ ...options, actionsColumnIndex: -1 }}
        columns={columns}
        data={data}
        title={title}
        detailPanel={detailpanel}
        editable={editable}
      />
    </MuiThemeProvider>
  );
};

export default Table;
