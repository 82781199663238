import "./style.scss";
import React, { useState } from "react";
import { useEffect } from "react";

const Input = (props) => {
  let { type = "text", placeholder, label, required, name, value } = props;
  const [error, setError] = useState("");

  const onBlur = () => {
    if (required && value === "") {
      setError(`${label ? label : placeholder} required!`);
    }
  };

  useEffect(() => {
    if (value !== "") {
      setError("");
    }
  }, [value]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      {label && (
        <label
          style={{
            fontSize: ".9rem",
            padding: ".3rem .2rem",
          }}
        >
          {label}
        </label>
      )}
      <input
        className="inputField"
        type={type}
        placeholder={placeholder}
        required={required}
        name={name}
        ref={props.compref}
        {...props}
        style={{
          backgroundColor: `${type === "date" ? "#eee" : "#fff"}`,
        }}
        value={value}
        onBlur={onBlur}
        min={1}
      />
      {error && (
        <span
          style={{
            fontSize: ".9rem",
            padding: ".3rem .2rem",
            color: "#dd3f3f",
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
