import axios from "../helper/axios";
import { mealConstants } from "./constants";
import { getAllStudentsWithMealBySite } from "./student.action";

export const AddMeal = (value) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: mealConstants.ADD_MEAL_REQUEST,
      });
      let res = await axios.post("/meal/add", value);
      if (res.status === 201 || res.status === 200) {
        dispatch({
          type: mealConstants.ADD_MEAL_SUCCESS,
          payload: res.data.meal,
        });
        return getAllStudentsWithMealBySite(value.site);
      }
      dispatch({
        type: mealConstants.ADD_MEAL_FAILURE,
      });
    } catch (err) {
      dispatch({
        type: mealConstants.ADD_MEAL_FAILURE,
      });
    }
  };
};

export const getMealCount = (site, weeks) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: mealConstants.GET_MEAL_COUNT_REQUEST,
      });
      let res = await axios.post(`/meal/sum/${site}`, { weeks });
      if (res.status === 201 || res.status === 200) {
        return dispatch({
          type: mealConstants.GET_MEAL_COUNT_SUCCESS,
          payload: {
            totalMealSum: res.data.totalMealSum,
            weekMealSum: res.data.weekMealSum,
          },
        });
      }
      dispatch({
        type: mealConstants.GET_MEAL_COUNT_FAILURE,
      });
    } catch (err) {
      dispatch({
        type: mealConstants.GET_MEAL_COUNT_FAILURE,
      });
    }
  };
};
