import "./style.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

// component
import Input from "../FormComponents/Input";
import Select from "../FormComponents/Select";
import Button from "../Button";
import Loader from "../Loader";

//actions
import {
  getStudentsById,
  AddStudents,
  UpdateStudent,
} from "../../actions/student.action";
import Notification from "../Notification";

const StudentForm = (props) => {
  //props
  let { action, studentId, student } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.student.loading);
  const studentStatus = useSelector((state) => state.student.status);
  const studentMsg = useSelector((state) => state.student.message);

  const initialState = {
    firstName: "",
    lastName: "",
    birthDate: "",
    enrollmentDate: "",
    signingDate: "",
    incomeCategory: "",
    racialCategory: "",
    ethnicCategory: "",
  };

  // states
  const [formValue, setFormValues] = useState(initialState);
  const [show, setShow] = useState(false);

  // parse student info
  useEffect(() => {
    if (action === "Update") {
      return dispatch(getStudentsById(studentId));
    }
  }, [action, studentId, dispatch]);

  useEffect(() => {
    if (action === "Update") {
      setFormValues(student);
    }
  }, [student]);

  // formValues destructured
  let {
    firstName,
    lastName,
    birthDate,
    enrollmentDate,
    signingDate,
    incomeCategory,
    racialCategory,
    ethnicCategory,
  } = formValue;
  // when formValues are changes
  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    //error for not filling required fields
    if (!firstName || !birthDate) {
      return alert("Please fill firstName & birthDate");
    }

    action === "Create"
      ? dispatch(
          AddStudents({
            ...formValue,
            site: user._id,
          })
        )
      : dispatch(UpdateStudent(formValue, studentId));

    if (action === "Create" && !loading) {
      setFormValues(initialState);
    }
  };

  useEffect(() => {
    setShow(false);
    if (studentStatus === 200 || studentStatus === 201) {
      setShow(true);
    }
  }, [studentStatus]);

  // today date
  let today = format(new Date(), "yyyy-MM-dd");

  // reset the fields if the action is create
  useEffect(() => {
    if (action === "Create") {
      setFormValues(initialState);
      setShow(false);
    }
  }, [action]);

  return (
    <div className="formContainer">
      <Notification show={show} type={"success"} message={studentMsg} />
      {loading && <Loader />}
      {!loading && (
        <form className="createStudentForm">
          <Input
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={onChange}
            required
          />
          <Input
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={onChange}
          />
          <Input
            label="Birth Date (d/m/Y)"
            type="date"
            name="birthDate"
            max={today}
            value={birthDate}
            onChange={onChange}
            required
          />
          <Input
            label="Enrollment Date"
            type="date"
            max={today}
            name="enrollmentDate"
            value={enrollmentDate}
            onChange={onChange}
          />
          <Input
            label="Date Income Form signed by Parent OR Signed & Certified by  Sponsor"
            type="date"
            max={today}
            name="signingDate"
            value={signingDate}
            onChange={onChange}
          />
          <Select
            label="Income Categories"
            name="incomeCategory"
            value={incomeCategory}
            onChange={onChange}
          >
            <option value="">Select</option>
            <option value="FR">FR</option>
            <option value="RD">RD</option>
            <option value="PD">PD</option>
          </Select>
          <Select
            label="Racial Categories"
            name="racialCategory"
            value={racialCategory}
            onChange={onChange}
          >
            <option value="">Select</option>
            <option value="AI">American Indian</option>
            <option value="AN">Alaska Native</option>
            <option value="AA">African American</option>
            <option value="A">Asian</option>
            <option value="B">Black</option>
            <option value="NH">Native Hawalian</option>
            <option value="PI">Pacific Islander</option>
            <option value="W">White</option>
          </Select>
          <Select
            label="Ethnic Category"
            name="ethnicCategory"
            value={ethnicCategory}
            onChange={onChange}
          >
            <option value="">Select</option>
            <option value="HL">Hispanic or Lation</option>
            <option value="NHL">Non-Hispanice or Lation</option>
          </Select>
          <Button
            text={
              loading
                ? `${action.slice(0, -1)}ing Student`
                : `${action} Student`
            }
            type="primary"
            onClick={onSubmit}
          />
        </form>
      )}
    </div>
  );
};

export default StudentForm;
