import { orderConstants } from "../actions/constants";

const initialState = {
  orders: [],
  loading: false,
  error: null,
  message: "",
  orderUpdated: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case orderConstants.CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case orderConstants.CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case orderConstants.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderUpdated: payload,
      };
    case orderConstants.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case orderConstants.GET_ORDERS_BY_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.GET_ORDERS_BY_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
      };
    case orderConstants.GET_ORDERS_BY_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case orderConstants.GET_ORDERS_BY_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.GET_ORDERS_BY_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
      };
    case orderConstants.GET_ORDERS_BY_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case orderConstants.GET_ORDERS_BY_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.GET_ORDERS_BY_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
      };
    case orderConstants.GET_ORDERS_BY_SITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case orderConstants.GET_ALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
      };
    case orderConstants.GET_ALL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    default:
      return { ...state };
  }
};
