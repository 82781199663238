import "./style.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// component
import Input from "../FormComponents/Input";
import Select from "../FormComponents/Select";
import Button from "../Button";

//actions
import {
  AddInvoice,
  getAllSites,
  getAllVendors,
  getInvoiceById,
  updateInvoice,
} from "../../actions";
import SubDashboardLayout from "../SubDashboardLayout";
import Notification from "../Notification";

const InvoiceForm = (props) => {
  //props
  let { action } = props;
  let { invoiceId } = useParams();
  // states
  const Invoice = useSelector((state) => state.invoice);
  const sites = useSelector((state) => state.admin.sites);
  const vendors = useSelector((state) => state.admin.vendors);
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);
  const invoiceStatus = useSelector((state) => state.invoice.status);
  const invoiceMsg = useSelector((state) => state.invoice.message);

  // init states

  const initialState = {
    items: [
      {
        itemName: "",
        itemQuantity: 0,
        itemPrice: 0,
      },
    ],
    formValue: {
      vendor: "",
      site: "",
      orderNumber: 0,
      invoiceDate: "",
    },
  };

  const [items, setItems] = useState(initialState.items);
  const [formValue, setFormValues] = useState(initialState.formValue);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userType === "admin") {
      dispatch(getAllVendors());
    }
  }, [userType]);

  useEffect(() => {
    dispatch(getAllSites());
  }, []);
  useEffect(() => {
    if (action === "Update") {
      return dispatch(getInvoiceById(invoiceId));
    }
  }, [action]);

  useEffect(() => {
    if (action === "Update") {
      setFormValues({
        site: Invoice.invoice.site,
        orderNumber: Invoice.invoice.orderNumber,
        invoiceDate: Invoice.invoice.invoiceDate,
      });
      setItems(Invoice.invoice.invoiceItems);
    }
  }, [Invoice.invoice]);

  useEffect(() => {
    setLoading(Invoice.loading);
  }, [Invoice.loading]);

  useEffect(() => {
    if (
      Invoice.error !== null ||
      Invoice.status === 201 ||
      Invoice.status === 200
    ) {
      setShow(true);
    }
  }, [Invoice.status, Invoice.error]);

  // formValues destructured
  let { site, orderNumber, invoiceDate, vendor } = formValue;
  // when formValues are changes
  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onItemChange = (e, index) => {
    let newArr = [...items];
    newArr[index] = {
      ...newArr[index],
      [e.target.name]: e.target.value,
    };
    setItems(newArr);
  };

  const addItem = () => {
    setItems((prev) => [
      ...prev,
      {
        itemName: "",
        itemQuantity: 0,
        itemPrice: 0,
      },
    ]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (action === "Create" && userType === "vendor") {
      formValue.vendor = user._id;
    }

    if (site === "") {
      return alert("Pleaser fillup required fields");
    }
    action === "Create"
      ? dispatch(
          AddInvoice({
            ...formValue,
            invoiceItems: [...items],
          })
        )
      : dispatch(
          updateInvoice(invoiceId, { ...formValue, invoiceItems: [...items] })
        );
    if (action === "Create" && !loading) {
      setFormValues(initialState.formValue);
      setItems(initialState.items);
    }
  };

  useEffect(() => {
    setShow(false);
    if (invoiceStatus === 200 || invoiceStatus === 201) {
      setShow(true);
    }
  }, [invoiceStatus]);

  // reset the fields if the action is create
  useEffect(() => {
    if (action === "Create") {
      setShow(false);
      setFormValues(initialState.formValue);
      setItems(initialState.items);
    }
  }, [action]);

  return (
    <SubDashboardLayout
      title={"Vendor"}
      operationName={`${action === "Update" ? "Update" : "Create"} Invoice`}
      id={"invoicePage"}
      to={"../"}
      loading={loading}
    >
      <Notification show={show} type={"success"} message={invoiceMsg} />
      <div className="formContainer">
        <form className="createInvoiceForm">
          {userType === "admin" && (
            <Select
              label="Vendor"
              name="vendor"
              value={vendor}
              onChange={onChange}
              required
            >
              <option value="">Select Vendor</option>
              {vendors?.map((vendor, index) => (
                <option value={vendor._id} key={index}>
                  {vendor.vendorName}
                </option>
              ))}
            </Select>
          )}
          <Select
            label="Site"
            name="site"
            value={site}
            onChange={onChange}
            required
          >
            <option value="">Select Site</option>
            {sites.map((site, index) => (
              <option value={site._id} key={index}>
                {site.siteName}
              </option>
            ))}
          </Select>
          <Input
            label="Order Number"
            name="orderNumber"
            value={orderNumber}
            onChange={onChange}
            type="number"
          />
          <Input
            label="Invoice Month"
            type="date"
            name="invoiceDate"
            value={invoiceDate}
            onChange={onChange}
            required
          />
          <div className="itemContainer">
            <h2 className="title">Invoice Items</h2>
            {items.map((item, index) => (
              <div key={index} className="item">
                <Input
                  placeholder="Item name"
                  value={item.itemName}
                  name="itemName"
                  required
                  onChange={(e) => onItemChange(e, index)}
                />
                <Input
                  placeholder="Quantity"
                  value={item.itemQuantity}
                  name="itemQuantity"
                  required
                  type="number"
                  onChange={(e) => onItemChange(e, index)}
                />
                <Input
                  placeholder="Price"
                  name="itemPrice"
                  value={item.itemPrice}
                  required
                  type="number"
                  onChange={(e) => onItemChange(e, index)}
                />
              </div>
            ))}
            <Button text="Add Item" type="secondary" onClick={addItem} />
          </div>
          <Button
            text={
              loading
                ? `${action === "Update" ? "Updating" : "Adding"} invoice`
                : `${action} invoice`
            }
            type="primary"
            onClick={onSubmit}
          />
        </form>
      </div>
    </SubDashboardLayout>
  );
};

export default InvoiceForm;
