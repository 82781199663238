import "./style.scss";
import React from "react";

// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";

// component
import MealCountList from "../../../components/MealCountList";
import { useSelector } from "react-redux";

const SiteMealCountPage = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <SubDashboardLayout
      title={user.siteName}
      operationName="Meal Count"
      id="siteMealCount"
    >
      <MealCountList site={user.siteName} />
    </SubDashboardLayout>
  );
};

export default SiteMealCountPage;
