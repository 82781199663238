import "./style.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";

// actions
import { getMenu, updateMenu } from "../../actions";

// comps
import Notification from "../Notification";

// table comps
import Lunch from "./Lunch";
import PmSnack from "./PmSnack";
import Supper from "./Supper";
import EveningSnack from "./EveningSnack";
import AmSnack from "./AmSnack";
import Breakfast from "./Breakfast";
import TableHeader from "./TableHeader";

const MenuList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMenu());
  }, []);

  const Menu = useSelector((state) => state.menu);
  const menu = useSelector((state) => state.menu.menu);
  const authType = useSelector((state) => state.auth.type);
  const [edit, setEdit] = useState(false);

  // meal state
  // breakfast
  const [breakfast, setBreakfast] = useState([
    {
      items: "",
      measure: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      measure: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      measure: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      measure: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // amsnack
  const [amsnack, setAmSnack] = useState([
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // lunch
  const [lunch, setLunch] = useState([
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // pmsnack
  const [pmsnack, setPmSnack] = useState([
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // supper
  const [supper, setSupper] = useState([
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // eveningsnack
  const [eveningsnack, setEveningSnack] = useState([
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
    {
      items: "",
      quantity1: "",
      quantity2: "",
      quantity3: "",
      mon: "",
      tues: "",
      wed: "",
      thurs: "",
      fri: "",
    },
  ]);
  // notificaiton state
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (Object.keys(menu).length > 0) {
      setBreakfast(menu.breakfast);
      setAmSnack(menu.amsnack);
      setLunch(menu.lunch);
      setPmSnack(menu.pmsnack);
      setSupper(menu.supper);
      setEveningSnack(menu.eveningsnack);
    }
  }, [menu]);

  useEffect(() => {
    if (authType === "admin") {
      if (
        (Menu.message !== "" && !Menu.loading) ||
        (Menu.error !== null && !Menu.loading)
      ) {
        setShow(true);
      }
    }
  }, [Menu.message, Menu.error]);

  const onUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateMenu(menu._id, {
        breakfast,
        amsnack,
        lunch,
        pmsnack,
        supper,
        eveningsnack,
      })
    );
    setEdit(false);
  };
  // state end
  return (
    <div className="menuContainer">
      <Notification
        show={show}
        type={Menu.error === null ? "success" : "error"}
        message={
          Menu.error === null ? "Updated successfully" : "Something went wrong"
        }
      />
      <div className="menu">
        <h3 className="formTitle text-center">
          Weekly Menu for Children (5-Day)
        </h3>
        <div
          style={{
            paddingBottom: ".5rem",
            overflowX: "auto",
          }}
        >
          <table className="table">
            <tbody className="tableBody">
              <TableHeader />
              <Breakfast
                edit={edit}
                setBreakfast={setBreakfast}
                breakfast={breakfast}
              />
              <TableHeader />
              <AmSnack edit={edit} setAmSnack={setAmSnack} amsnack={amsnack} />
              <TableHeader />
              <Lunch edit={edit} setLunch={setLunch} lunch={lunch} />
              <TableHeader />
              <PmSnack edit={edit} setPmSnack={setPmSnack} pmsnack={pmsnack} />
              <TableHeader />
              <Supper edit={edit} setSupper={setSupper} supper={supper} />
              <TableHeader />
              <EveningSnack
                edit={edit}
                setEveningSnack={setEveningSnack}
                eveningsnack={eveningsnack}
              />
            </tbody>
          </table>
        </div>
        {authType === "admin" && (
          <div className="actions">
            {edit ? (
              <Button text="Update" type="primary" onClick={onUpdate} />
            ) : (
              <Button
                text="Edit Menu"
                onClick={() => setEdit(true)}
                type="secondary"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuList;
