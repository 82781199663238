import React from "react";
import { Link } from "react-router-dom";
import {
  FaCrown,
  FaHotdog,
  FaListUl,
  FaRegClipboard,
  FaRegEdit,
  FaScroll,
} from "react-icons/fa";

// layout
import DashboardLayout from "../../components/DashboardLayout";
import { useSelector } from "react-redux";

const SitePage = (props) => {
  const auth = useSelector((state) => state.auth);

  return (
    <DashboardLayout DashboardTitle={auth.user?.siteName} id="siteDashboard">
      <Link to="create-order">
        <FaRegEdit size={25} />
        <span>Create Order</span>
      </Link>
      <Link
        to="claims-report"
        style={{
          pointerEvents: "none",
          color: "#d2d2d2",
        }}
      >
        <FaRegClipboard size={25} />
        <span>Claims Report</span>
      </Link>
      <Link to="delivery-slip">
        <FaScroll size={25} />
        <span>Delivery Slip</span>
      </Link>
      <Link to="master-list">
        <FaCrown size={25} />
        <span>Master List</span>
      </Link>
      <Link to="meal-count">
        <FaHotdog size={25} />
        <span>Meal Count</span>
      </Link>
      <Link to="menu">
        <FaListUl size={25} />
        <span>Menu</span>
      </Link>
    </DashboardLayout>
  );
};

export default SitePage;
