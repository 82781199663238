import { invoiceConstants } from ".";
import axios from "../helper/axios";

export const getInvoices = (vendor, date, site) => {
  return async (dispatch) => {
    try {
      dispatch({ type: invoiceConstants.GET_INVOICE_BY_VENDOR_REQUEST });
      let res = await axios.get(
        `/invoices/all?vendor=${vendor}&date=${date}&site=${site}`
      );
      if (res.status === 200) {
        return dispatch({
          type: invoiceConstants.GET_INVOICE_BY_VENDOR_SUCCESS,
          payload: res.data.invoices,
        });
      }
      dispatch({
        type: invoiceConstants.GET_INVOICE_BY_VENDOR_FAILURE,
        payload: "Something went wrong getting invoice",
      });
    } catch (err) {
      dispatch({
        type: invoiceConstants.GET_INVOICE_BY_VENDOR_FAILURE,
        payload: "Something went wrong getting invoice",
      });
    }
  };
};

export const AddInvoice = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: invoiceConstants.ADD_INVOICE_REQUEST });
      let res = await axios.post(`/invoices/create`, value);
      if (res.status === 201) {
        return dispatch({
          type: invoiceConstants.ADD_INVOICE_SUCCESS,
          payload: res.status,
        });
      }
      dispatch({
        type: invoiceConstants.ADD_INVOICE_FAILURE,
        payload: "Something went wrong creating invoice",
      });
    } catch (err) {
      dispatch({
        type: invoiceConstants.ADD_INVOICE_FAILURE,
        payload: "Something went wrong creating invoice",
      });
    }
  };
};

export const getInvoiceById = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: invoiceConstants.GET_INVOICE_BY_ID_REQUEST });
      let res = await axios.get(`/invoices/get/${invoiceId}`);
      if (res.status === 200) {
        return dispatch({
          type: invoiceConstants.GET_INVOICE_BY_ID_SUCCESS,
          payload: res.data.invoice,
        });
      }
      dispatch({
        type: invoiceConstants.GET_INVOICE_BY_ID_FAILURE,
        payload: "Something went wrong fetching invoice by Id",
      });
    } catch (err) {
      dispatch({
        type: invoiceConstants.GET_INVOICE_BY_ID_FAILURE,
        payload: "Something went wrong fetching invoice by Id ",
      });
    }
  };
};

export const updateInvoice = (invoiceId, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: invoiceConstants.UPDATE_INVOICE_REQUEST });
      let res = await axios.put(`/invoices/update/${invoiceId}`, value);
      console.log(res);
      if (res.status === 200) {
        return dispatch({
          type: invoiceConstants.UPDATE_INVOICE_SUCCESS,
        });
      }
      dispatch({
        type: invoiceConstants.UPDATE_INVOICE_FAILURE,
        payload: "Something went wrong updating invoice",
      });
    } catch (err) {
      dispatch({
        type: invoiceConstants.UPDATE_INVOICE_FAILURE,
        payload: "Something went wrong updating invoice",
      });
    }
  };
};

export const deleteInvoice = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: invoiceConstants.DELETE_INVOICE_REQUEST });

      let res = await axios.delete(`/invoices/delete/${invoiceId}`);
      if (res.status === 200) {
        return dispatch({
          type: invoiceConstants.DELETE_INVOICE_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: invoiceConstants.DELETE_INVOICE_FAILURE,
        payload: "Something went wrong updating invoice",
      });
    } catch (err) {
      dispatch({
        type: invoiceConstants.DELETE_INVOICE_FAILURE,
        payload: "Something went wrong updating invoice",
      });
    }
  };
};
