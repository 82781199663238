import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import SubDashboardLayout from "../../components/SubDashboardLayout";
import Button from "../../components/Button";
import { deleteDeliverySlip, getDeliverySlips } from "../../actions";
import Loader from "../../components/Loader";

const DeliverySlipPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.deliveryslip.loading);
  const deliveryslips = useSelector(
    (state) => state.deliveryslip.deliverySlips
  );
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);
  const deleted = useSelector((state) => state.deliveryslip.deletedId);

  useEffect(() => {
    if (user._id && userType === "admin") {
      dispatch(getDeliverySlips());
    }

    if (user._id && userType === "vendor") {
      dispatch(getDeliverySlips(user._id, null, null));
    }

    if (user._id && userType === "driver") {
      dispatch(getDeliverySlips(null, user._id, null));
    }
    if (user._id && userType === "site") {
      dispatch(getDeliverySlips(null, null, user._id));
    }
  }, [user._id, userType, deleted]);

  const deliveryItems = (itemArr) => {
    let items = "";
    itemArr?.map((item, index) => {
      if (index !== 0 && item.items !== "") {
        items += ",";
      }
      if (item.items !== "") {
        items += item.items;
      }
    });
    return items;
  };

  const onDelete = (id) => {
    dispatch(deleteDeliverySlip(id));
  };
  return (
    <SubDashboardLayout
      title={userType}
      operationName={"Delivery slips"}
      id={"dailySlipPage"}
      to="../../"
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              {userType === "admin" || userType === "vendor" ? (
                <td colSpan={9} className="header">
                  <div className="headerContainer">
                    <div className="actionContainer">
                      <Button
                        text="Create Delivery Slip"
                        to="create"
                        type="primary"
                      />
                    </div>
                  </div>
                </td>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Site</th>
              <th>Meals Type</th>
              <th>Driver</th>
              <th>Ordered</th>
              <th>Delivered</th>
              <th>Delivery Items</th>
              <th>Actions</th>
            </tr>
            {deliveryslips?.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No slips yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              deliveryslips?.map((delivery, index) => (
                <tr key={delivery._id}>
                  <td>{index + 1}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {format(new Date(delivery.deliveryDate), "dd-MM-yyyy")}
                  </td>
                  <td>{delivery.site?.siteName}</td>
                  <td> {delivery.mealType}</td>
                  <td>
                    {delivery.vendorRepresentative.firstName}{" "}
                    {delivery.vendorRepresentative.lastName}
                  </td>
                  <td>{delivery.mealsOrdered}</td>
                  <td>{delivery.mealsDelivered}</td>
                  <td>
                    <p>{deliveryItems(delivery.foodItems)}</p>
                  </td>
                  <td>
                    <Button text="Update" type="secondary" to={delivery._id} />
                    {userType === "admin" || userType === "vendor" ? (
                      <Button
                        text="Delete"
                        type="danger"
                        onClick={() => onDelete(delivery._id)}
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default DeliverySlipPage;
