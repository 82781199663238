import React from "react";
import "./style.scss";
import SubDashboardLayout from "../../../components/SubDashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteVendor, getAllVendors, signout } from "../../../actions";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

// components
import Loader from "../../../components/Loader";

const AllVendorPage = () => {
  const Admin = useSelector((state) => state.admin);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllVendors());
  }, []);

  const onDelete = (id) => {
    dispatch(deleteVendor(id));
  };

  const redirectToLogin = () => {
    dispatch(signout());
    return navigate("/vendors/signin");
  };

  return (
    <SubDashboardLayout
      title={"Admin"}
      id={"adminVendorPanel"}
      operationName="vendors"
      to={"../../"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr className="tableHeader">
              <td colSpan={4}>
                <div className="headerContainer">
                  <Button text="Create Vendor" to="create" />
                </div>
              </td>
            </tr>
            <tr className="driverTableHead">
              <th>#</th>
              <th>Vendor Name</th>
              <th>username</th>
              <th>actions</th>
            </tr>
            {Admin.vendors.length < 1 && !loading ? (
              <tr>
                <td
                  colSpan={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  No Sites
                </td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Admin.vendors.map((vendor, index) => (
                <tr key={vendor._id} className="driverData">
                  <td>{index + 1}</td>
                  <td>{vendor.vendorName}</td>
                  <td>{vendor.userName}</td>
                  <td>
                    <div>
                      <Button text="update" type="primary" to={vendor._id} />
                      <Button
                        text="Delete"
                        type="danger"
                        onClick={() => onDelete(vendor._id)}
                      />
                      <Button
                        text="Login"
                        type="secondary"
                        onClick={redirectToLogin}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default AllVendorPage;
