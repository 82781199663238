import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

import {
  CreateDeliverySlip,
  getAllSites,
  getAllVendors,
  getDeliverySlipById,
  getDrivers,
  getMenu,
  updateDeliverySlip,
} from "../../actions";

import Input from "../../components/FormComponents/Input";
import Select from "../../components/FormComponents/Select";
import TextArea from "../../components/FormComponents/TextArea";
import Button from "../Button";

// icon
import { FaCheck, FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import SubDashboardLayout from "../SubDashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import Notification from "../Notification";

const DeliverySlip = (props) => {
  let { action } = props;
  let { id } = useParams();
  let dispatch = useDispatch();

  // global states
  const sites = useSelector((state) => state.admin.sites);
  const vendors = useSelector((state) => state.admin.vendors);
  const drivers = useSelector((state) => state.vendor.drivers);
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);
  const deliverySlip = useSelector((state) => state.deliveryslip.deliverySlip);
  const foodItem = useSelector((state) => state.deliveryslip.foodItems);
  const loading = useSelector((state) => state.deliveryslip.loading);
  const menu = useSelector((state) => state.menu.menu);
  const deliverySlipStatus = useSelector((state) => state.deliveryslip.status);
  const deliverySlipMsg = useSelector((state) => state.deliveryslip.message);

  // initstate
  const initialState = {
    tableValues: {
      vendor: "",
      site: "",
      deliveryDate: "",
      mealType: "",
      mealsOrdered: 0,
      mealsDelivered: 0,
      milkDelivery: {
        fatfree: {
          fourOzQuantity: 0,
          eightOzQuantity: 0,
          halfGallonQuantity: 0,
          gallonQuantity: 0,
        },
        lowfat: {
          fourOzQuantity: 0,
          eightOzQuantity: 0,
          halfGallonQuantity: 0,
          gallonQuantity: 0,
        },
        wholeMilk: {
          fourOzQuantity: 0,
          eightOzQuantity: 0,
          halfGallonQuantity: 0,
          gallonQuantity: 0,
        },
      },
      vendorRepresentative: "",
      deliveryTime: "",
      problems: "",
    },
    foodItems: [
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
      {
        items: "",
        quantity: 0,
        deliveryType: 2,
        state1: {
          temp: "",
          time: "",
        },
        state2: {
          temp: "",
          time: "",
        },
        state3: {
          temp: "",
          time: "",
        },
      },
    ],
  };

  // component states
  const [tableValues, setTableValues] = useState(initialState.tableValues);
  const [foodItems, setFoodItems] = useState(initialState.foodItems);

  // if action is update it gets the delivery slip data
  // also gets the menu
  useEffect(() => {
    if (action === "Update") {
      dispatch(getDeliverySlipById(id));
    }
    dispatch(getMenu());
  }, [action, id]);

  // updates state when the action is Update
  useEffect(() => {
    if (action === "Update") {
      setTableValues(deliverySlip);
      setFoodItems(foodItem);
    }
  }, [deliverySlip]);

  // gets all sites
  useEffect(() => {
    dispatch(getAllSites());
  }, []);
  // gets all vendors if userType admin
  useEffect(() => {
    if (userType === "admin" && action === "Create") {
      dispatch(getAllVendors());
    }
  }, [userType]);

  useEffect(() => {
    // if userId exista and userType is vendor then it will use the auth user id for
    //fetching the drivers
    if (user._id && userType === "vendor") {
      dispatch(getDrivers(user._id));
    }

    // else it will use the table populated data when the action is update
    if (
      user._id &&
      userType !== "vendor" &&
      action === "Update" &&
      tableValues.vendor._id
    ) {
      dispatch(getDrivers(tableValues.vendor._id));
    }

    // else it will use the table data when the action is create and when the userType isnot vendor
    if (
      userType !== "vendor" &&
      tableValues.vendor !== "" &&
      action === "Create"
    ) {
      dispatch(getDrivers(tableValues.vendor));
    }
  }, [user._id, tableValues.vendor]);

  useEffect(() => {
    let mealType = tableValues.mealType.toLowerCase().split(" ").join("");
    if (mealType === "") {
      setFoodItems([
        {
          ...foodItem[0],
          items: "",
          quantity: 0,
        },
        {
          ...foodItem[1],
          items: "",
          quantity: 0,
        },
        {
          ...foodItem[2],
          items: "",
          quantity: 0,
        },
        {
          ...foodItem[3],
          items: "",
          quantity: 0,
        },
        {
          ...foodItem[4],
          items: "",
          quantity: 0,
        },
        {
          ...foodItem[5],
          items: "",
          quantity: 0,
        },
      ]);
    }
    if (menu?.[mealType]?.length > 0) {
      setFoodItems([
        {
          ...foodItem[0],
          items: menu[mealType][0]?.items,
        },
        {
          ...foodItem[1],
          items: menu[mealType][1]?.items,
        },
        {
          ...foodItem[2],
          items: menu[mealType][2]?.items,
        },
        {
          ...foodItem[3],
          items: menu[mealType][3]?.items,
        },
        {
          ...foodItem[4],
          items: menu[mealType][4]?.items,
        },
        {
          ...foodItem[5],
          items: menu[mealType][5]?.items,
        },
      ]);
    }
  }, [menu, tableValues.mealType]);

  const [show, setShow] = useState(false);

  // functions

  // when foodItem changes
  const foodItemChange = (e, index, stateName) => {
    let newArr = [...foodItems];
    if (stateName !== "") {
      newArr[index][stateName][e.target.name] = e.target.value;
    } else {
      newArr[index][e.target.name] = e.target.value;
    }
    setFoodItems(newArr);
  };
  // to check the foodItem delivered fields
  const checkFoodItem = (e, value, index) => {
    let newArr = [...foodItems];
    let name = e.target.name.slice(0, e.target.name.length - 1);
    newArr[index][name] = value;
    setFoodItems(newArr);
  };

  // when tableValue need changes

  const onChange = (e, step1, subname) => {
    let newObj = { ...tableValues };

    if (step1) {
      if (e.target.type === "number") {
        if (subname) {
          newObj[step1][e.target.name][subname] = Number(e.target.value);
        } else {
          newObj[step1][e.target.name] = Number(e.target.value);
        }
      } else {
        if (subname) {
          newObj[step1][e.target.name][subname] = e.target.value;
        } else {
          newObj[step1][e.target.name] = e.target.value;
        }
      }
    } else {
      if (e.target.type === "number") {
        newObj[e.target.name] = Number(e.target.value);
      } else {
        newObj[e.target.name] = e.target.value;
      }
    }
    setTableValues(newObj);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      tableValues.site === "" ||
      tableValues.deliveryDate === "" ||
      tableValues.vendorRepresentative === "" ||
      tableValues.mealType === ""
    ) {
      return alert(
        "Please fillup required fields: Date of delivery, Name of site Food delivered To, Vendor Represntitive  "
      );
    }
    if (action === "Create" && userType === "vendor") {
      tableValues.vendor = user._id;
    }
    action === "Create"
      ? dispatch(CreateDeliverySlip({ ...tableValues, foodItems }))
      : dispatch(updateDeliverySlip(id, { ...tableValues, foodItems }));

    if (action === "Create" && !loading) {
      setTableValues(initialState.tableValues);
      setFoodItems(initialState.foodItems);
    }
  };

  useEffect(() => {
    setShow(false);
    if (deliverySlipStatus === 200 || deliverySlipStatus === 201) {
      setShow(true);
    }
  }, [deliverySlipStatus]);

  // reset the fields if the action is create
  useEffect(() => {
    if (action === "Create") {
      setTableValues(initialState.tableValues);
      setFoodItems(initialState.foodItems);
      setShow(false);
    }
  }, [action]);

  return (
    <SubDashboardLayout
      title={userType}
      operationName={`${action} Delivery Slip`}
      id="deliverySlip"
      loading={loading}
    >
      <Notification show={show} message={deliverySlipMsg} type="success" />
      <div className="deliverySlipContainer">
        <div
          className="deliverySlip"
          style={{
            overflowX: "auto",
          }}
        >
          <table className="tableBody">
            <tbody>
              <tr className="title">
                <th colSpan={12}>
                  <h2 style={{ textAlign: "center" }}>DAILY DELIVERY SLIP</h2>
                </th>
              </tr>
              <tr className="vendorDetails">
                <th colSpan={4}>Vendor or Central Kitchen Name:</th>
                {userType === "admin" && action === "Create" ? (
                  <td colSpan={3}>
                    <Select
                      value={tableValues.vendor}
                      onChange={onChange}
                      name="vendor"
                      placeholder="vendor name"
                      required={true}
                    >
                      <option value="">Select vendor</option>
                      {vendors?.map((vendor, index) => (
                        <option value={vendor._id} key={index}>
                          {vendor.vendorName}
                        </option>
                      ))}
                    </Select>
                  </td>
                ) : (
                  <td colSpan={3}>
                    {userType === "vendor" && action === "Create" ? (
                      <p> {user.vendorName} </p>
                    ) : (
                      <p> {tableValues.vendor.vendorName}</p>
                    )}
                  </td>
                )}
                <th colSpan={3}>Date of delivery:</th>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="date"
                      placeholder="delivery date"
                      value={tableValues.deliveryDate}
                      onChange={(e) =>
                        setTableValues((prev) => ({
                          ...prev,
                          deliveryDate: e.target.value,
                        }))
                      }
                      required={true}
                    />
                  ) : (
                    tableValues.deliveryDate
                  )}
                </td>
              </tr>
              <tr className="siteName">
                <th colSpan={3}>Name of Site Food Deliverd To:</th>
                <td colSpan={9}>
                  {userType === "vendor" || userType === "admin" ? (
                    <Select
                      style={{
                        width: "350px",
                      }}
                      value={
                        action === "Create"
                          ? tableValues.site
                          : tableValues.site._id
                      }
                      onChange={(e) => {
                        setTableValues((prev) => ({
                          ...prev,
                          site: e.target.value,
                        }));
                      }}
                      placeholder="site"
                      required={true}
                    >
                      <option value="">Select Site</option>
                      {sites.map((site, index) => (
                        <option value={site._id} key={index}>
                          {site.siteName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    tableValues.site.siteName
                  )}
                </td>
              </tr>
              <tr className="mealType">
                <th colSpan={7}>Meal Type</th>
                <th colSpan={5}>
                  {userType === "admin" || userType === "vendor" ? (
                    <Select
                      name="mealType"
                      value={tableValues.mealType}
                      onChange={onChange}
                      required={true}
                      placeholder="Meal Type"
                    >
                      <option value="">Select Meal Type</option>
                      <option value="Breakfast">Breakfast</option>
                      <option value="AM Snack">Am snack</option>
                      <option value="Lunch">Lunch</option>
                      <option value="PM Snack">PM Snack</option>
                      <option value="Supper">Supper</option>
                      <option value="Evening Snack">Evening Snack</option>
                    </Select>
                  ) : (
                    tableValues.mealType
                  )}
                </th>
              </tr>
              <tr className="mealOrdered">
                <th colSpan={7}>Number of Meals Ordered:</th>
                <td colSpan={6}>
                  {userType === "admin" || userType === "vendor" ? (
                    <Input
                      type="number"
                      name="mealsOrdered"
                      value={tableValues.mealsOrdered}
                      onChange={onChange}
                      placeholder="Ordered quantity"
                      required
                    />
                  ) : (
                    tableValues.mealsOrdered
                  )}
                </td>
              </tr>
              <tr className="mealDelivered">
                <th colSpan={7}>Number of Meals Delivered:</th>
                <td colSpan={6}>
                  {userType === "admin" ||
                  userType === "vendor" ||
                  userType === "site" ? (
                    <Input
                      type="number"
                      name="mealsDelivered"
                      value={tableValues.mealsDelivered}
                      onChange={onChange}
                      placeholder="Delivered quantity"
                      required
                    />
                  ) : (
                    tableValues.mealsDelivered
                  )}
                </td>
              </tr>
              <tr className="mealChecks">
                <th
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                  rowSpan={2}
                >
                  Description of Food Items Delivered <br />
                  (Including milk, if applicable)
                </th>
                <th
                  colSpan={2}
                  rowSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Quantity <br />
                  Delivered or Serving <br /> Size
                </th>
                <th
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Food Item <br /> Delivered <br />
                  (check one)
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderBottom: "3px solid #000",
                  }}
                  colSpan={6}
                  rowSpan={1}
                >
                  Temperature and Time
                </th>
              </tr>
              <tr className="mealChecks2">
                <th
                  style={{
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  Unitized
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Bulk
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Temp. <br /> leaving <br />
                  Kitchen
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Time
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Temp. <br /> at <br />
                  delivery
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Time
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Temp. <br /> when <br />
                  served
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Time
                </th>
              </tr>
              {foodItems.map((food, index) => (
                <tr className="mealCheckData" key={index}>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    {userType === "vendor" || userType === "admin" ? (
                      <TextArea
                        placeholder="items"
                        style={{
                          height: "auto",
                          minHeight: "40px",
                        }}
                        value={food.items}
                        name="items"
                        onChange={(e) => foodItemChange(e, index, "")}
                      />
                    ) : (
                      <p>{food.items}</p>
                    )}
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {userType === "vendor" || userType === "admin" ? (
                      <Input
                        type="number"
                        placeholder="quantity"
                        name="quantity"
                        value={food.quantity}
                        onChange={(e) => foodItemChange(e, index, "")}
                      />
                    ) : (
                      <p>{food.quantity}</p>
                    )}
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      textAlign: "center",
                    }}
                    className="checkBox"
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        type="radio"
                        value={food.deliveryType}
                        checked={food.deliveryType === 1 ? true : false}
                        name={`deliveryType${index}`}
                        onChange={(e) => checkFoodItem(e, 1, index)}
                      />
                    ) : (
                      <p>
                        {food.deliveryType === 1 ? (
                          <FaCheck />
                        ) : (
                          <FaTimes size={20} />
                        )}
                      </p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    className="checkBox"
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        type="radio"
                        value={food.deliveryType}
                        checked={food.deliveryType === 2 ? true : false}
                        name={`deliveryType${index}`}
                        onChange={(e) => checkFoodItem(e, 2, index)}
                      />
                    ) : (
                      <p>
                        {food.deliveryType === 2 ? (
                          <FaCheck />
                        ) : (
                          <FaTimes size={20} />
                        )}
                      </p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="temp leavning kitchen"
                        name="temp"
                        type="number"
                        value={food.state1.temp}
                        onChange={(e) => foodItemChange(e, index, "state1")}
                      />
                    ) : (
                      <p>{food.state1.temp}</p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="time leavning kitchen"
                        name="time"
                        type="time"
                        value={food.state1.time}
                        onChange={(e) => foodItemChange(e, index, "state1")}
                      />
                    ) : (
                      <p>{food.state1.time}</p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="temp at delivery"
                        name="temp"
                        value={food.state2.temp}
                        onChange={(e) => foodItemChange(e, index, "state2")}
                      />
                    ) : (
                      <p>{food.state2.temp}</p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="time at delivery"
                        type="time"
                        value={food.state2.time}
                        name="time"
                        onChange={(e) => foodItemChange(e, index, "state2")}
                      />
                    ) : (
                      <p>{food.state2.time}</p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="temp when served"
                        value={food.state3.temp}
                        name="temp"
                        onChange={(e) => foodItemChange(e, index, "state3")}
                      />
                    ) : (
                      <p>{food.state3.temp}</p>
                    )}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={1}
                  >
                    {userType === "vendor" ||
                    userType === "admin" ||
                    userType === "driver" ? (
                      <Input
                        placeholder="time when served"
                        value={food.state3.time}
                        type="time"
                        name="time"
                        onChange={(e) => foodItemChange(e, index, "state3")}
                      />
                    ) : (
                      <p>{food.state3.time}</p>
                    )}
                  </td>
                </tr>
              ))}
              <tr className="milkTypeAmount">
                <th colSpan={4}>
                  Type and Amount of Milk <br />
                  Delivered Today <br />
                  (if applicable)
                </th>
                <th
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Number of <br /> 4 oz. Cartons
                </th>
                <th
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Number of <br /> 8 oz. Cartons
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  Number of <br />
                  Half Gallons
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  Number of <br />
                  Gallons
                </th>
              </tr>
              <tr className="fatFree">
                <th colSpan={4}>Fat Free (Skim)</th>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="fatfree"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.fatfree.fourOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "fourOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.fatfree.fourOzQuantity}</p>
                  )}
                </td>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="fatfree"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.fatfree.eightOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "eightOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.fatfree.eightOzQuantity}</p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="fatfree"
                      placeholder="quantity"
                      value={
                        tableValues.milkDelivery.fatfree.halfGallonQuantity
                      }
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "halfGallonQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.fatfree.halfGallonQuantity}</p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="fatfree"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.fatfree.gallonQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "gallonQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.fatfree.gallonQuantity}</p>
                  )}
                </td>
              </tr>
              <tr className="lowFat">
                <th colSpan={4}>Low Fat (1%)</th>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="lowfat"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.lowfat.fourOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "fourOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.lowfat.fourOzQuantity}</p>
                  )}
                </td>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="lowfat"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.lowfat.eightOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "eightOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.lowfat.eightOzQuantity}</p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="lowfat"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.lowfat.halfGallonQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "halfGallonQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.lowfat.halfGallonQuantity}</p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="lowfat"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.lowfat.gallonQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "gallonQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.lowfat.gallonQuantity}</p>
                  )}
                </td>
              </tr>
              <tr className="wholeMilk">
                <th colSpan={4}>Whole Milk</th>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="wholeMilk"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.wholeMilk.fourOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "fourOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.wholeMilk.fourOzQuantity}</p>
                  )}
                </td>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="wholeMilk"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.wholeMilk.eightOzQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "eightOzQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.wholeMilk.eightOzQuantity}</p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="wholeMilk"
                      placeholder="quantity"
                      value={
                        tableValues.milkDelivery.wholeMilk.halfGallonQuantity
                      }
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "halfGallonQuantity")
                      }
                    />
                  ) : (
                    <p>
                      {tableValues.milkDelivery.wholeMilk.halfGallonQuantity}
                    </p>
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {userType === "vendor" || userType === "admin" ? (
                    <Input
                      type="number"
                      name="wholeMilk"
                      placeholder="quantity"
                      value={tableValues.milkDelivery.wholeMilk.gallonQuantity}
                      onChange={(e) =>
                        onChange(e, "milkDelivery", "gallonQuantity")
                      }
                    />
                  ) : (
                    <p>{tableValues.milkDelivery.wholeMilk.gallonQuantity}</p>
                  )}
                </td>
              </tr>
              <tr className="DeliveryValidity">
                <th colSpan={2}>DELIVERY</th>

                <td
                  colSpan={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Print Name Vendor Representative
                  {userType === "admin" || userType === "vendor" ? (
                    <Select
                      value={
                        action === "Create"
                          ? tableValues.vendorRepresentative
                          : tableValues.vendorRepresentative._id
                      }
                      name="vendorRepresentative"
                      onChange={onChange}
                      placeholder="vendor representative(driver)"
                      required={true}
                    >
                      <option value="">Select Driver</option>
                      {drivers.map((driver, index) => (
                        <option key={index} value={driver._id}>
                          {driver.firstName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <div>
                      <b>{tableValues.vendorRepresentative.firstName}</b>
                    </div>
                  )}
                </td>
                <td
                  colSpan={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Signature Vendor Representative
                  {action === "Create" ||
                  !tableValues.vendorRepresentative.signature ? (
                    ""
                  ) : (
                    <div className="signatureBoard">
                      <img
                        src={tableValues.vendorRepresentative.signature}
                        alt="signature"
                      />
                    </div>
                  )}
                </td>
                <th
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={1}
                >
                  Time of Delivery
                </th>
                <td
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={1}
                >
                  {userType === "vendor" ||
                  userType === "driver" ||
                  userType === "admin" ? (
                    <Input
                      type="time"
                      name="deliveryTime"
                      onChange={onChange}
                      value={tableValues.deliveryTime}
                    />
                  ) : (
                    tableValues.deliveryTime
                  )}
                </td>
              </tr>
              <tr className="ReceiptValidity">
                <th colSpan={2}>RECEIPT</th>
                <td
                  colSpan={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Print Name Sponsor Representative
                  {(userType === "admin" && action === "Create") ||
                  (userType === "vendor" && action === "Create") ? (
                    <Select
                      value={tableValues.site}
                      name="sponsorRepresentative"
                      readOnly={true}
                    >
                      <option value="">Select Site</option>
                      {sites.map((site, index) => (
                        <option key={index} value={site._id}>
                          {site.siteName}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <div>
                      <b>{tableValues.site.siteName}</b>
                    </div>
                  )}
                </td>
                <td
                  colSpan={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Signature Sponsor Representative
                </td>
              </tr>
              <tr className="problems">
                <th
                  colSpan={12}
                  style={{
                    borderBottom: 0,
                    textAlign: "left",
                  }}
                >
                  List any problems or discrepancies regarding food and/or
                  delivery:
                </th>
              </tr>
              <tr className="problemsData">
                <td
                  colSpan={12}
                  style={{
                    borderTop: 0,
                    textAlign: "left",
                  }}
                >
                  {userType === "vendor" ||
                  userType === "admin" ||
                  userType === "site" ? (
                    <TextArea
                      placeholder="problems"
                      style={{
                        height: "auto",
                        minHeight: "150px",
                        width: "1000px",
                      }}
                      value={tableValues.problems}
                      name="problems"
                      onChange={onChange}
                    />
                  ) : (
                    <p>{tableValues.problems}</p>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={30}>
                  <div
                    className="action"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <Button
                      text={`${action} Delivery Slip`}
                      type="primary"
                      style={{
                        color: "#fff",
                        backgroundColor: "#5cb85c",
                        width: "200px",
                        margin: 0,
                      }}
                      onClick={onSubmit}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SubDashboardLayout>
  );
};

export default DeliverySlip;
