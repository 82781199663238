import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  FaRoute,
  FaRegCheckCircle,
  FaListUl,
  FaFileInvoiceDollar,
  FaExchangeAlt,
  FaRegAddressCard,
  FaScroll,
} from "react-icons/fa";

// layout
import DashboardLayout from "../../components/DashboardLayout";

const VendorsPage = (props) => {
  return (
    <DashboardLayout
      DashboardTitle={"Vendor"}
      id="vendorDashboard"
      optionsLength={7}
    >
      <Link to="orders-received">
        <FaExchangeAlt size={25} />
        <span>Orders Received</span>
      </Link>
      <Link to="delivery-slip">
        <FaScroll size={25} />
        <span>Delivery slips</span>
      </Link>
      <Link to="invoices">
        <FaFileInvoiceDollar size={25} />
        <span>Invoices</span>
      </Link>
      <Link to="drivers">
        <FaRegAddressCard size={25} />
        <span>Drivers</span>
      </Link>
      <Link to="assign-route">
        <FaRoute size={25} />
        <span>Assign Route</span>
      </Link>
      <Link
        to="cn-labels"
        style={{
          pointerEvents: "none",
          color: "#d2d2d2",
        }}
      >
        <FaRegCheckCircle size={25} />
        <span>CN Labels</span>
      </Link>
      <Link to="menu">
        <FaListUl size={25} />
        <span>Menu</span>
      </Link>
    </DashboardLayout>
  );
};

export default VendorsPage;
