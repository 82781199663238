import React from "react";
import TextArea from "../../FormComponents/TextArea";

const Breakfast = (props) => {
  let { setBreakfast, breakfast, edit } = props;

  const setMeal = (e, index, name) => {
    let newArr = [...breakfast];
    newArr[index][name] = e.target.value;
    setBreakfast(newArr);
  };

  return (
    <>
      {breakfast.map((meal, index) =>
        index === 0 ? (
          <tr className="breakfast" key={index}>
            <th rowSpan={breakfast.length}>
              <p className="rotateText">
                Breakfast
                <br />
              </p>
            </th>
            <td>
              {edit ? (
                <TextArea
                  value={meal.items}
                  placeholder="items"
                  onChange={(e) => setMeal(e, index, "items")}
                />
              ) : (
                <pre>{meal.items}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity1}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity1")}
                />
              ) : (
                <pre>{meal.quantity1}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity2}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity2")}
                />
              ) : (
                <pre>{meal.quantity2}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity3}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity3")}
                />
              ) : (
                <pre>{meal.quantity3}</pre>
              )}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.mon}
                  onChange={(e) => setMeal(e, index, "mon")}
                />
              )}
              {meal.mon}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.tues}
                  onChange={(e) => setMeal(e, index, "tues")}
                />
              )}
              {meal.tues}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.wed}
                  onChange={(e) => setMeal(e, index, "wed")}
                />
              )}
              {meal.wed}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.thurs}
                  onChange={(e) => setMeal(e, index, "thurs")}
                />
              )}
              {meal.thurs}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.fri}
                  onChange={(e) => setMeal(e, index, "fri")}
                />
              )}
              {meal.fri}
            </td>
          </tr>
        ) : (
          <tr className="breakfast" key={index}>
            <td>
              {edit ? (
                <TextArea
                  value={meal.items}
                  placeholder="items"
                  onChange={(e) => setMeal(e, index, "items")}
                />
              ) : (
                <pre>{meal.items}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity1}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity1")}
                />
              ) : (
                <pre>{meal.quantity1}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity2}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity2")}
                />
              ) : (
                <pre>{meal.quantity2}</pre>
              )}
            </td>
            <td>
              {edit ? (
                <TextArea
                  value={meal.quantity3}
                  placeholder="measurement"
                  onChange={(e) => setMeal(e, index, "quantity3")}
                />
              ) : (
                <pre>{meal.quantity3}</pre>
              )}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.mon}
                  onChange={(e) => setMeal(e, index, "mon")}
                />
              )}
              {meal.mon}
            </td>

            <td>
              {edit && (
                <TextArea
                  value={meal.tues}
                  onChange={(e) => setMeal(e, index, "tues")}
                />
              )}
              {meal.tues}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.wed}
                  onChange={(e) => setMeal(e, index, "wed")}
                />
              )}
              {meal.wed}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.thurs}
                  onChange={(e) => setMeal(e, index, "thurs")}
                />
              )}
              {meal.thurs}
            </td>
            <td>
              {edit && (
                <TextArea
                  value={meal.fri}
                  onChange={(e) => setMeal(e, index, "fri")}
                />
              )}
              {meal.fri}
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default Breakfast;
