import React, { useRef } from "react";
import { useSelector } from "react-redux";
import "./style.scss";

// layout
import SubDashboardLayout from "../SubDashboardLayout";

//component
import Button from "../Button";
import Input from "../FormComponents/Input";

const MonitorReviewForm = () => {
  const userType = useSelector((state) => state.auth.type);

  //   state
  const MealType = [
    { title: "Breakfast", checked: "BreakfastObserved" },
    { title: "AmSnack", checked: "AmSnackObserved" },
    { title: "PmSnack", checked: "PmSnackObserved" },
    { title: "Supper", checked: "SupperObserved" },
    { title: "EveSnack", checked: "EveSnackObserved" },
    { title: "Lunch", checked: "LunchObserved" },
  ];

  const tableRef = useRef();
  const onPrint = () => {
    window.print();
  };
  return (
    <SubDashboardLayout
      title={userType}
      id={"montiorReviewForm"}
      to={"../../"}
      operationName="Monitor Form"
    >
      <Button text="Export Form" onClick={onPrint} />
      <div className="monitorReviewContainer">
        <div
          className="monitorReview"
          style={{
            overflowX: "auto",
          }}
          ref={tableRef}
        >
          <div className="formHeader">
            <h3
              className="textCenter"
              style={{
                textAlign: "center",
              }}
            >
              Child Care Food Program
            </h3>
            <h2 className="textCenter">Monitor Review Form</h2>
            <p className="description">
              <u>Monitoring Requirements:</u> All multi-site sponsoring
              organizations must adequately train, supervise and review
              site/facility operations to ensure that CACFP requirements are
              met. New sites must have a review completed within the first 4
              weeks of CACFP operation. Sites must be reviewed at least 3 times
              per CACFP fiscal year (October 1 thorough September 30). Reviews
              cannot be more than 6 months apart within a fiscal year and from
              one fiscal year to the next. At least 2 of the 3 reviews must be
              unannounced and at least one unannounced review must include a
              meal service observation. Sponsors may choose to use the Review
              Averaging Option by indicating so on the application management
              plan. Sponsors still have to conduct the same number of reviews (3
              x number of sites) but some sites could receive 2, 3 or 4 reviews
              per year. No more than 9 months can elapse between reviews when
              only 2 are conducted and both must be unannounced and one must
              include a meal service observation
            </p>
            <div className="chartTable">
              <p className="chartDesc">
                If site is not approved and participating on the CACFP for the
                full fiscal year, use the following chart to determine the
                number of required reviews.
              </p>
              <table>
                <tbody>
                  <tr>
                    <th className="textCenter">
                      Number of Months Site is Open and Participating during
                      CACFP Fiscal Year
                    </th>
                    <th className="textCenter">
                      Number of Required Monitoring Reviews
                    </th>
                    <th className="textCenter">
                      Number of Required Monitoring Reviews that must be
                      Unannounced
                    </th>
                    <th className="textCenter">
                      Number of Required Monitoring Reviews that must be
                      Unannounced
                    </th>
                  </tr>
                  <tr>
                    <td className="textCenter">1-4</td>
                    <td className="textCenter">1</td>
                    <td className="textCenter">1</td>
                    <td className="textCenter">1</td>
                  </tr>
                  <tr>
                    <td className="textCenter">5-8</td>
                    <td className="textCenter">2</td>
                    <td className="textCenter">2</td>
                    <td className="textCenter">1</td>
                  </tr>
                  <tr>
                    <td className="textCenter">9-12</td>
                    <td className="textCenter">3</td>
                    <td className="textCenter">2</td>
                    <td className="textCenter">1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="sponsorTable" id="formContainer">
            <table>
              <tbody>
                <tr>
                  <th>Sponsoring Organization Name: </th>
                  <td colSpan={3} className="sponsorName">
                    <Input placeholder="Organization Name" />
                  </td>
                </tr>
                <tr>
                  <th>Site/Facility Name and Address:</th>
                  <td colSpan={3} className="siteName">
                    <Input placeholder="Site Name and Address" />
                  </td>
                </tr>
                <tr>
                  <th>Name of Monitor:</th>
                  <td className="monitorName">
                    <Input name="monitorName" placeholder="Monitor name" />
                  </td>
                  <th>Arrival Time:</th>
                  <td
                    className="arrivaleTime"
                    style={{
                      padding: "0 3rem",
                    }}
                  ></td>
                </tr>
                <tr>
                  <th>Date of Last Review:</th>
                  <td className="lastReviewDate"></td>
                  <th>Date of Review:</th>
                  <td
                    className="reviewDate"
                    style={{
                      padding: "0 3rem",
                    }}
                  ></td>
                </tr>
                <tr>
                  <th>Is Review Averaging option is used:</th>
                  <td className="reviewAveragingOption">
                    <div
                      className="rowFlex"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>Yes</span>
                    </div>
                    <div
                      className="rowFlex"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>No</span>
                    </div>
                  </td>
                  <td className="reviewNumber" colSpan={2}>
                    <div>
                      <b>Announced Review, number:</b>
                    </div>
                    <div>
                      <b>Unannounced Review, number:</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="reviewType" colSpan={4}>
                    <div className="rowFlex">
                      <b>Type of Review:</b>
                      <div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "2rem",
                            }}
                          >
                            <Input type="checkbox" />
                            New site, review conducted within first 4 weeks
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Input type="checkbox" />
                            Follow-up review
                          </p>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "2rem",
                            }}
                          >
                            <Input type="checkbox" />
                            Regular review
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Input type="checkbox" />
                            Block claim investigation
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <b>Total Number of Children In Attendance:</b>
                    </div>
                  </td>
                  <td colSpan={3} className="textCenter">
                    <b>If Site Licensed for Child Care</b>
                    <div className="rowFlex">
                      <b>License ExpirationDate: </b>
                      <div></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Is Attendance Within License Capacity?</b>
                    <div className="rowFlex">
                      <div className="rowFlex">
                        <Input type="checkbox" />
                        <span>Yes</span>
                      </div>
                      <div className="rowFlex">
                        <Input type="checkbox" />
                        <span>No</span>
                      </div>
                      <div className="rowFlex">
                        <Input type="checkbox" />
                        <span>N/A</span>
                      </div>
                    </div>
                  </td>
                  <td colSpan={3} rowSpan={2} className="textCenter">
                    <b>If Site is exempt form having a child care license</b>
                    <div className="rowFlex">
                      <b>Health Inspection Date: </b>
                      <div></div>
                    </div>
                    <div className="rowFlex">
                      <b>Fire Inspection Date: </b>
                      <div></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingBottom: "3rem",
                    }}
                  >
                    <b>License Capacity (if applicable):</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <th>Site Type:</th>
                  <td className="siteType" colSpan={4}>
                    <div className="rowFlex">
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          Child Care Center
                        </p>{" "}
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          Youth Development
                        </p>{" "}
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          Outside School Hour (Licensed)
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          Head Start
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          After School At Risk
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Input type="checkbox" />
                          After School At Risk
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mealService">
            <h2 className="textCenter">A. Meal Service for Children </h2>
            <table>
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <div
                      className="rowFlex"
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "space-evenly",
                        height: "100px",
                      }}
                    >
                      <span> Was A Meal Service Observed?</span>
                      <div
                        className="rowFlex"
                        style={{
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <Input type="checkbox" />
                        <span>Yes</span>
                      </div>
                      <div
                        className="rowFlex"
                        style={{
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <Input type="checkbox" />
                        <span>No</span>
                      </div>
                      <div
                        style={{
                          width: "40%",
                        }}
                      >
                        If No, Explain:
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Circle Meal Observed</th>
                  {MealType.map((meal, index) => (
                    <td key={index}>
                      <div className="rowFlex">
                        <Input type="checkbox" />
                        {meal.title}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="textCenter">
                    Circle Type of Food Preparation:
                  </th>
                  <td className="textCenter">on-Site</td>
                  <td className="textCenter">Central/Kitchen</td>
                  <td colSpan={4}>vended/Catered: List Vendor:</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mealService2">
            <table>
              <tbody>
                <tr className="textCenter">
                  <th rowSpan={2}>Meals</th>
                  <th rowSpan={2}>Meal Requirement</th>
                  <th rowSpan={2}>Food Item Served/Observed</th>

                  <th colSpan={3} className="textCenter">
                    List Amount/Portion Size Served
                  </th>
                  <td className="textCenter" rowSpan={2}>
                    <b>Problems Observed </b>
                    <br />
                    (Missing Components/Portions)
                  </td>
                </tr>
                <tr>
                  <th className="textCenter">1-2</th>
                  <th className="textCenter">3-5</th>
                  <th className="textCenter">6-18</th>
                </tr>
                <tr>
                  <td rowSpan={4}>Breakfast</td>
                  <td>Milk</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td rowSpan={4}></td>
                </tr>
                <tr>
                  <td>Vegetables, fruits, or portions of both</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Grains (oz. eq.) Whole grain rich or enriched bread,
                    cooked/dry cereal, or pasta
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Other Foods</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td rowSpan={6}>
                    LUNCH OR <br />
                    SUPPER
                  </td>
                  <td>Milk</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td rowSpan={6}></td>
                </tr>
                <tr>
                  <td>Meat / meat alternate</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Vegetables</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Fruits</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Grains (oz. eq.) Whole grain rich or enriched bread,
                    cooked/dry cereal, or pasta
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Other Foods</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td rowSpan={5}>
                    SNACK
                    <br />
                    <br />
                    <b>Select 2 of 4 components</b>
                  </td>
                  <td>Milk</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td rowSpan={5}></td>
                </tr>
                <tr>
                  <td>Meat / meat alternate</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Vegetables</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Fruits</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Grains (oz. eq.) Whole grain rich or enriched bread,
                    cooked/dry cereal, or pasta
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mealCount">
            <div>
              <h2 className="textCenter">B. Meal Counts </h2>
              <table>
                <tbody>
                  <tr>
                    <td rowSpan={2} className="textCenter">
                      Name of Classroom
                    </td>
                    <td rowSpan={2} className="textCenter">
                      Age Range
                    </td>
                    <td colSpan={2} className="textCenter">
                      No. of Meals Served to Children
                    </td>
                  </tr>
                  <tr>
                    <td className="textCenter">1st Meals</td>
                    <td className="textCenter">2nd Meals</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="textCenter" colSpan={2}>
                      Totals
                    </th>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <th className="textCenter">Yes</th>
                    <th className="textCenter">No</th>
                    <th className="textCenter">N/A</th>
                    <th className="textCenter">Meal Counts</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      1. The observed meal met CACFP meal pattern requirements.
                      If no, explain in other comments.
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      2. Meal counts were recorded at the POINT OF SERVICE
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      3. Meal counts recorded by the center for the observed
                      meal matches monitor’s meal counts for each classroom.
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      4. Meals are claimed only for children meeting the
                      specific program type age limit participation
                      requirements.
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      5. Excluding this meal, all meal counts for previous meals
                      served in month have been completed/recorded on meal count
                      forms.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <th className="textCenter" colSpan={4}>
                      Complete chart below for any meals dissallowed by monitor
                    </th>
                  </tr>
                  <tr>
                    <th className="textCenter">Name of Classroom</th>
                    <th className="textCenter">Date(s)</th>
                    <th className="textCenter">MealType(s)</th>
                    <th className="textCenter">No. of Meals Disallowed</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Other Comments</td>
                    <td
                      colSpan={3}
                      style={{
                        height: "90px",
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mealServiceInfants">
            <div>
              <h2 className="textCenter">C. Meal Service for Infants</h2>
              <p className="textCenter">
                (Infant is defined as being under 1 year of age)
              </p>
              <table>
                <tbody>
                  <tr>
                    <th className="textCenter" colSpan={7}>
                      CIRCLE N/A AND SKIP THIS PAGE IF THERE ARE NO INFANTS
                      UNDER 1 YEAR OF AGE AT THIS CENTER: N/A
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={7}>
                      <div
                        className="rowFlex"
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-evenly",
                          height: "100px",
                        }}
                      >
                        <span> Was A Meal Service Observed?</span>
                        <div
                          className="rowFlex"
                          style={{
                            justifyContent: "center",
                            margin: 0,
                          }}
                        >
                          <Input type="radio" />
                          <span>Yes</span>
                        </div>
                        <div
                          className="rowFlex"
                          style={{
                            justifyContent: "center",
                            margin: 0,
                          }}
                        >
                          <Input type="radio" />
                          <span>No</span>
                        </div>
                        <div
                          style={{
                            width: "40%",
                          }}
                        >
                          If No, Explain:
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Circle Meal Observed</td>
                    {MealType.map((meal, index) => (
                      <td key={index}>
                        <div className="rowFlex">
                          <Input type="checkbox" />
                          {meal.title}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th>Meal/Age</th>
                    <th>Portion Size</th>
                    <th>Food Served</th>
                  </tr>
                  <tr>
                    <td>
                      <b>Breakfast</b>
                      <br />
                      0-5 months
                    </td>
                    <td>4-6 oz. Breastmilk or Iron Fortified Infant Formula</td>
                    <td
                      style={{
                        padding: "1rem 12rem",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      6-11 months
                    </td>
                    <td>
                      6-8 oz. Breastmilk or Iron Fortified Infant Formula and
                      0-4 Tbsp. Iron Fortified Infant Cereal, meat, fish,
                      poultry, whole egg, cooked dry beans, or cooked dry peas
                      or 0-2 ounces of cheese; or 0-4 ounces (volume) or cottage
                      cheese; or 0-4 ounces or ½ cup of yogurt; or a combination
                      of the above; And 0-2 tablespoons vegetable or fruit or a
                      combination of both
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Lunch</b>
                      <br />
                      0-5 months
                    </td>
                    <td>4-6 oz. Breastmilk or Iron Fortified Infant Formula</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      6-11 months
                    </td>
                    <td>
                      6-8 oz. Breastmilk or Iron Fortified Infant Formula and
                      0-4 Tbsp. Iron Fortified Infant Cereal, meat, fish,
                      poultry, whole egg, cooked dry beans, or cooked dry peas
                      or 0-2 ounces of cheese; or 0-4 ounces (volume) or cottage
                      cheese; or 0-4 ounces or ½ cup of yogurt; or a combination
                      of the above; And 0-2 tablespoons vegetable or fruit or a
                      combination of both
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Snack</b>
                      <br />
                      0-5 months
                    </td>
                    <td>4-6 oz. Breastmilk or Iron Fortified Infant Formula</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      6-11 months
                    </td>
                    <td>
                      2-4 fluid ounces breastmilk or Iron Fortified Infant
                      formula; And 0-1/2 slice bread; or 0-2 crackers; or 0-4
                      Tbsp. vegetable or fruit or a combination of both
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <th>Yes</th>
                    <th>No</th>
                    <th>N/A</th>
                    <th>Infant Meal Service and Menus</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      1. A daily infant feeding record which reflect dates
                      served, food items served and portion sizes is on file for
                      each infant whose meals are claimed for CACFP
                      reimbursement.
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      2. Infant menus meet CACFP meal pattern requirements. If
                      no, explain in other comments.
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      3. Infant Meals – Parent Preference Letters are on file
                      for all infants enrolled at the center.
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      4. Bottles and formula are properly labeled, stored and
                      prepared in a sanitary manner in accordance with health
                      and licensing regulations.
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      5. Foods for infants are prepared in an age-appropriate
                      manner to avoid choking. (pureed, ground, small slices,
                      mashed)
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      6. Commercial baby-jar foods are single product. (no
                      combination meals such as chicken with noodles and
                      desserts like Cherry Delight)
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      7. When infants are developmentally ready to eat solid
                      foods, meals claimed can only contain one parent-provided
                      meal component.
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      9. Meal count is recorded by individual name at the POINT
                      OF SERVICE on infant meal count form.
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={3}>Other Comments</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="dayRecolation">
            <h3 className="textCenter">
              D. 5-DAY RECONCILIATION OF MEAL COUNTS FORM FOR CENTER-BASED
              PROGRAMS
            </h3>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Circle meal type observed or last meal/snack served prior
                      to monitoring review:
                    </th>
                    <th>Breakfast</th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      AM Snack
                    </th>
                    <th>Lunch</th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      PM Snack
                    </th>
                    <th>Supper</th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Evening Snack
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="instructions">
              <h3>INSTRUCTION</h3>
              <ul>
                <li>Leave Shaded areas blank</li>
                <li>
                  To calculate 5-day meal count average (if more than one
                  classroom) add day #1 – #5 meal count total and divide by 5.
                  Insert answer in block B.
                </li>
              </ul>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      To calculate 5-day meal count average (if more than one
                      classroom) add day #1 – #5 meal count total and divide by
                      5. Insert answer in block B.
                    </th>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <th></th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Today's Date:
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Date:
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Date:
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Date:
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Date:
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        height: "60px",
                      }}
                    >
                      Date:
                    </th>
                  </tr>
                  <tr>
                    <th>Classroom</th>
                    <th className="textCenter">Today's Meal Count</th>
                    <th className="textCenter"> Today's Attendance</th>
                    <th className="textCenter">Day#1 Meal Count</th>
                    <th className="textCenter"> Attendance</th>
                    <th className="textCenter">Day#2 Meal Count</th>
                    <th className="textCenter">Attendance</th>
                    <th className="textCenter">Day#3 Meal Count</th>
                    <th className="textCenter">Attendance</th>
                    <th className="textCenter">Day#4 Meal Count</th>
                    <th className="textCenter">Attendance</th>
                    <th className="textCenter">Day#5 Meal Count</th>
                    <th className="textCenter">Attendance</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>6</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>7</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>8</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>9</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>10</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="textCenter">A.Today’s Meal Count Total</th>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      Attendance Total
                    </td>
                  </tr>
                  <tr>
                    <th className="textCenter">B. 5-Day Meal Count Average</th>
                    <td
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <th
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      = Divide By 5
                    </th>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {" "}
                      Day#1 Total
                    </td>
                    <td
                      className="textCenter"
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {" "}
                      Day#2 Total
                    </td>
                    <td
                      className="textCenter"
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {" "}
                      Day#3 Total
                    </td>
                    <td
                      className="textCenter"
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {" "}
                      Day#4 Total
                    </td>
                    <td
                      className="textCenter"
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                    <td
                      className="textCenter"
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {" "}
                      Day#5 Total
                    </td>
                    <td
                      className="textCenter"
                      style={{
                        backgroundColor: "#cecece",
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <th>Yes</th>
                    <th>No</th>
                    <th>N/A</th>
                    <th>5 – Day Reconciliation of Meal Counts</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      1. Is today’s meal count total (A on chart) different than
                      the 5-Day meal count average (B on chart)? If yes, explain
                      any significant discrepancy.
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      2. Is today’s total attendance reasonable in comparison to
                      the other 5 days total attendance?
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      3. Are any meal counts over claimed when compared to daily
                      attendance? If so, circle and make any needed
                      adjustments/disallowances to classroom meal count records.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mealServiceMenus">
            <h3 className="textCenter">E. Meal Service and Menu</h3>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th>General Record Keeping and Meal Service</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. Current menus are posted in at least one place at the
                    center.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. Dated menus are kept on file for all approved meal types.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>3. Menu substitutions are recorded on the menus.</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    4. The food served was the same as listed on the planned
                    menu.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    5. Written menus are in compliance with the CACFP meal
                    pattern requirements.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    6. Portion sizes were in compliance for the age groups
                    served
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    style={{
                      paddingBottom: "70px",
                    }}
                  >
                    7. All CACFP meal components are available to all
                    participants at the beginning of the meal. If no, explain:
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    8. Adult staff sits with children to model behavior, try new
                    foods and talk together
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    9. Adults seated with children eat the same creditable foods
                    as the children
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    10. A medical statement is on file to verify any medical
                    condition of a child requiring variation from the CACFP meal
                    pattern.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>11. A valid food service license is posted</td>
                </tr>
                <tr>
                  <th colSpan={4} className="textCenter">
                    Family-Style Meal Service: Yes or No If no, check N/A for
                    questions # 13 - # 15
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>13. Each child is offered all meal components.</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    14. Enough food is available/served at each table to provide
                    minimum portions of all meal components for the children at
                    the table.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    15. All children are encouraged to take the minimum serving
                    size of each component.
                  </td>
                </tr>
                <tr>
                  <th colSpan={4} className="textCenter">
                    Delivered Meals: Yes or No If no, check N/A for questions #
                    16 - # 19
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    16. Meals are delivered in a timely manner and delivery time
                    recorded.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    17. Upon delivery, staff checks to see if the food is at a
                    safe temperature (hot and cold).
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    18. Delivered meals are checked for correct quantity and
                    quality upon delivery.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    19. Daily delivery slips provided by the vender/central
                    kitchen are signed by center staff.
                  </td>
                </tr>
                <tr>
                  <th colSpan={4} className="textCenter">
                    Adult Meals
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    20. The number of center-provided adult meals served are
                    recorded and kept on file
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>21. Adult meals are not claimed for reimbursement.</td>
                </tr>
                <tr>
                  <td colSpan={3}>Other Comments</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sanitation">
            <h3 className="textCenter">F. sanitation</h3>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. Working thermometers are in each refrigerator to maintain
                    temperature between 32 and 41 degrees. List refrigerator(s)
                    temperature(s):
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. The freezer(s) is clean and in good repair to maintain
                    correct temperature to keep food at appropriate frozen state
                    (zero degrees or below).
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>3. Cleaning supplies are stored separately from food.</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>4. Food and supplies are stored off of the floor</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    5. The center is free of rodent and insect infestation.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    6. The center uses methods to assure hot and cold foods are
                    served at correct temperatures.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    7. Proper hand washing procedures are followed by children
                    before meals and after restroom use.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    8. Proper hand washing procedures are followed by staff
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    9. Proper dishwashing methods are used (wash, rinse,
                    sanitize).{" "}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    10. Sanitary procedures are followed during meal preparation
                    including wearing disposable gloves when appropriate
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    11. All eating surfaces are properly sanitized prior to meal
                    service.
                  </td>
                </tr>

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    12. Sanitary procedures are followed during meal service.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>13. Food is properly disposed of after meal service.</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    14. Disposable tableware and/or utensils are discarded after
                    each meal service.
                  </td>
                </tr>

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    15. In general, the center is clean and conducive to meal
                    service.
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>Other Comments</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="civilrights">
            <h3 className="textCenter">G. Civil Rights</h3>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. The full size (11x17) “Justice for All” poster is on
                    display in a public place.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. The parent handbook or other written information
                    describing meals that is provided to the public contain the
                    CACFP Non-discrimination statement.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    3. All site services are offered without regard to race,
                    color, national origin, sex, age or disability
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    4. All children receive the same food and meal service
                    without any separation by race, color, national origin, sex,
                    age or disability,
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>Other Comments</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="staffTraining">
            <h3 className="textCenter">H. Staff Training</h3>
            <p className="textCenter">
              (Key Staff is defined as center staff with any assigned CACFP
              responsibility or duties)
            </p>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. The sponsor has conducted CACFP related training to key
                    site staff. List date of last training:
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. Records are on file at the center or sponsor’s office to
                    show staff training was conducted within the last 12 months.
                  </td>
                </tr>
                <tr>
                  <th
                    colSpan={3}
                    style={{
                      background: "#cecece",
                    }}
                  ></th>

                  <td
                    style={{
                      paddingBottom: "4rem",
                    }}
                  >
                    3. List any training topics that need to be provided to this
                    site.
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>Other Comments</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="recordKeeping">
            <h3 className="textCenter">I. Record Keeping</h3>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. Site distributes and collects income eligibility
                    applications (kept on file at site or sponsor’s office).
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. Enrollment forms are collected on an annual basis and
                    kept on file for all children
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    3. Nonfood supplies costs are separated from food costs.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    4. Itemized receipts or invoices for food, milk and supplies
                    are on file for each month.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    5. The site has documented distribution of WIC information
                    to families during the current fiscal year.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    6. The site has a system in place to check that recorded
                    meal counts are accurate.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    7. The Parent Notification - Building For The Future Fact
                    Sheet is included in the site’s enrollment packet
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    8. CACFP records are kept on file for three years plus the
                    current fiscal year.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    9. Staff follows established procedures for maintaining and
                    sending records to sponsor’s main office
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    10. Site is keeping daily records of number of participants
                    in attendance.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    11. Site is maintaining daily meal counts by type of meal
                    service served to participants.
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      paddingBottom: "80px",
                    }}
                  >
                    Other Comments
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="recordKeeping">
            <h3 className="textCenter">
              J. RECORD KEEPING - NON PROFIT FOOD SERVICE – FINANCIAL
              DOCUMENTATION
            </h3>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter">Yes</th>
                  <th className="textCenter"> No</th>
                  <th className="textCenter">N/A</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    1. Are reimbursement funds dispersed to sites? If yes, the
                    rest of this section must be completed
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    2. Is CACFP income to program reported and documented?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    5. Does the site charge a fee for any meals served to
                    children?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    6. Is the CACFP reimbursement deposited into the sites
                    business account?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    7. Are CACFP expenses accurately reported and documented?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    7. Are CACFP expenses accurately reported and documented?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    8. Is a food/non-food cost worksheet/ledger completed for
                    monthly food related expenditures?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    9. Itemized receipts or invoices for food, milk and supplies
                    are on file for each month.
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    10. Was documentation (daily time logs, canceled checks,
                    payroll records) available to support costs claimed for food
                    service labor?
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>11. Are non-CACFP expenses correctly excluded?</td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    12. Does the site appear to operate a non-profit food
                    service?
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      paddingBottom: "200px",
                    }}
                  >
                    Other Comments
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="findingsSummary">
            <h3 className="textCenter">K. SUMMARY OF FINDING</h3>
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      paddingBottom: "200px",
                    }}
                  >
                    1.What are the center’s strengths in operating the CACFP:
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>FINDINGS:</b>
                    <ul
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Input type="checkbox" />
                        No findings were observed
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Input type="checkbox" />
                        Findings from last monitor review were corrected and
                        were not evident during this review.
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <Input type="checkbox" />
                        Findings were observed but were resolved during the
                        visit. Findings and Corrective Action are listed below.
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Input type="checkbox" />
                        Findings were observed during this visit. Findings and
                        Corrective Action are listed below.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="findingAndCorrectiveAction">
            <h3 className="textCenter">
              L. FINDINGS AND CORRECTIVE ACTION REQUIRED
            </h3>
            <p className="textCenter">
              (If more space is need, insert additional page)
            </p>
            <table>
              <tbody>
                <tr>
                  <th className="textCenter"> FINDING/PROBLEM</th>
                  <th className="textCenter">
                    REQUIRED CORRECTIVE ACTION <br /> (specific steps to be
                    taken )
                  </th>
                  <th className="textCenter"> DUE DATE</th>
                  <th className="textCenter">DATE COMPLETED</th>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "2rem 0",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "2rem 0",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "2rem 0",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "2rem 0",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="footer"
            style={{
              width: "100%",
            }}
          >
            <p className="textCenter">
              I certify the above information is correct. The monitor discussed
              the contents of this report with the site director.
            </p>
            <div
              className="sgnature"
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "5rem",
              }}
            >
              <div
                className="monitorSign"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "2px solid #000",
                }}
              >
                <span
                  style={{
                    paddingRight: "5rem",
                  }}
                >
                  Signature of Sponsor Monitor
                </span>
                <span>Date</span>
              </div>
              <div
                className="monitorSign"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "2px solid #000",
                }}
              >
                <span
                  style={{
                    paddingRight: "5rem",
                  }}
                >
                  Signature of Sponsor Monitor
                </span>
                <span>Date</span>
              </div>
            </div>
            <div
              className="time"
              style={{
                paddingTop: "2rem",
                display: "flex",
              }}
            >
              <span>Time of Departure:</span>
              <div
                style={{
                  width: "200px",
                  borderBottom: "2px solid #000",
                }}
              ></div>
            </div>
            <div
              style={{
                marginTop: "2rem",
                padding: "2rem",
                border: "2px solid #000",
              }}
            >
              <h3 className="textCenter">FOLLOW-UP REVIEW</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input type="checkbox" />
                  No Follow-Up Review Needed
                </p>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input type="checkbox" />
                  Follow-Up Review Needed
                </p>
                <p
                  style={{
                    display: "flex",
                  }}
                >
                  Date of Follow-up Review:
                  <div
                    style={{
                      width: "200px",
                      borderBottom: "2px solid #000",
                    }}
                  ></div>
                </p>
              </div>
              <div
                style={{
                  paddingBottom: "4rem",
                }}
              >
                Comments Regarding Follow-up Review:
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubDashboardLayout>
  );
};

export default MonitorReviewForm;
