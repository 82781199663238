import axios from "../helper/axios";
import { siteConstants } from "./constants";

// sites
export const siteSignin = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.SITE_SIGNIN_REQUEST });
      let res = await axios.post(`/sites/signin`, credentials);

      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("type", "site");
        return dispatch({
          type: siteConstants.SITE_SIGNIN_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: siteConstants.SITE_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.SITE_SIGNIN_FAILURE,
        payload: "Invalid credentials",
      });
    }
  };
};

export const getAllSites = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.GET_ALL_SITES_REQUEST });
      let res = await axios.get(`/sites/all`);

      if (res.status === 200) {
        return dispatch({
          type: siteConstants.GET_ALL_SITES_SUCCESS,
          payload: res.data.sites,
        });
      }
      dispatch({
        type: siteConstants.GET_ALL_SITES_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.GET_ALL_SITES_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};

export const getAllSitesWithReport = (month) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.GET_ALL_SITES_WITH_REPORT_REQUEST });

      if (!month) {
        month = new Date().getMonth();
      }

      let res = await axios.get(`/sites/get-sites-with-report?month=${month}`);

      if (res.status === 200) {
        return dispatch({
          type: siteConstants.GET_ALL_SITES_WITH_REPORT_SUCCESS,
          payload: res.data.sites,
        });
      }
      dispatch({
        type: siteConstants.GET_ALL_SITES_WITH_REPORT_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.GET_ALL_SITES_WITH_REPORT_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};

export const addSite = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.ADD_SITE_REQUEST });
      let res = await axios.post(`/sites/add`, value);
      if (res.status === 201) {
        console.log(res);
        return dispatch({
          type: siteConstants.ADD_SITE_SUCCESS,
          payload: res.data.site,
        });
      }
      dispatch({
        type: siteConstants.ADD_SITE_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.ADD_SITE_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};

export const deleteSite = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.DELETE_SITE_REQUEST });
      let res = await axios.delete(`/sites/delete/${id}`);
      if (res.status === 200) {
        console.log(res);
        dispatch({
          type: siteConstants.DELETE_SITE_SUCCESS,
        });
        return dispatch(getAllSites());
      }
      dispatch({
        type: siteConstants.DELETE_SITE_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.DELETE_SITE_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
export const updateSite = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.UPDATE_SITE_REQUEST });
      let res = await axios.put(`/sites/update/${id}`, value);
      if (res.status === 200) {
        return dispatch({
          type: siteConstants.UPDATE_SITE_SUCCESS,
        });
      }
      dispatch({
        type: siteConstants.UPDATE_SITE_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.UPDATE_SITE_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
export const getSiteById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: siteConstants.GET_SITE_BY_ID_REQUEST });
      let res = await axios.get(`/sites/get/${id}`);
      if (res.status === 200) {
        return dispatch({
          type: siteConstants.GET_SITE_BY_ID_SUCCESS,
          payload: res.data.site,
        });
      }
      dispatch({
        type: siteConstants.GET_SITE_BY_ID_FAILURE,
        payload: "Something went wrong",
      });
    } catch (err) {
      dispatch({
        type: siteConstants.GET_SITE_BY_ID_FAILURE,
        payload: "Something went wrong",
      });
    }
  };
};
