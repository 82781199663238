import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";

// layout
import SubDashboardLayout from "../../../components/SubDashboardLayout";

// actions
import { deleteDriver, getDrivers } from "../../../actions";
// components
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

const VendorDriverPage = (props) => {
  const dispatch = useDispatch();

  const Vendor = useSelector((state) => state.vendor);
  const loading = useSelector((state) => state.vendor.loading);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user._id) {
      dispatch(getDrivers(user._id));
    }
  }, [user._id]);

  const onDelete = (id) => {
    dispatch(deleteDriver(id, user._id));
  };
  return (
    <SubDashboardLayout
      title={"Vendor Dashboard"}
      operationName={`Drivers`}
      id={"vendorDriver"}
      to={"/vendors"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <td colSpan={8} className="filterField">
                <div className="filterContainer">
                  <div className="actionContainer">
                    <Button text="Create Driver" to="create" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Username</th>
              <th>Passowrd</th>
              <th>Signature</th>
              <th>Action</th>
            </tr>
            {Vendor?.drivers?.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Driver yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Vendor?.drivers?.map((driver, index) => (
                <tr key={driver._id}>
                  <td>{index + 1}</td>
                  <td>
                    {driver.firstName} {driver.lastName}
                  </td>
                  <td>{driver.userName}</td>
                  <td>{driver.password}</td>
                  <td>
                    {driver.signature && (
                      <div
                        style={{
                          padding: ".5rem 0",
                        }}
                      >
                        <img
                          src={driver.signature}
                          alt="signautre"
                          style={{
                            width: "100px",
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <div>
                      <Button
                        text="Update"
                        type="primary"
                        to={`update/${driver._id}`}
                      />
                      <Button
                        text="Delete"
                        type="danger"
                        onClick={() => onDelete(driver._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default VendorDriverPage;
