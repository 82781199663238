import React, { useEffect, useState } from "react";
import "./style.scss";

//icons
import { FaCheckCircle } from "react-icons/fa";

const Notification = (props) => {
  let { message, type, show = true } = props;
  let [showNotification, setShowNotification] = useState(false);

  const action = () => {
    setTimeout(() => {
      setShowNotification(false);
    }, 4000);
  };
  useEffect(() => {
    setShowNotification(show);
    action();
  }, [show]);
  const NotificationType = (type) => {
    switch (type) {
      case "success":
        return {
          borderColor: "green",
        };
      case "error":
        return {
          borderColor: "red",
        };
      default:
        return {
          borderColor: "blue",
        };
    }
  };

  return (
    <div
      className={`notificationContainer ${showNotification ? "visible" : ""}`}
      style={NotificationType(type)}
    >
      <FaCheckCircle size={25} className="icon" color="green" />
      <p className="notificationMessage">{message}</p>
    </div>
  );
};

export default Notification;
