import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";

// actions
import {
  getStudentsById,
  getParentById,
  AddParents,
  UpdateParent,
} from "../../actions";

// components
import Button from "../Button";
import Input from "../FormComponents/Input";
import Select from "../FormComponents/Select";
import Signature from "../FormComponents/Signature";
import Loader from "../Loader";
import Notification from "../Notification";

const ParentForm = (props) => {
  // props
  let { action, studentId, parentId } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const Student = useSelector((state) => state.student.student);
  const Parents = useSelector((state) => state.parent);
  const parentStatus = useSelector((state) => state.parent.status);
  const parentMsg = useSelector((state) => state.parent.message);
  const loading = useSelector((state) => state.parent.loading);

  const initialState = {
    firstName: "",
    lastName: "",
    userName: "",
    streetAptNo: "",
    city: "",
    zipcode: "",
    dayPhone: "",
    workPhone: "",
    ssn: "",
    password: "",
    racialCategory: "",
    ethnicCategory: "",
    signature: "",
    site: "",
    students: [],
  };

  // state
  const [formValue, setFormValues] = useState(initialState);
  const [show, setShow] = useState(false);

  // destructed props
  let {
    firstName,
    lastName,
    userName,
    streetAptNo,
    city,
    zipcode,
    dayPhone,
    workPhone,
    ssn,
    password,
    racialCategory,
    ethnicCategory,
    signature,
  } = formValue;

  // fetching data of parent and assigned students
  useEffect(() => {
    if (action === "Update") {
      setFormValues((prevState) => ({
        ...prevState,
        students: Student,
      }));
      return dispatch(getParentById(parentId));
    }
    dispatch(getStudentsById(studentId));
  }, [action, parentId, studentId]);

  useEffect(() => {
    if (action === "Update") {
      setFormValues(Parents.parent);
    }
  }, [Parents]);

  useEffect(() => {
    if (action === "Add") {
      setFormValues({ ...formValue, students: [Student] });
    }
  }, [Student]);

  const signCanvas = useRef({});

  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    // canvas to dataurl
    if (formValue.signature === "") {
      let sign = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      formValue.signature = sign;
    }

    if (action === "Add") {
      formValue.site = user._id;
      formValue.students = [studentId];
    }

    action === "Add"
      ? dispatch(AddParents({ ...formValue, studentId }))
      : dispatch(UpdateParent(formValue, parentId));
    if (action === "Add" && !loading) {
      // redirected to masterlist
      return navigate("/sites/master-list");
    }
  };

  const clear = (e) => {
    e.preventDefault();
    signCanvas.current.clear();
  };

  useEffect(() => {
    setShow(false);
    if (parentStatus === 200 || parentStatus === 201) {
      setShow(true);
    }
  }, [parentStatus]);

  // reset the fields if the action is create
  useEffect(() => {
    if (action === "Create") {
      setFormValues(initialState);
      setShow(false);
    }
  }, [action]);

  return (
    <div className="formContainer">
      <Notification show={show} type="success" message={parentMsg} />
      {loading && <Loader />}
      {!loading && (
        <form className="parentForm">
          <div className="twoFields">
            <Input
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={onChange}
            />
            <Input
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={onChange}
            />
          </div>
          <Input
            label="Street and apartment number"
            name="streetAptNo"
            value={streetAptNo}
            onChange={onChange}
          />
          <div className="twoFields">
            <Input label="City" name="city" value={city} onChange={onChange} />
            <Input
              label="Zip Code"
              name="zipcode"
              value={zipcode}
              onChange={onChange}
            />
          </div>
          <div className="twoFields">
            <Input
              label="Day Phone"
              name="dayPhone"
              value={dayPhone}
              onChange={onChange}
            />
            <Input
              label="Work Phone"
              name="workPhone"
              value={workPhone}
              onChange={onChange}
            />
          </div>
          <div className="twoFields">
            <Input
              label="Username"
              name="userName"
              value={userName}
              onChange={onChange}
              required
            />
            <Input
              type="password"
              label="password"
              name="password"
              value={password}
              onChange={onChange}
            />
          </div>
          <Input label="SSN" name="ssn" value={ssn} onChange={onChange} />
          <div className="twoFields">
            <Select
              label="Racial Categories"
              name="racialCategory"
              value={racialCategory}
              onChange={onChange}
            >
              <option value="">Select</option>
              <option value="AI">American Indian</option>
              <option value="AN">Alaska Native</option>
              <option value="AA">African American</option>
              <option value="A">Asian</option>
              <option value="B">Black</option>
              <option value="NH">Native Hawalian</option>
              <option value="PI">Pacific Islander</option>
              <option value="W">White</option>
            </Select>
            <Select
              label="Ethnic Category"
              name="ethnicCategory"
              value={ethnicCategory}
              onChange={onChange}
            >
              <option value="">Select</option>
              <option value="HL">Hispanic or Lation</option>
              <option value="NHL">Non-Hispanice or Lation</option>
            </Select>
          </div>
          <div className="assignedStudent">
            <div className="label">
              <b>Assigned Children</b>
            </div>
            <div className="label2">
              <b
                style={{
                  width: "50%",
                }}
              >
                Name
              </b>
              <b
                style={{
                  width: "50%",
                }}
              >
                Birth Date
              </b>
            </div>
            {formValue?.students?.map((student, index) => (
              <div className="student" key={index}>
                <p
                  style={{
                    width: "50%",
                  }}
                >{`${student.firstName} ${student.lastName}`}</p>
                <p
                  style={{
                    width: "50%",
                  }}
                >
                  {student.birthDate}
                </p>
              </div>
            ))}
          </div>
          {signature && (
            <div className="signature">
              <label>Parent's signature:</label>
              <div className="signatureContainer">
                <img src={formValue.signature} alt="signautre" />
              </div>
              <Button
                text="Remove Signature"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setFormValues((prevState) => ({
                    ...prevState,
                    signature: "",
                  }));
                }}
              />
            </div>
          )}
          {formValue.signature === "" && (
            <Signature clear={clear} canvas={signCanvas} />
          )}
          <Button
            text={
              loading
                ? `${action === "Update" ? "Updating" : "Adding"} information`
                : `${action} parent information`
            }
            type="primary"
            onClick={onSubmit}
          />
        </form>
      )}
    </div>
  );
};

export default ParentForm;
