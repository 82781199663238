import { invoiceConstants } from "../actions/constants";

const initialState = {
  deleteId: "",
  loading: false,
  error: null,
  message: "",
  status: 0,
  invoice: {
    siteName: "",
    orderNumber: 0,
    invoiceDate: "",
    invoiceItems: [
      {
        itemName: "",
        itemQuantity: 0,
        itemPrice: 0,
      },
    ],
  },
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case invoiceConstants.ADD_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case invoiceConstants.ADD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Invoice added",
        status: payload,
      };
    case invoiceConstants.ADD_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case invoiceConstants.GET_INVOICE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invoiceConstants.GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: payload,
      };
    case invoiceConstants.GET_INVOICE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case invoiceConstants.UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        status: 0,
        loading: true,
      };
    case invoiceConstants.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        message: "Updated successfully!",
      };
    case invoiceConstants.UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case invoiceConstants.DELETE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invoiceConstants.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Deleted successfully!",
        deletedId: payload.id,
      };
    case invoiceConstants.DELETE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};
