import {
  siteConstants,
  vendorConstants,
  userConstants,
} from "../actions/constants";

const initialState = {
  loading: false,
  error: null,
  message: "",
  users: [],
  userDeleted: {},
  vendors: [],
  sites: [],
  vendor: {
    vendorName: "",
    userName: "",
    password: "",
  },
  site: { siteName: "", userName: "", password: "" },
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case siteConstants.GET_ALL_SITES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.GET_ALL_SITES_SUCCESS:
      return {
        ...state,
        loading: false,
        sites: payload,
      };
    case siteConstants.GET_ALL_SITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case siteConstants.GET_ALL_SITES_WITH_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.GET_ALL_SITES_WITH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        sites: payload,
      };
    case siteConstants.GET_ALL_SITES_WITH_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case siteConstants.GET_SITE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.GET_SITE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        site: payload,
      };
    case siteConstants.GET_SITE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case vendorConstants.GET_ALL_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vendorConstants.GET_ALL_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendors: payload,
      };
    case vendorConstants.GET_ALL_VENDORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case vendorConstants.GET_VENDOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vendorConstants.GET_VENDOR_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        vendor: payload,
      };
    case vendorConstants.GET_VENDOR_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case vendorConstants.ADD_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vendorConstants.ADD_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case vendorConstants.ADD_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case userConstants.GET_ALL_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case userConstants.GET_ALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Error fetching admins",
      };
    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDeleted: payload,
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Error fetching admins",
      };
    default:
      return { ...state };
  }
};
