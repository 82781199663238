import "./style.scss";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import {
  FaArrowLeft,
  FaCrown,
  FaHotdog,
  FaListUl,
  FaRegClipboard,
  FaRegEdit,
  FaScroll,
} from "react-icons/fa";

const DashboardLayout = (props) => {
  let {
    id,
    DashboardTitle = "Not set yet",
    children,
    optionsLength = 6,
  } = props;

  if (!children) {
    children = [
      <Link to="create-order">
        <FaRegEdit size={25} />
        <span>Create Order</span>
      </Link>,
      <Link to="claims-report">
        <FaRegClipboard size={25} />
        <span>Claims Report</span>
      </Link>,
      <Link to="delivery-slip">
        <FaScroll size={25} />
        <span>Delivery Slip</span>
      </Link>,
      <Link to="master-list">
        <FaCrown size={25} />
        <span>Master List</span>
      </Link>,
      <Link to="meal-count">
        <FaHotdog size={25} />
        <span>Meal Count</span>
      </Link>,
      <Link to="menu">
        <FaListUl size={25} />
        <span>Menu</span>
      </Link>,
    ];
  }

  return (
    <section className="siteDashboard" id={id}>
      <div className="siteHeader container">
        {/* <Link to="/" className="backwardLink">
          <FaArrowLeft size={25} color="#636363" />
        </Link> */}
        <h1 className="title">{DashboardTitle}</h1>
      </div>
      <div className="dashboardMenus">
        <div
          className="links container"
          style={{
            gridTemplateColumns: `repeat(${optionsLength}, 1fr)`,
            gridGap: ".5rem",
          }}
        >
          {children}
        </div>
      </div>
      <Outlet />
    </section>
  );
};

export default DashboardLayout;
