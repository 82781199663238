import {
  authConstants,
  siteConstants,
  vendorConstants,
  driverConstants,
} from "../actions/constants";

const initialState = {
  user: {},
  type: "",
  authenticate: false,
  token: null,
  loading: false,
  error: null,
  message: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case authConstants.AUTH_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.user,
        token: payload.token,
        authenticate: true,
        type: "admin",
      };
    case authConstants.AUTH_SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case authConstants.AUTH_SUPER_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.AUTH_SUPER_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case authConstants.AUTH_SUPER_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        user: {},
      };
    case vendorConstants.VENDOR_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case vendorConstants.VENDOR_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticate: true,
        user: payload.user,
        token: payload.token,
        type: "vendor",
      };
    case vendorConstants.VENDOR_SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case siteConstants.SITE_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.SITE_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticate: true,
        user: payload.user,
        token: payload.token,
        type: "site",
      };
    case siteConstants.SITE_SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case driverConstants.DRIVER_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case driverConstants.DRIVER_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticate: true,
        user: payload.user,
        token: payload.token,
        type: "driver",
      };
    case driverConstants.DRIVER_SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case authConstants.GET_AUTHENTICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.GET_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.user,
        token: payload.token,
        type: payload.type,
        authenticate: true,
      };
    case authConstants.GET_AUTHENTICATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        token: null,
        authenticate: false,
      };
    case authConstants.LOGOUT_FAILURE:
      return {
        ...state,
        error: "Somethign went wrong wrong!",
        loading: false,
      };

    default:
      return { ...state };
  }
};
