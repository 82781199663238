import "./style.scss";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

// components
import Input from "../FormComponents/Input";
import Button from "../Button";
import Loader from "../Loader";

// icons
import { FaCheck } from "react-icons/fa";
import { searchStudents } from "../../actions";
import downloadPdfDocument from "../../helper/donwloadPdfDocument";

const MasterList = (props) => {
  let { students } = props;
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.student.loading);

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const download = () => downloadPdfDocument("masterlist");
  const onSearch = () => {
    if (user._id) {
      dispatch(searchStudents(search, user._id));
    }
  };

  return (
    <div className="masterListContainer">
      <div className="masterList">
        <h3 className="formTitle text-center">
          Child and Adult Care Food Program - MASTER LIST
        </h3>
        <p className="formDetails">
          Instructions: All sponsors are to keep a computerized or written
          Master List to record how each child is claimed monthly. Keep separate
          Master Lists per program type (i.e. child care center, Head Start,
          After School at Risk, etc.). We suggest listing the participants in
          alphabetical order on one center list or individual classroom lists in
          the same order as the classroom attendance list. Complete the columns
          for racial category and ethnic category (a visual identification can
          be made if the parent/guardian has not checked the racial and ethnic
          category on the income form), date enrollment form signed by parent,
          date income form signed by parent OR signed by sponsor (per option
          sponsor has selected on CRRS application), and income category taken
          from the income form. If participant was in the attendance at least
          once per month, include them on the Master List and insert the correct
          income category code (F, R, P) in the monthly column. If participant
          was not in attendance during the month, insert and “X” in the monthly
          column. Enter an “E” along with the income code in the column only for
          the month when participant first attends. Enter a “W” along with the
          income code in the column only for the month when the participant
          withdraws and stops attending at your center. Each month add all
          income categories (F’s, R’s, and P’s) at bottom of page(s) and submit
          on CRRS claim.
        </p>
        <div
          style={{
            overflowX: "auto",
          }}
        >
          <table className="table" id="masterlist" ref={componentRef}>
            <thead className="tableBody">
              <tr className="tableTop">
                <td colSpan={24}>
                  <div className="tableTopContainer">
                    <div className="searchContainer">
                      <Input
                        placeholder="Search Student"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button text="Search" onClick={onSearch} />
                      <Button
                        text="Clear Search"
                        onClick={(e) => setSearch("")}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      />
                    </div>
                    <div className="actionContainer">
                      <Button text="Create Students" to="../students/create" />
                      <Button
                        text="Download Master List Students"
                        onClick={download}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody ref={componentRef}>
              <tr className="labels">
                <th rowSpan={2} className="text-center">
                  No.
                </th>
                <th rowSpan={2} className="text-center">
                  PARTICIPANT NAMES
                </th>
                <th rowSpan={2} className="text-center">
                  * Racial <br />
                  Category <br />
                  Code
                </th>
                <th colSpan={2} className="text-center">
                  Ethnic Category
                </th>
                <th className="text-center">
                  If applicable <br /> to program
                </th>
                <th className="text-center">
                  Option selected on <br />
                  CRRS application
                </th>
                <th colSpan={3} className="text-center">
                  Income Category
                </th>

                <th rowSpan={2}>
                  <p className="rotateText">Oct</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Nov</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Dec</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Jan</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Feb</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Mar</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Apr</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">May</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText"> Jun</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Jul</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Aug</p>
                </th>
                <th rowSpan={2}>
                  <p className="rotateText">Sept</p>
                </th>
                <th rowSpan={2}></th>
                <th rowSpan={2}></th>
              </tr>
              <tr className="labels2">
                <th>
                  <p
                    className="rotateText text-center"
                    style={{
                      minWidth: "70px",
                    }}
                  >
                    Hispanic or <br /> Latino
                  </p>
                </th>
                <th>
                  <p
                    className="rotateText text-center"
                    style={{
                      minWidth: "95px",
                    }}
                  >
                    Non-Hispanic or <br /> Latino
                  </p>
                </th>
                <th className="text-center">
                  Date Enrollment Form Signed by Parent
                </th>
                <th className="text-center">
                  Date Income <br />
                  Form signed by <br />
                  Parent
                  <br />
                  OR <br />
                  Signed & <br />
                  Certified by <br />
                  Sponsor
                </th>
                <th>FR</th>
                <th>RD</th>
                <th>PD</th>
              </tr>
              {students.length === 0 && !loading ? (
                <tr>
                  <td className="text-center" colSpan={30}>
                    <h2>No students</h2>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {loading && (
                <tr>
                  <td colSpan={24}>
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading &&
                students.map((student, index) => (
                  <tr className="masterListData" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{`${student.firstName}  ${student.lastName}`}</td>
                    <td className="text-center">{student.racialCategory}</td>
                    <td className="text-center">
                      {student.ethnicCategory === "HL" && <FaCheck />}
                    </td>
                    <td className="text-center">
                      {student.ethnicCategory === "NHL" && <FaCheck />}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {student.enrollmentDate}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {student.signingDate}
                    </td>
                    <td className="text-center">
                      {student.incomeCategory === "FR" && <FaCheck />}
                    </td>
                    <td className="text-center">
                      {student.incomeCategory === "RD" && <FaCheck />}
                    </td>
                    <td className="text-center">
                      {student.incomeCategory === "PD" && <FaCheck />}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Button
                        text="Update"
                        to={`../students/update/${student._id}`}
                      />
                    </td>
                    <td>
                      {student.parent ? (
                        <div className="actionContainer">
                          <Button
                            type="primary"
                            text="Update parent/guardian Info"
                            to={`../parents/update/${student.parent}`}
                          />
                          <Button
                            type="primary"
                            text="Enrollment Form"
                            to={`/sites/enrollment-form/${student._id}`}
                          />
                        </div>
                      ) : (
                        <div className="actionContainer">
                          <Button
                            type="secondary"
                            text="Add parent/guardian Info"
                            to={`../parents/create?studentId=${student._id}`}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot className="tableFooter" ref={componentRef}>
              <tr>
                <th
                  colSpan={5}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  * RACIAL CATEGORY CODES
                </th>
                <td
                  colSpan={2}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  <b>B/AA</b>= Black or African American
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  Total Fee<b>[F]</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  <b>AI/AN</b>= American Indian or Alaska
                </td>
                <td
                  colSpan={2}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  <b>NH/PI</b>= Native Hawaiian or Pacific Islander
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  Total Reduced<b>[R]</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  <b>A</b>= Asian
                </td>
                <td
                  colSpan={2}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  <b>W</b>= White
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "3px solid black",
                  }}
                >
                  Total Paid<b>[P]</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MasterList;
