import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

// actions
import { getAllOrders } from "../../actions";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";

// components
import Button from "../../components/Button";
import Loader from "../../components/Loader";

const OrderPage = (props) => {
  const dispatch = useDispatch();

  const Orders = useSelector((state) => state.order.orders);
  const loading = useSelector((state) => state.order.loading);
  // const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);

  useEffect(() => {
    if (userType === "admin") {
      dispatch(getAllOrders());
    }
  }, [userType]);

  return (
    <SubDashboardLayout
      title={"Admin"}
      operationName={`Orders`}
      id={"orderDashboard"}
      to={"/admin/dashboard"}
    >
      <div className="tableContainer">
        <table>
          <tbody>
            <tr>
              <td colSpan={8} className="filterField">
                <div className="filterContainer">
                  <div className="actionContainer">
                    <Button text="Create Order" to="create" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>#</th>
              <th>Meal Type</th>
              <th>Meals Required</th>
              <th>Site</th>
              <th>vendor</th>
              <th>Comments</th>
              <th>Created At</th>
              <th>Status</th>
            </tr>
            {Orders?.length < 1 && !loading ? (
              <tr>
                <td colSpan={8}>No Orders yet</td>
              </tr>
            ) : (
              <></>
            )}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              Orders?.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order.mealType}</td>
                  <td>{order.mealRequired}</td>
                  <td>{order?.site?.siteName}</td>
                  <td>{order.vendor.vendorName}</td>
                  <td>{order.comment}</td>
                  <td>{format(new Date(order.createdAt), "dd-MM-yyyy")}</td>
                  <td>{order.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SubDashboardLayout>
  );
};

export default OrderPage;
