import { deliverySlipConstants } from "../actions/constants";

const initialState = {
  deletedId: "",
  deliverySlips: [],
  status: 0,
  deliverySlip: {
    vendor: "",
    site: "",
    deliveryDate: "",
    mealType: "",
    mealsOrdered: 0,
    mealsDelivered: 0,
    milkDelivery: {
      fatfree: {
        fourOzQuantity: 0,
        eightOzQuantity: 0,
        halfGallonQuantity: 0,
        gallonQuantity: 0,
      },
      lowfat: {
        fourOzQuantity: 0,
        eightOzQuantity: 0,
        halfGallonQuantity: 0,
        gallonQuantity: 0,
      },
      wholeMilk: {
        fourOzQuantity: 0,
        eightOzQuantity: 0,
        halfGallonQuantity: 0,
        gallonQuantity: 0,
      },
    },
    vendorRepresentative: "",
    deliveryTime: "",
    problems: "",
  },
  foodItems: [
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
    {
      items: "",
      quantity: 0,
      deliveryType: 2,
      state1: {
        temp: "",
        time: "",
      },
      state2: {
        temp: "",
        time: "",
      },
      state3: {
        temp: "",
        time: "",
      },
    },
  ],
  loading: false,
  error: null,
  message: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case deliverySlipConstants.GET_DELIVERY_SLIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverySlipConstants.GET_DELIVERY_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        deliverySlips: payload,
      };
    case deliverySlipConstants.GET_DELIVERY_SLIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Delivery slips error",
      };
    case deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        status: 0,
      };
    case deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        deliverySlip: payload.deliverySlip,
        foodItems: payload.foodItems,
      };
    case deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Delivery slips error",
      };
    case deliverySlipConstants.CREATE_DELIVERY_SLIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverySlipConstants.CREATE_DELIVERY_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        status: payload,
        message: "Created successfully",
      };
    case deliverySlipConstants.CREATE_DELIVERY_SLIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Delivery slips error",
      };
    case deliverySlipConstants.UPDATE_DELIVERY_SLIP_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case deliverySlipConstants.UPDATE_DELIVERY_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        status: 200,
      };
    case deliverySlipConstants.UPDATE_DELIVERY_SLIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Delivery slips error",
      };
    case deliverySlipConstants.DELETE_DELIVERY_SLIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deliverySlipConstants.DELETE_DELIVERY_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        deletedId: payload.id,
      };
    case deliverySlipConstants.DELETE_DELIVERY_SLIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Delivery slips error",
      };
    default:
      return { ...state };
  }
};
