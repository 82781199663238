import axios from "axios";
import store from "../Store";
// let baseURL = process.env.REACT_APP_BASE_API;
let baseURL = "https://server.cacfpohio.com";
let token = window.localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/v1/`,
  header: {
    Authorization: token ? `Beaerer ${token}` : "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }
  return req;
});

export default axiosInstance;
