import { deliverySlipConstants } from ".";
import axios from "../helper/axios";

export const getDeliverySlips = (vendorId, driverId, siteId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: deliverySlipConstants.GET_DELIVERY_SLIP_REQUEST });
      let res;
      if (!vendorId && !driverId && !siteId) {
        res = await axios.get("/deliverySlip/all");
      }
      if (vendorId) {
        res = await axios.get(`/deliverySlip/all?vendorId=${vendorId}`);
      }
      if (driverId) {
        res = await axios.get(`/deliverySlip/all?driverId=${driverId}`);
      }
      if (siteId) {
        res = await axios.get(`/deliverySlip/all?siteId=${siteId}`);
      }

      if (res.status === 200) {
        return dispatch({
          type: deliverySlipConstants.GET_DELIVERY_SLIP_SUCCESS,
          payload: res.data.deliverySlips,
        });
      }

      dispatch({
        type: deliverySlipConstants.GET_DELIVERY_SLIP_FAILURE,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: deliverySlipConstants.GET_DELIVERY_SLIP_FAILURE,
      });
    }
  };
};

export const getDeliverySlipById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_REQUEST });
      let res = await axios.get(`/deliverySlip/get/${id}`);
      if (res.status === 200) {
        return dispatch({
          type: deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_SUCCESS,
          payload: {
            deliverySlip: res.data.deliverySlip,
            foodItems: res.data.foodItems,
          },
        });
      }
      dispatch({
        type: deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_FAILURE,
        payload: "Something went wrong fetching slip",
      });
    } catch (err) {
      dispatch({
        type: deliverySlipConstants.GET_DELIVERY_SLIP_BY_ID_FAILURE,
        payload: "Something went wrong fetching slip",
      });
    }
  };
};

export const CreateDeliverySlip = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: deliverySlipConstants.CREATE_DELIVERY_SLIP_REQUEST });
      let res = await axios.post(`/deliverySlip/create`, value);
      if (res.status === 201) {
        return dispatch({
          type: deliverySlipConstants.CREATE_DELIVERY_SLIP_SUCCESS,
          payload: res.status,
        });
      }
      dispatch({
        type: deliverySlipConstants.CREATE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong creating slip",
      });
    } catch (err) {
      dispatch({
        type: deliverySlipConstants.CREATE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong creating slip",
      });
    }
  };
};
export const updateDeliverySlip = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: deliverySlipConstants.UPDATE_DELIVERY_SLIP_REQUEST });
      let res = await axios.put(`/deliverySlip/update/${id}`, value);
      if (res.status === 200) {
        return dispatch({
          type: deliverySlipConstants.UPDATE_DELIVERY_SLIP_SUCCESS,
          payload: "Updated successfully",
        });
      }
      dispatch({
        type: deliverySlipConstants.UPDATE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong updating slip",
      });
    } catch (err) {
      dispatch({
        type: deliverySlipConstants.UPDATE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong updating slip",
      });
    }
  };
};

export const deleteDeliverySlip = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: deliverySlipConstants.DELETE_DELIVERY_SLIP_REQUEST });
      let res = await axios.delete(`/deliverySlip/delete/${id}`);
      if (res.status === 200) {
        return dispatch({
          type: deliverySlipConstants.DELETE_DELIVERY_SLIP_SUCCESS,
          payload: res.data,
        });
      }
      dispatch({
        type: deliverySlipConstants.DELETE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong deleting slip",
      });
    } catch (err) {
      dispatch({
        type: deliverySlipConstants.DELETE_DELIVERY_SLIP_FAILURE,
        payload: "Something went wrong deleting slip",
      });
    }
  };
};
