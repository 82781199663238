import "./style.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// action
import { getAllStudentsWithMealBySite } from "../../actions/student.action";

// helper
import getWeek from "../../helper/getWeek.js";

// component
// import Select from "../FormComponents/Select";
import Input from "../FormComponents/Input";
import TableData from "../TableData";
import Loader from "../Loader";
import { getMealCount } from "../../actions";

const MealCountList = (props) => {
  // props
  let { site } = props;
  // state

  const [filterDate, setFilterDate] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() < 10 ? "0" : ""}${
      new Date().getMonth() + 1
    }-${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}`
  );
  const [weeks, setWeeks] = useState([]);
  const [mealCount, setMealCount] = useState([]);
  const [weekMealCount, setWeekMealCount] = useState({});

  useEffect(() => {
    let date = getWeek(filterDate);

    setWeeks(date);
  }, [filterDate]);

  const dispatch = useDispatch();
  const Student = useSelector((state) => state.student);
  const loading = useSelector((state) => state.student.loading);
  const user = useSelector((state) => state.auth.user);

  // meal
  const mealAdded = useSelector((state) => state.meal.mealAdded);
  const weekMealSum = useSelector((state) => state.meal.weekMealSum);
  const totalMealSum = useSelector((state) => state.meal.totalMealSum);

  useEffect(() => {
    if (weeks.length > 0) {
      dispatch(getAllStudentsWithMealBySite(user._id, weeks));
    }
  }, [weeks]);

  useEffect(() => {
    if (weeks.length > 0) {
      dispatch(getMealCount(user._id, weeks));
    }
  }, [weeks, mealAdded]);

  useEffect(() => {
    setWeekMealCount(weekMealSum);
    setMealCount(totalMealSum);
  }, [totalMealSum, weekMealSum]);

  const dayId = [1, 2, 3, 4, 5];

  return (
    <div className="mealCountListContainer">
      <div className="mealCountList">
        <h3 className="formTitle text-center">
          CHILDREN'S MEAL COUNT BY INDIVIDUAL NAME FORM
        </h3>
        <p className="formSubTitle text-center">(Use Other Form for Infants)</p>
        <ul className="formRules">
          <li>
            To be used by sponsors who are approved to serve more than 2 meals
            and 1 snack OR 2 snacks and 1 meal per day. Also for sponsors who
            may be <br /> requested to record meal counts by individual child.
          </li>
          <li>
            Record all creditable meals served to each child for the day by
            entering an “X”. Before submitting the monthly claim, center
            administration must circle the
            <br /> meal/snack that will not be claimed if the total served to
            the child is over 2 meals and 1 snack OR 2 snacks and 1 meal.
          </li>
        </ul>
        <div
          style={{
            overflowX: "auto",
            paddingBottom: ".5rem",
          }}
        >
          <table className="table">
            <tbody className="tableBody">
              <tr className="mealCountHeader">
                <td rowSpan={2} className="mealDetails">
                  <div className="date">
                    <b>Date: </b>
                    <Input
                      type="date"
                      value={filterDate}
                      onChange={(e) => setFilterDate(e.target.value)}
                    />
                  </div>
                  {/* <div className="classroom">
                    <b>Class Room: </b>
                    <Select>
                      <option>All ages</option>
                      <option>Toddlers</option>
                      <option>Pre-School</option>
                      <option>School</option>
                      <option>Infants (12-18 Months)</option>
                      <option>Infants (0-11 Months)</option>
                    </Select>
                  </div> */}
                  {/* <p className="teacherName">
                    <b>Teacher:</b> John
                  </p> */}
                  <p className="siteName">
                    <b>Site:</b> {site}
                  </p>
                  <div className="childName">CHILD’S NAME</div>
                </td>
                <th colSpan={6}>
                  MONDAY
                  <br />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                  >
                    {weeks[0]}
                  </p>
                </th>
                <th colSpan={6}>
                  TUESDAY
                  <br />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                  >
                    {weeks[1]}
                  </p>
                </th>
                <th colSpan={6}>
                  WEDNESDAY
                  <br />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                  >
                    {weeks[2]}
                  </p>
                </th>
                <th colSpan={6}>
                  THURSDAY
                  <br />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                  >
                    {weeks[3]}
                  </p>
                </th>
                <th colSpan={6}>
                  FRIDAY
                  <br />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 400,
                    }}
                  >
                    {weeks[4]}
                  </p>
                </th>
              </tr>
              <tr className="mealCountHeader2">
                <th className="breakfast">
                  <p className="rotateText">Breakfast</p>
                </th>
                <th className="AMSnack">
                  <p className="rotateText">AM Snack</p>
                </th>
                <th className="Lunch">
                  <p className="rotateText">Lunch</p>
                </th>
                <th className="PMSnack">
                  <p className="rotateText">PM Snack</p>
                </th>
                <th className="Supper">
                  <p className="rotateText">Supper</p>
                </th>
                <th className="EveningSnack">
                  <p className="rotateText">Evening Snack</p>
                </th>
                <th className="breakfast">
                  <p className="rotateText">Breakfast</p>
                </th>
                <th className="AMSnack">
                  <p className="rotateText">AM Snack</p>
                </th>
                <th className="Lunch">
                  <p className="rotateText">Lunch</p>
                </th>
                <th className="PMSnack">
                  <p className="rotateText">PM Snack</p>
                </th>
                <th className="Supper">
                  <p className="rotateText">Supper</p>
                </th>
                <th className="EveningSnack">
                  <p className="rotateText">Evening Snack</p>
                </th>
                <th className="breakfast">
                  <p className="rotateText">Breakfast</p>
                </th>
                <th className="AMSnack">
                  <p className="rotateText">AM Snack</p>
                </th>
                <th className="Lunch">
                  <p className="rotateText">Lunch</p>
                </th>
                <th className="PMSnack">
                  <p className="rotateText">PM Snack</p>
                </th>
                <th className="Supper">
                  <p className="rotateText">Supper</p>
                </th>
                <th className="EveningSnack">
                  <p className="rotateText">Evening Snack</p>
                </th>
                <th className="breakfast">
                  <p className="rotateText">Breakfast</p>
                </th>
                <th className="AMSnack">
                  <p className="rotateText">AM Snack</p>
                </th>
                <th className="Lunch">
                  <p className="rotateText">Lunch</p>
                </th>
                <th className="PMSnack">
                  <p className="rotateText">PM Snack</p>
                </th>
                <th className="Supper">
                  <p className="rotateText">Supper</p>
                </th>
                <th className="EveningSnack">
                  <p className="rotateText">Evening Snack</p>
                </th>
                <th className="breakfast">
                  <p className="rotateText">Breakfast</p>
                </th>
                <th className="AMSnack">
                  <p className="rotateText">AM Snack</p>
                </th>
                <th className="Lunch">
                  <p className="rotateText">Lunch</p>
                </th>
                <th className="PMSnack">
                  <p className="rotateText">PM Snack</p>
                </th>
                <th className="Supper">
                  <p className="rotateText">Supper</p>
                </th>
                <th className="EveningSnack">
                  <p className="rotateText">Evening Snack</p>
                </th>
              </tr>
              {Student.students.length < 1 && !loading ? (
                <tr>
                  <td
                    colSpan={31}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Students
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {Student.students.length > 0 &&
                Student.students.map((student, index) => (
                  <tr className="mealData" key={index}>
                    <td>{`${student.firstName} ${student.lastName}`}</td>
                    {dayId.map((day, index) => (
                      <React.Fragment key={index}>
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          day={day}
                          mealTypeId={1}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.breakfast
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          mealTypeId={2}
                          day={day}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.amSnack
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          mealTypeId={3}
                          day={day}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.lunch
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          mealTypeId={4}
                          day={day}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.pmSnack
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          mealTypeId={5}
                          day={day}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.supper
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                        <TableData
                          student_id={student._id}
                          dates={weeks}
                          mealTypeId={6}
                          day={day}
                          defValue={
                            student.meals?.length > 0
                              ? student.meals[day - 1]?.eveningSnack
                              : 0
                          }
                          site={student.site}
                          // clicked={setClicked}
                        />
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              <tr className="dailyMealCount">
                <th>DAILY MEAL COUNT TOTALS</th>
                {dayId?.map((day, index) => (
                  <React.Fragment key={index}>
                    <td className="breakfast">
                      {mealCount?.length > 0
                        ? mealCount[day - 1]?.breakfast
                        : 0}
                    </td>
                    <td className="AMSnack">
                      {mealCount?.length > 0 ? mealCount[day - 1]?.amSnack : 0}
                    </td>
                    <td className="Lunch">
                      {mealCount?.length > 0 ? mealCount[day - 1]?.lunch : 0}
                    </td>
                    <td className="PMSnack">
                      {mealCount?.length > 0 ? mealCount[day - 1]?.pmSnack : 0}
                    </td>
                    <td className="Supper">
                      {mealCount?.length > 0 ? mealCount[day - 1]?.supper : 0}
                    </td>
                    <td className="EveningSnack">
                      {mealCount?.length > 0
                        ? mealCount[day - 1]?.eveningSnack
                        : 0}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
              <tr className="dummy">
                <th colSpan={31}></th>
              </tr>
            </tbody>
            <tfoot className="tableFooter">
              <tr>
                <th>
                  {" "}
                  MEAL COUNT <br /> TOTALS FOR <br /> PAGE (WEEK)
                </th>
                <td colSpan={5}>
                  <div>
                    <b>Breakfast</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.breakfast
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div>
                    <b>AM SNACK</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.amSnack
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div>
                    <b>LUNCH</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.lunch
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div>
                    <b>PM SNACK</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.pmSnack
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div>
                    <b>SUPPER</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.supper
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div>
                    <b>EVENING SNACK</b>
                    <span>
                      {loading ? (
                        <Loader />
                      ) : weekMealCount ? (
                        weekMealCount.eveningSnack
                      ) : (
                        0
                      )}
                    </span>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MealCountList;
