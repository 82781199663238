import "./style.scss";
import React from "react";
// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";
import MenuList from "../../components/MenuList";
import { useSelector } from "react-redux";

const MenuPage = () => {
  const type = useSelector((state) => state.auth.type);
  const loading = useSelector((state) => state.menu.loading);

  return (
    <SubDashboardLayout
      title={type}
      operationName="Menu"
      id="menu"
      loading={loading}
    >
      <MenuList />
    </SubDashboardLayout>
  );
};

export default MenuPage;
