import { useEffect, useState } from "react";
import "./style.scss";

const Select = (props) => {
  let { children, required, label, name, value, placeholder } = props;
  const [error, setError] = useState("");

  const onBlur = () => {
    if (required && value === "") {
      setError(`${label ? label : placeholder} required!`);
    }
  };

  useEffect(() => {
    if (value !== "") {
      setError("");
    }
  }, [value]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: ".5rem" }}
    >
      {label && (
        <label
          style={{
            fontSize: ".9rem",
            padding: ".3rem .2rem",
          }}
        >
          {label}
        </label>
      )}
      <select
        className="selectInput"
        required={required}
        name={name}
        {...props}
        onBlur={onBlur}
      >
        {children}
      </select>
      {error && (
        <span
          style={{
            fontSize: ".9rem",
            padding: ".3rem .2rem",
            color: "#dd3f3f",
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
