import { mealConstants } from "../actions/constants";

const initialState = {
  totalMealSum: [],
  weekMealSum: {},
  loading: false,
  error: null,
  message: "",
  mealAdded: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case mealConstants.ADD_MEAL_REQUEST:
      return {
        ...state,
      };
    case mealConstants.ADD_MEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Meal updated succesfully",
        mealAdded: payload,
      };
    case mealConstants.ADD_MEAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong adding the meal!",
      };
    case mealConstants.GET_MEAL_COUNT_REQUEST:
      return {
        ...state,
      };
    case mealConstants.GET_MEAL_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Meal updated succesfully",
        totalMealSum: payload.totalMealSum,
        weekMealSum: payload.weekMealSum[0],
      };
    case mealConstants.GET_MEAL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong adding the meal!",
      };
    default:
      return { ...state };
  }
};
