import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// actions
import { addVendor, getVendorById, updateVendor } from "../../actions";
import "./style.scss";
// layout
import SubDashboardLayout from "../SubDashboardLayout";
// component
import Button from "../Button";
import Input from "../FormComponents/Input";
import Notification from "../Notification";
import Loader from "../Loader";

const VendorForm = (props) => {
  let { vendorId } = useParams();
  let { action } = props;
  const Admin = useSelector((state) => state.admin);
  const loading = useSelector((state) => state.vendor.loading);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formValue, setFormValues] = useState({
    vendorName: "",
    userName: "",
    password: "",
  });
  let { vendorName, userName, password } = formValue;

  useEffect(() => {
    if (action === "Update") {
      return dispatch(getVendorById(vendorId));
    }
  }, [action]);

  useEffect(() => {
    if (action === "Update") {
      setFormValues({
        vendorName: Admin.vendor.vendorName,
        userName: Admin.vendor.userName,
        password: "",
      });
    }
  }, [Admin.vendor]);

  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    action === "Add"
      ? dispatch(addVendor(formValue))
      : dispatch(updateVendor(vendorId, formValue));
    if (!Admin.loading && !Admin.error) {
      if (action === "Add") {
        setFormValues({
          vendorName: "",
          userName: "",
          password: "",
        });
      }
      setShow(true);
    }
  };
  return (
    <SubDashboardLayout
      title={"Admin"}
      operationName={`${action} Vendor`}
      id={"vendorForm"}
      to={"../"}
      loading={Admin.loading}
    >
      <Notification
        message={action === "Add" ? "Vendor Created" : "Vendor Updated"}
        type={"success"}
        show={show}
      />
      <div className="formContainer">
        {loading && <Loader />}
        {!loading && (
          <form className="vendorForm">
            <Input
              label="Vendor name"
              placeholder="Vendor Name"
              name="vendorName"
              value={vendorName}
              onChange={onChange}
            />
            <Input
              label="Username"
              placeholder="username"
              name="userName"
              value={userName}
              onChange={onChange}
            />

            <Input
              label="Password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={onChange}
            />

            <Button
              text={
                loading
                  ? `${action === "Update" ? "Updating" : "Adding"} Vendor`
                  : `${action} Vendor`
              }
              type="primary"
              onClick={onSubmit}
            />
          </form>
        )}
      </div>
    </SubDashboardLayout>
  );
};

export default VendorForm;
