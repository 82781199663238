import { studentConstants } from "../actions/constants";

const initialState = {
  students: [],
  totalMealSum: [],
  weekMealSum: {},
  enrollmentForm: {
    student: {
      fullName: "",
      age: 0,
      birthDate: "",
      site: {
        siteName: "",
      },
    },
    days: [
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Monday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Tuesday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Wednesday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Thursday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Friday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Saturday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
      {
        arrive1: "",
        depart1: "",
        arrive2: "",
        depart2: "",
        amSnack: false,
        breakfast: false,
        eveningSnack: false,
        lunch: false,
        name: "Sunday",
        pmSnack: false,
        supper: false,
        tick: false,
      },
    ],
    parent: {
      signature: "",
      streetAptNo: "",
      zipcode: "",
      dayPhone: "",
      createdAt: "",
    },
    vary: false,
  },
  student: {
    firstName: "",
    lastName: "",
    birthDate: "",
    enrollmentDate: "",
    signingDate: "",
    incomeCategory: "",
    racialCategory: "",
    ethnicCategory: "",
    siteName: "",
  },
  loading: false,
  error: null,
  message: "",
  status: 0,
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case studentConstants.GET_STUDENTS_BY_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case studentConstants.GET_STUDENTS_BY_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: payload,
      };
    case studentConstants.GET_STUDENTS_BY_SITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case studentConstants.GET_STUDENTS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        status: 0,
      };
    case studentConstants.GET_STUDENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        student: payload,
      };
    case studentConstants.GET_STUDENTS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case studentConstants.ADD_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
        message: "",
      };
    case studentConstants.ADD_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: payload,
        message: "Student added",
      };
    case studentConstants.ADD_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case studentConstants.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case studentConstants.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        student: payload,
        message: "Student updated",
        status: 200,
      };
    case studentConstants.UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: payload.students,
      };
    case studentConstants.GET_STUDENTS_WITH_MEAL_BY_SITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Something went wrong!",
      };
    case studentConstants.GET_STUDENT_ENROLLMENT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case studentConstants.GET_STUDENT_ENROLLMENT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollmentForm: payload,
      };
    case studentConstants.GET_STUDENT_ENROLLMENT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollmentForm: payload,
      };
    case studentConstants.UPDATE_STUDENT_ENROLLMENT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};
