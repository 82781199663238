import "./style.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getAllSites, getAllVendors, CreateOrder } from "../../actions";

// layout
import SubDashboardLayout from "../../components/SubDashboardLayout";

// component
import Select from "../../components/FormComponents/Select";
import Input from "../../components/FormComponents/Input";
import TextArea from "../../components/FormComponents/TextArea";
import Button from "../Button";
import Loader from "../Loader";
import Notification from "../Notification";

const OrderForm = (props) => {
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.type);
  // to get all vendors
  const admin = useSelector((state) => state.admin);
  const loading = useSelector((state) => state.order.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVendors());
  }, []);

  useEffect(() => {
    if (userType === "admin") {
      dispatch(getAllSites());
    }
  }, [userType]);

  const [show, setShow] = useState(false);
  const [formValue, setFormValues] = useState({
    mealType: "",
    vendor: "",
    mealRequired: 1,
    comment: "",
    site: "",
  });

  let { mealType, mealRequired, comment, vendor, site } = formValue;

  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const Meal = useSelector((state) => state.meal);

  const onSubmit = (e) => {
    e.preventDefault();
    if (userType !== "admin") {
      formValue.site = user._id;
    }
    dispatch(CreateOrder(formValue));
    if (!Meal.loading && !Meal.error) {
      setFormValues({
        mealType: "",
        vendor: "",
        mealRequired: 1,
        comment: "",
        site: "",
      });
      setShow(true);
    }
  };
  return (
    <SubDashboardLayout
      title={user.siteName || "Admin"}
      operationName="Create Order"
      id="SiteCreateOrder"
    >
      <Notification message={"Order Created"} type={"success"} show={show} />
      <div className="formContainer">
        {loading && <Loader />}
        {!loading && (
          <form className="createOrderForm">
            <Select
              label="Meal Type"
              name="mealType"
              required
              value={mealType}
              onChange={onChange}
            >
              <option value="">Select</option>
              <option value="Breakfast">Breakfast</option>
              <option value="AM Snack">AM Snack</option>
              <option value="Lunch">Lunch</option>
              <option value="PM Snack">PM Snack</option>
              <option value="Supper">Supper</option>
            </Select>
            {userType === "admin" && (
              <Select
                label="Sites"
                name="site"
                required
                value={site}
                onChange={onChange}
              >
                <option value="">Select</option>
                {admin.sites.map((site, index) => (
                  <option value={site._id} key={index}>
                    {site.siteName}
                  </option>
                ))}
              </Select>
            )}
            <Select
              label="Vendors"
              name="vendor"
              required
              value={vendor}
              onChange={onChange}
            >
              <option value="">Select</option>
              {admin.vendors.map((vendor, index) => (
                <option value={vendor._id} key={index}>
                  {vendor.vendorName}
                </option>
              ))}
            </Select>

            <Input
              type="number"
              label="Meal Required"
              placeholder="Quantity"
              name="mealRequired"
              value={mealRequired}
              onChange={onChange}
            />
            <TextArea
              label="Comment"
              name="comment"
              value={comment}
              onChange={onChange}
            />
            <Button text={"Create Order"} type="primary" onClick={onSubmit} />
          </form>
        )}
      </div>
    </SubDashboardLayout>
  );
};

export default OrderForm;
