import axios from "../helper/axios";
import { orderConstants } from "./constants";

export const CreateOrder = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.CREATE_ORDER_REQUEST });
      let res = await axios.post(`/orders/create`, value);
      if (res.status === 201) {
        return dispatch({
          type: orderConstants.CREATE_ORDER_SUCCESS,
        });
      }
      dispatch({ type: orderConstants.CREATE_ORDER_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.CREATE_ORDER_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getAllOrders = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.GET_ALL_ORDERS_REQUEST });
      let res = await axios.get(`/orders/get/all`);
      if (res.status === 200) {
        return dispatch({
          type: orderConstants.GET_ALL_ORDERS_SUCCESS,
          payload: res.data.orders,
        });
      }
      dispatch({ type: orderConstants.GET_ALL_ORDERS_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ALL_ORDERS_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getOrdersByVendor = (vendor) => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.GET_ORDERS_BY_VENDOR_REQUEST });
      let res = await axios.get(`/orders/get/${vendor}`);
      if (res.status === 200) {
        return dispatch({
          type: orderConstants.GET_ORDERS_BY_VENDOR_SUCCESS,
          payload: res.data.orders,
        });
      }
      dispatch({ type: orderConstants.GET_ORDERS_BY_VENDOR_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ORDERS_BY_VENDOR_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getOrdersByDriver = (driver) => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.GET_ORDERS_BY_DRIVER_REQUEST });
      let res = await axios.get(`/orders/get/driver/${driver}`);
      if (res.status === 200) {
        return dispatch({
          type: orderConstants.GET_ORDERS_BY_DRIVER_SUCCESS,
          payload: res.data.orders,
        });
      }
      dispatch({ type: orderConstants.GET_ORDERS_BY_DRIVER_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ORDERS_BY_DRIVER_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const getOrdersBySite = (site) => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.GET_ORDERS_BY_SITE_REQUEST });
      let res = await axios.get(`/orders/get/site/${site}`);
      if (res.status === 200) {
        return dispatch({
          type: orderConstants.GET_ORDERS_BY_SITE_SUCCESS,
          payload: res.data.orders,
        });
      }
      dispatch({ type: orderConstants.GET_ORDERS_BY_SITE_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.GET_ORDERS_BY_SITE_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const updateOrder = (orderId, value, vendorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: orderConstants.UPDATE_ORDER_REQUEST });
      let res = await axios.put(`/orders/update/${orderId}`, value);
      if (res.status === 200) {
        return dispatch({
          type: orderConstants.UPDATE_ORDER_SUCCESS,
          payload: res.data.updatedOrder,
        });
      }
      dispatch({ type: orderConstants.UPDATE_ORDER_FAILURE });
    } catch (err) {
      dispatch({
        type: orderConstants.UPDATE_ORDER_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};
