import { siteConstants } from "../actions/constants";

const initialState = {
  loading: false,
  error: null,
  message: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case siteConstants.ADD_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.ADD_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case siteConstants.ADD_SITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case siteConstants.UPDATE_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteConstants.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Updated Successfully",
      };
    case siteConstants.UPDATE_SITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Update was not successful",
      };
    default:
      return { ...state };
  }
};
