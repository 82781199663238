import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DriverPrivate() {
  const authenticated = useSelector((state) => state.auth.authenticate);
  const userType = useSelector((state) => state.auth.type);
  const hasAccess = authenticated && userType === "driver";

  return hasAccess ? <Outlet /> : <Navigate to="/" />;
}
