import axios from "../helper/axios";
import { parentConstants } from "./constants";

export const getParentById = (parentId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: parentConstants.GET_PARENT_BY_ID_REQUEST });
      let res = await axios.get(`/parents/get/${parentId}`);
      if (res.status === 200) {
        dispatch({
          type: parentConstants.GET_PARENT_BY_ID_SUCCESS,
          payload: res.data.parent,
        });
      } else {
        dispatch({ type: parentConstants.GET_PARENT_BY_ID_FAILURE });
      }
    } catch (err) {
      dispatch({
        type: parentConstants.GET_PARENT_BY_ID_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const AddParents = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: parentConstants.ADD_PARENTS_REQUEST });
      let res = await axios.post(`/parents/add`, value);
      if (res.status === 201) {
        return dispatch({
          type: parentConstants.ADD_PARENTS_SUCCESS,
          payload: res.data.parent,
        });
      }
      dispatch({ type: parentConstants.ADD_PARENTS_FAILURE });
    } catch (err) {
      dispatch({
        type: parentConstants.ADD_PARENTS_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};

export const UpdateParent = (updatedValue, parentId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: parentConstants.UPDATE_PARENT_REQUEST });
      let res = await axios.put(`/parents/update/${parentId}`, updatedValue);
      if (res.status === 200) {
        return dispatch({
          type: parentConstants.UPDATE_PARENT_SUCCESS,
          payload: updatedValue,
        });
      }
      dispatch({ type: parentConstants.UPDATE_PARENT_FAILURE });
    } catch (err) {
      dispatch({
        type: parentConstants.UPDATE_PARENT_FAILURE,
        payload: { error: err.message },
      });
    }
  };
};
