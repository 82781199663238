import React, { useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteSite, getAllSitesWithReport, signout } from "../../../actions";
import { useNavigate } from "react-router-dom";

// comps
import SubDashboardLayout from "../../../components/SubDashboardLayout";
import Loader from "../../../components/Loader";
import Select from "../../../components/FormComponents/Select";
import Table from "../../../components/Table";

const AllSitePage = () => {
  const Admin = useSelector((state) => state.admin);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDelete = (id) => {
    dispatch(deleteSite(id));
  };

  const redirectToLogin = () => {
    dispatch(signout());
    return navigate("/sites/signin");
  };

  const monthName = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 },
  ];

  const [monthNumber, setMonthNumber] = useState(new Date().getMonth());

  useEffect(() => {
    dispatch(getAllSitesWithReport(monthNumber));
  }, [monthNumber]);

  return (
    <SubDashboardLayout
      title={"Admin"}
      id={"adminSitePanel"}
      to={"../../"}
      loading={loading}
    >
      <div className="tableContainer">
        <div
          className="filter"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
              whiteSpace: "nowrap",
            }}
          >
            Report Month:{" "}
          </span>
          <Select
            value={monthNumber}
            onChange={(e) => setMonthNumber(e.target.value)}
          >
            {monthName.map((month, index) => (
              <option value={month.value} key={index}>
                {month.name}
              </option>
            ))}
          </Select>
        </div>
        <Table
          title="Sites Table"
          isLoading={true}
          className="siteTable"
          OverlayLoading={<Loader />}
          columns={[
            {
              title: "#",
              render: (rowData) => rowData.tableData.id + 1,
            },
            { title: "Site", field: "siteName" },
            {
              title: "Username",
              field: "userName",
            },
          ]}
          data={Admin.sites}
          actions={[
            {
              icon: "add",
              tooltip: "Add Site",
              isFreeAction: true,
              onClick: (event) => navigate("create"),
            },
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete site",
              onClick: (event, rowData) => onDelete(rowData._id),
            }),
            (rowData) => ({
              icon: "edit",
              tooltip: "Update site",
              onClick: (event, rowData) => navigate(rowData._id),
            }),
            {
              icon: "login",
              tooltip: "Login",
              onClick: (event) => redirectToLogin(),
            },
          ]}
          options={{
            paging: false,
            search: false,
            headerStyle: {
              backgroundColor: "rgb(233 233 233)",
              color: "#000",
              padding: "0 .5rem",
            },
            rowStyle: {
              backgroundColor: "#ececec",
            },
            draggable: false,
          }}
          detailpanel={(rowData) => {
            return (
              <div
                style={{
                  width: "98%",
                  margin: "0 auto",
                  padding: ".5rem 0",
                }}
                className="monthlyReportTable"
              >
                <div>
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    Month:
                  </span>
                  <b>{monthName[monthNumber].name}</b>
                </div>

                <Table
                  title="Monthly report"
                  columns={[
                    { title: "Meal Type", field: "mealType" },
                    { title: "Served", field: "quantity" },
                  ]}
                  data={rowData.meals}
                  options={{
                    paging: false,
                    search: false,
                    headerStyle: {
                      color: "#000",

                      borderBottom: "1px solid #000",
                      padding: "2px .5rem",
                    },
                    rowStyle: {
                      border: 0,
                    },
                    cellStyle: {
                      border: 0,
                    },
                    draggable: false,
                    exportButton: true,
                    exportFileName: `${monthName[monthNumber].name} meal report - ${rowData.siteName}`,
                  }}
                />
              </div>
            );
          }}
        />
      </div>
    </SubDashboardLayout>
  );
};

export default AllSitePage;
